import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Versicherung2017 extends React.Component {
  render() {
    const dualVM = 'Dualer Student im Bereich Versicherung';
    const dualVW = 'Duale Studentin im Bereich Versicherung';
    const VM = 'Auszubildender im Bereich Versicherung';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Magnus Bunar'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
          <AzubiCard name={'Dana Gipp'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Carlo Hapke'} dkx={''} ausbildungsart={VM} history={this.props.history} />
          <AzubiCard name={'Laura Jürgens'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Marie Kulle'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Daniel Pfening'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
          <AzubiCard name={'Jennifer Tews'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Hans Maximilian Voigt'} dkx={''} ausbildungsart={VM} history={this.props.history} />
          <AzubiCard name={'Nina Werthmann'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Versicherung2017;
