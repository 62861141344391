import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

const flickityOptions = {
  groupCells: true,
};

class It2017 extends React.Component {
  render() {
    const dualWIM = 'Dualer Student der Wirtschaftsinformatik';
    const dualWIW = 'Duale Studentin der Wirtschaftsinformatik';
    const ITM = 'Auszubildender im Bereich Fachinformatik';
    const ITW = 'Auszubildende im Bereich Fachinformatik';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity options={flickityOptions}>
          <AzubiCard name={'Kai Bendig'} dkx={'DKX025Q'} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Jolina Blume'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Vanessa Datta'} dkx={''} ausbildungsart={ITW} history={this.props.history} />
          <AzubiCard name={'Marvin Gaida'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Noah Gollnick'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Marvin Kapitza'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Katharina Kern'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Fabian Kreit'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Jonas Leusmann'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Stefan Moor'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Alina Müller'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Morten Niehoff'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Felix Pletke'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Johannes Plewka'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Vanessa Prica'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Jonas Radtke'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Kyra Salewski'} dkx={''} ausbildungsart={ITW} history={this.props.history} />
          <AzubiCard name={'Jan Luca Sandmann'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Lucas Schejok'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Lennart Schwarz'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Sven Schwärzel'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Lukas Sestendrup'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Jan Thielke'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Alina Wegener'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default It2017;
