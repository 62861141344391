import React, { useEffect, useState } from 'react';

import NavigationBarTrainee from './NavigationBarTrainee';

import NewsFeed from '../../components/newsfeed/NewsFeed';
import HorizontalTimeline from '../../components/horizontalTimeline/HorizontalTimeline';
import Page from '../../components/page/Page';
import DatesAPI from '../../api/Global/Dates';

const HomePageTrainee = () => {
  const [timelineData, setTimelineData] = useState();
  let timeline = DatesAPI.useGetTimeline();

  useEffect(() => {
    let unsortedData = [];

    timeline.data?.absences.map((x) => {
      if (x) unsortedData.push({ ...x, entryType: 'abs' });
    });

    timeline.data?.dates.map((x) => {
      if (x) unsortedData.push({ ...x, entryType: 'dat' });
    });

    timeline.data?.departmentRequests.map((x) => {
      if (x) unsortedData.push({ ...x, entryType: 'dpr' });
    });

    let sorted =
      unsortedData.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      }) ?? [];

    console.log(sorted);

    setTimelineData(sorted);
  }, [timeline.data]);

  return (
    <Page title="Willkommen" navigation={<NavigationBarTrainee />}>
      <HorizontalTimeline
        dates={timelineData?.map((x) => {
          if (x.entryType == 'dpr') {
            return { ...x, title: x.department.shortcut };
          } else if (x.entryType == 'abs') {
            return { ...x, title: 'Urlaub' };
          } else if (x.entryType == 'dat') {
            return { ...x };
          }
        })}
      ></HorizontalTimeline>
      <NewsFeed />
    </Page>
  );
};

export default HomePageTrainee;
