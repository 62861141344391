import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NavigationBarAdmin from '../NavigationBarAdmin';
import TrainingTypesAPI from '../../../api/Global/TrainingTypes';
import AutoDatatable from '../../../components/AutoDatatable';
import AutoForm from '../../../components/AutoForm';
import { Link } from 'react-router-dom';
import Page from '../../../components/page/Page';
import { useQueryClient } from 'react-query';

function TrainingtypesEditOrShow({ editMode }) {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  const autoForm = useRef();
  const queryClient = useQueryClient();

  const onSubmitForm = async () => {
    const result = await TrainingTypesAPI.update(data);

    if (!result.error) {
      TrainingTypesAPI.invalidate(queryClient);
      history.push('/admin/trainingtypes');
    }

    return result;
  };

  const showData = [
    {
      header: 'ID',
      accessor: 'id',
      editable: false,
      required: true,
    },
    {
      header: 'Titel',
      accessor: 'label',
      required: true,
    },
    {
      header: 'Anzeigen',
      accessor: 'display',
      required: true,
      type: 'combobox',
      comboboxOptions: [
        { display: 'Ja', value: 'true' },
        { display: 'Nein', value: 'false' },
      ],
    },
  ];

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const trainingtypeData = await TrainingTypesAPI.getOne({ id: id });
        if (!trainingtypeData.error) setData(trainingtypeData.data);
      } catch (e) {
        setData([]);
      }
      setIsLoading(false);
    })();
  }, [setData]);

  return (
    <Page title="Ausbildungsgang bearbeiten" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        {editMode ? (
          <AutoForm
            data={data}
            dataDescription={showData}
            onValueChange={setData}
            submitForm={onSubmitForm}
            ref={autoForm}
          ></AutoForm>
        ) : (
          <AutoDatatable data={data} dataDescription={showData} isLoading={isLoading}></AutoDatatable>
        )}

        <div className="o-layout__item">
          <div className="c-button-group">
            <Link to={'/admin/trainingtypes'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            {editMode ? (
              <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
                Speichern
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default TrainingtypesEditOrShow;
