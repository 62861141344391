import ThemesAPI from '../../api/Global/Themes';

export const GET_THEME_START = 'GET_THEME_START';
export const GET_THEME_SUCCESS = 'GET_THEME_SUCCESS';
export const GET_THEME_ERROR = 'GET_THEME_ERROR';

export function getThemes() {
  return async (dispatch) => {
    dispatch({ type: GET_THEME_START });

    const response = await ThemesAPI.get();

    dispatch({
      type: GET_THEME_SUCCESS,
      themes: response.data,
    });
  };
}
