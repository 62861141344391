import React, { useCallback, useEffect, useRef, useState } from 'react';
import AutoForm from '../AutoForm';
import Spinner from '../loader/Spinner';
import Page from '../page/Page';
import ThemesAPI from '../../api/Global/Themes';
import UserAPI from '../../api/Admin/User';

const ProfileEdit = ({ navigation }) => {
  const userDataReq = UserAPI.useGet();
  const themesReq = ThemesAPI.useGet();
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState(userData);
  const [rerender, setRerender] = useState(false);

  const autoForm1 = useRef();
  const autoForm2 = useRef();
  const autoForm3 = useRef();

  const [dataDefinitionLeft] = useState([
    {
      header: 'Vorname',
      accessor: 'firstname',
      editable: false,
      required: true,
      notEmpty: true,
      type: 'input',
    },
    {
      header: 'Email',
      accessor: 'email',
      editable: false,
      required: true,
      notEmpty: true,
      type: 'input',
    },
  ]);

  const [dataDefinitionRight] = useState([
    {
      header: 'Nachname',
      accessor: 'lastname',
      editable: false,
      required: true,
      notEmpty: true,
      type: 'input',
    },
    {
      header: 'Telefon',
      accessor: 'phone',
      required: false,
      type: 'input',
    },
  ]);

  const [dataDefinitionTopics, setDataDefinitionTopics] = useState([
    {
      header: 'Themen',
      accessor: 'topics',
      required: false,
      type: 'tagpicker',
      optionsAccessor: 'title',
      optionsIdAccessor: 'id',
      tagpickerOptions: [],
    },
  ]);

  const resetData = () => {
    if (userDataReq.data) {
      setData(userDataReq.data);
      setUserData(userDataReq.data);
    }
  };

  useEffect(() => {
    resetData();
  }, [userDataReq.data]);

  useEffect(() => {
    if (themesReq.data) {
      dataDefinitionTopics[0].tagpickerOptions = themesReq.data;
      setDataDefinitionTopics(dataDefinitionTopics);
      setRerender(!rerender);
    }
  }, [themesReq.data]);

  const onSubmitForm = useCallback(async () => {
    await UserAPI.updateUserProfile(data);
    window.location.href = '../account';
  }, [data]);

  const onValueChange = (data) => {
    setData(data);
  };

  return (
    <Page navigation={navigation}>
      <Spinner
        fullPage
        display={userDataReq.isLoading || userDataReq.isFetching || themesReq.isLoading || themesReq.isFetching}
      ></Spinner>
      <div className="o-layout">
        <div className="o-layout__item">
          <div className="container-profileHeader">
            <div className="profileIcon">
              <h1 className="icon-profile-initial">
                {userData?.firstname.charAt(0)}
                {userData?.lastname.charAt(0)}
              </h1>
            </div>
            <div>
              <h2 className="u-mt-large u-ml">
                {userData?.firstname} {userData?.lastname}
              </h2>
              <p className="u-ml">{userData?.userGroup}</p>
            </div>
          </div>
          <div className="nav-profile u-mt-large">
            <hr></hr>
          </div>

          <h3>Mein Profil</h3>
          <p>Überprüfe und aktualisiere deine persönlichen Angaben.</p>
          <hr></hr>
          <h4>Konto</h4>
        </div>

        <div className="o-layout__item u-1/2">
          {userData ? (
            <AutoForm
              data={data}
              dataDescription={dataDefinitionLeft}
              onValueChange={onValueChange}
              submitForm={onSubmitForm}
              ref={autoForm1}
            ></AutoForm>
          ) : null}
        </div>

        <div className="o-layout__item u-1/2">
          {userData ? (
            <AutoForm
              data={data}
              dataDescription={dataDefinitionRight}
              onValueChange={onValueChange}
              submitForm={onSubmitForm}
              ref={autoForm2}
            ></AutoForm>
          ) : null}
        </div>

        <div className="o-layout__item">
          <hr></hr>
          <h4>Interessen</h4>
          <AutoForm
            data={data}
            dataDescription={dataDefinitionTopics}
            onValueChange={onValueChange}
            submitForm={onSubmitForm}
            ref={autoForm3}
          ></AutoForm>
        </div>

        <div className="o-layout__item">
          <div className="u-mt">
            <button
              className="c-btn c-btn--secondary u-1/5 u-mr"
              onClick={() => {
                window.location.href = '../account';
              }}
            >
              Verwerfen
            </button>
            <button
              className="c-btn c-btn--secondary u-1/5"
              onClick={async (event) => {
                await autoForm3.current.onFormSubmit(event, [autoForm1, autoForm2]);
              }}
            >
              Aktualisieren
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProfileEdit;
