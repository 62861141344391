import React, { Component } from 'react';
import NavBarItem from '../../components/navbar/NavBarItem';
import NavigationBar from '../../components/navbar/NavigationBar';

class NavigationBarCoaches extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <NavigationBar defaultPage={'/unterweiser'}>
        <NavBarItem location="/unterweiser/abteilung" title="Meine Abteilung" />
        <NavBarItem location="/unterweiser/einsatzanfragen" title="Einsatzanfragen" />
        {/* <NavBarItem location="/unterweiser/auszubildende" title="Auszubildende" /> */}
      </NavigationBar>
    );
  }
}
export default NavigationBarCoaches;
