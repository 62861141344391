import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import '../../App.css';
import NavigationBarCoaches from './NavigationBarCoaches';
import It2016 from '../../components/cardslider/Jahrgaenge/IT2016';
import It2017 from '../../components/cardslider/Jahrgaenge/IT2017';
import It2018 from '../../components/cardslider/Jahrgaenge/IT2018';
import Mediengestalter2018 from '../../components/cardslider/Jahrgaenge/Mediengestalter2018';
import DigitalMarketing2018 from '../../components/cardslider/Jahrgaenge/DigitalMarketing2018';
import Versicherung2018 from '../../components/cardslider/Jahrgaenge/Versicherung2018';
import Versicherung2017 from '../../components/cardslider/Jahrgaenge/Versicherung2017';
import Versicherung2016 from '../../components/cardslider/Jahrgaenge/Versicherung2016';
import Bank2018 from '../../components/cardslider/Jahrgaenge/Bank2018';
import Bank2017 from '../../components/cardslider/Jahrgaenge/Bank2017';
import Page from '../../components/page/Page';

class TraineeOverviewCoaches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IT: false,
      Bank: false,
      Versicherung: false,
      DigitalMarketing: false,
      Mediengestalter: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  async componentDidMount() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const group = attributes['custom:group'];
      if (group !== 'Unterweiser') {
        this.props.history.push('/noaccess');
      }
    } catch {
      this.props.history.push('/noaccess');
    }
  }

  handleClick(event) {
    if (event.target.id === 'IT') {
      this.setState(() => ({
        IT: true,
        Bank: false,
        Versicherung: false,
        DigitalMarketing: false,
        Mediengestalter: false,
      }));
    }
    if (event.target.id === 'Bank') {
      this.setState(() => ({
        IT: false,
        Bank: true,
        Versicherung: false,
        DigitalMarketing: false,
        Mediengestalter: false,
      }));
    }
    if (event.target.id === 'Versicherung') {
      this.setState(() => ({
        IT: false,
        Bank: false,
        Versicherung: true,
        DigitalMarketing: false,
        Mediengestalter: false,
      }));
    }
    if (event.target.id === 'DigitalMarketing') {
      this.setState(() => ({
        IT: false,
        Bank: false,
        Versicherung: false,
        DigitalMarketing: true,
        Mediengestalter: false,
      }));
    }
    if (event.target.id === 'Mediengestalter') {
      this.setState(() => ({
        IT: false,
        Bank: false,
        Versicherung: false,
        DigitalMarketing: false,
        Mediengestalter: true,
      }));
    }
  }

  render() {
    return (
      <Page navigation={<NavigationBarCoaches history={this.props.history} title="Auszubildende" />}>
        <p className={'unterweiserazubip'}>
          <button
            className={`unterweiserazubilink ${this.state.IT ? 'is-active' : 'is-closed'}`}
            id="IT"
            onClick={this.handleClick}
          >
            IT Azubis
          </button>
          <button
            className={`unterweiserazubilink ${this.state.Bank ? 'is-active' : 'is-closed'}`}
            id="Bank"
            onClick={this.handleClick}
          >
            Bank Azubis
          </button>
          <button
            className={`unterweiserazubilink ${this.state.Versicherung ? 'is-active' : 'is-closed'}`}
            id="Versicherung"
            onClick={this.handleClick}
          >
            Versicherungs Azubis
          </button>{' '}
          <button
            className={`unterweiserazubilink ${this.state.DigitalMarketing ? 'is-active' : 'is-closed'}`}
            id="DigitalMarketing"
            onClick={this.handleClick}
          >
            Digital Marketing & Sales Azubis
          </button>
          <button
            className={`unterweiserazubilink ${this.state.Mediengestalter ? 'is-active' : 'is-closed'}`}
            id="Mediengestalter"
            onClick={this.handleClick}
          >
            Mediengestalter Azubis
          </button>
        </p>
        <hr className={'content-divider'} />

        <div className={'unterweiserazubikasten'} style={{ height: 'calc(100vh - 250px)' }}>
          <div className="c-tabs js-tabs">
            <div>
              {this.state.IT ? (
                <div className="c-tabs__content  js-tabs__content ">
                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Jahrgang 2016</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <It2016 history={this.props.history} />
                    </div>
                  </section>

                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Jahrgang 2017</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <It2017 history={this.props.history} />
                    </div>
                  </section>

                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Jahrgang 2018</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <It2018 history={this.props.history} />
                    </div>
                  </section>
                </div>
              ) : null}

              {this.state.Bank ? (
                <div className="c-tabs__content  js-tabs__content ">
                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Bank 2017</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <Bank2017 history={this.props.history} />
                    </div>
                  </section>

                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Bank 2018</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <Bank2018 history={this.props.history} />
                    </div>
                  </section>
                </div>
              ) : null}
              {this.state.Versicherung ? (
                <div className="c-tabs__content  js-tabs__content ">
                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Versicherung 2016</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <Versicherung2016 history={this.props.history} />
                    </div>
                  </section>

                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Versicherung 2017</h3>
                      <Versicherung2017 history={this.props.history} />
                    </div>
                  </section>

                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Versicherung 2018</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <Versicherung2018 history={this.props.history} />
                    </div>
                  </section>
                </div>
              ) : null}

              {this.state.DigitalMarketing ? (
                <div className="c-tabs__content  js-tabs__content ">
                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Digital Marketing & Sales 2018</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <DigitalMarketing2018 history={this.props.history} />
                    </div>
                  </section>
                </div>
              ) : null}
              {this.state.Mediengestalter ? (
                <div className="c-tabs__content  js-tabs__content ">
                  <section className="o-content-section">
                    <div className="o-page-wrap">
                      <h3 id={'calendarH3'}>Mediengestalter 2018 (in Berlin)</h3>
                      <hr className={'content-divider'} />
                      <br />
                      <Mediengestalter2018 history={this.props.history} />
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
export default TraineeOverviewCoaches;
