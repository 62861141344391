import React from 'react';
import classNames from 'classnames';
import SimpleLoader from './SimpleLoader';

const Spinner = ({
  display = true,
  center = true,
  fullPage = false,
  section = false,
  small = false,
  className,
  label,
}) => {
  if (!display) {
    return null;
  }
  return (
    <div
      className={classNames('c-spinner', className, {
        'c-spinner--full-page': fullPage,
        'c-spinner--center': center,
        'c-spinner--section': section,
        'c-spinner--small': small,
      })}
      aria-live="polite"
      aria-busy="true"
    >
      <SimpleLoader display />
      {label && <small className="u-text-brand">{label}</small>}
    </div>
  );
};

export default Spinner;
