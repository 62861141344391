import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

class Azubi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abteilungshistorie: [],
      email: '',
      telefon: '',
      Ausbildungsrichtung: '',
      faehigkeiten: [],
      interessen: [],
    };
  }

  async componentDidMount() {
    try {
      const currentSession = await Auth.currentSession();
      const RoherIdToken = await currentSession.getIdToken();
      const idToken = await RoherIdToken.getJwtToken();
      let config = {
        headers: { Authorization: idToken },
      };
      axios
        .post(
          'https://8nfzsh0rvi.execute-api.eu-central-1.amazonaws.com/dev/toad-user-daten',
          {
            DKX: this.props.location.state.dkx,
            Eventart: 'Get',
          },
          config
        )
        .then((response) => {
          try {
            if (response.data.Item.Abteilungen.length !== 0) {
              this.setState(() => ({ abteilungshistorie: response.data.Item.Abteilungen }));
            }
            if (response.data.Item.Interessen.length !== 0) {
              this.setState(() => ({ interessen: response.data.Item.Interessen }));
            }
            if (response.data.Item.Faehigkeiten.length !== 0) {
              this.setState(() => ({ faehigkeiten: response.data.Item.Faehigkeiten }));
            }
          } catch {
            console.log('Abteilungsselect fehlgeschlagen');
          }
          this.setState(() => ({ email: response.data.Item.Email }));
          this.setState(() => ({ telefon: response.data.Item.Telefon }));
          this.setState(() => ({ Ausbildungsrichtung: response.data.Item.Ausbildungsrichtung }));
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (ex) {
      console.error(ex);
    }

    this.setState({ isAuthenticating: false });
  }

  render() {
    return <></>;
  }
}

export default Azubi;
