import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AutoForm from '../../../components/AutoForm';
import NavigationBarTrainee from './../../Trainee/NavigationBarTrainee';
import DepartmentAPI from '../../../api/Global/Department';
import DepartmentRequestAPI from '../../../api/Azubi/DepartmentRequest';
import Page from '../../../components/page/Page';
import { toast, ToastContainer } from 'react-toastify';

export default function DepartmentRequestsCreate() {
  let const_showData = [
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      required: true,
      type: 'daterangepicker',
      minDate: 'today',
    },
    {
      header: 'Abteilung',
      accessor: 'departmentId',
      required: true,
      comboboxOptions: [],
      type: 'combobox',
    },
    {
      header: 'Nachricht',
      accessor: 'message',
      required: true,
      type: 'textarea',
    },
  ];

  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(const_showData);

  const departmentData = DepartmentAPI.useDepartmentGetAll();

  const history = useHistory();
  const autoForm = useRef();
  const homePath = '/auszubildende/einsatzanfragenuebersicht';

  const onSubmitForm = async () => {
    const result = await DepartmentRequestAPI.insert(data);

    if (!result.error) {
      history.push(homePath);
    } else {
      toast.error('Termin konnte nicht angelegt werden: ' + result.msg, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return result;
  };

  useEffect(() => {
    const_showData[1].comboboxOptions = departmentData?.data?.map((x) => {
      return { display: x.shortcut, value: x.id };
    });

    setShowData(const_showData);
  }, [departmentData.data]);

  return (
    <Page title="Einsatzanfrage erstellen" navigation={<NavigationBarTrainee />}>
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
          showLoader={departmentData}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={homePath} className="c-btn c-btn--secondary">
              Abbrechen
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Page>
  );
}
