import { QueryClient } from 'react-query';

class QueryClientManagerClass {
  constructor() {
    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 30, // 30 Sek, dann Refetch
        },
      },
    });
  }

  getClient() {
    return this.queryClient;
  }
}

export const QueryClientManager = (function () {
  var instance;

  function createInstance() {
    var object = new QueryClientManagerClass();
    return object;
  }

  return {
    instance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    getClient: function () {
      return instance.getClient();
    },
  };
})();
