import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

const flickityOptions = {
  groupCells: true,
};

class It2016 extends React.Component {
  render() {
    const dualWIM = 'Dualer Student der Wirtschaftsinformatik';
    const dualWIW = 'Duale Studentin der Wirtschaftsinformatik';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity options={flickityOptions}>
          <AzubiCard name={'Paul Buba'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard
            name={'Katharina Fickendey Engels'}
            dkx={''}
            ausbildungsart={dualWIW}
            history={this.props.history}
          />
          <AzubiCard name={'Jason Görs'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Yannick Lerch'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default It2016;
