import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Versicherung2016 extends React.Component {
  render() {
    const dualVM = 'Dualer Student im Bereich Versicherung';
    const dualVW = 'Duale Studentin im Bereich Versicherung';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Lisa Marie Fricke'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Tobias Jerschensky'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
          <AzubiCard name={'Jana Kurz'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Marina Rosensprung'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Thoya Schubert'} dkx={''} ausbildungsart={dualVW} history={this.props.history} />
          <AzubiCard name={'Peter Wachs'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Versicherung2016;
