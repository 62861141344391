import axios from 'axios';

class AdminApi {
  endpointName = 'toad-user';

  async createUser(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'create',
      });

      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  }

  async get() {
    let result = await axios.post('/' + this.endpointName, {
      action: 'getUserList',
    });
    return { error: false, status: result.status, data: result.data.data };
  }

  async deleteUser(userID) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'deleteUser',
        userID: userID,
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  }
}

export default AdminApi;
