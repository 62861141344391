/* istanbul ignore file */
/* Tests ignored because this is bronson specific */

import bronson from '@vwfs-bronson/bronson-bluelabel/dist/js/bronson.min.js';

export function bronsonRegister() {
  // need this on the global window object, because the webcalc/calcjs need it.
  window.bronson = bronson;
  bronson.ComponentRegister.initAll();
}
