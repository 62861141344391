import React from 'react';
import { Link } from 'react-router-dom';

const NavBarItem = ({ location, title }) => {
  return (
    <li className="c-site-nav__item      js-dropdown">
      <Link className="c-site-nav-dropdown__link foo" to={location}>
        <span className="c-site-nav__link-label">{title}</span>
      </Link>
    </li>
  );
};

export default NavBarItem;
