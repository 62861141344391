import React from 'react';

const Page = ({ children, title, navigation }) => {
  return (
    <>
      {navigation}
      <section className="o-content-section">
        <div className="o-component-wrapper">
          <div className={'o-page-wrap '}>
            <header className="c-section-heading ">
              <h3>{title}</h3>
            </header>

            {children}
          </div>
        </div>
      </section>
    </>
  );
};

export default Page;
