import React, { Component } from 'react';
import NavigationBarCoaches from './NavigationBarCoaches';
import InputValidate from '../../components/InputValidate';
import DepartmentAPI from '../../api/Global/Department';
import SelectUtils from '../../components/SelectUtils';
import { withRouter } from 'react-router-dom';

class EditDepartmentPageCoaches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentHistory: '',
      newDepartment: '',
    };
    this.UNSAFE_componentWillMount = this.UNSAFE_componentWillMount.bind(this); // Binding der Funktion, sodass die States die dort veraendert werden, auch gespeichert werden.
    this.componentDidMount = this.componentDidMount.bind(this);
    this.addPossibleTheme = this.addPossibleTheme.bind(this);
    this.cutThemes = this.cutThemes.bind(this);
    this.deleteCoach = this.deleteCoach.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async UNSAFE_componentWillMount() {
    this.setState({
      departmentShortcuts: history.location.state.departmentShortcuts,
    });
    this.setState({ location: history.location.state.location });
    this.setState({ description: history.location.state.description });
    this.setState({ status: history.location.state.status });
    this.setState({
      headOfDepartment: history.location.state.headOfDepartment,
    });
    this.setState({ coacheslist: history.location.state.coacheslist });
    this.setState({
      themesOfTheDepartment: history.location.state.themesOfTheDepartment,
    });
    this.setState({ allThemesList: history.location.state.allThemesList });
    this.setState({ departmentID: history.location.state.departmentID });
    let selection = history.location.state.status;
    console.log(selection);
    await SelectUtils.selectOption('status', parseInt(selection));
  }

  async componentDidMount() {
    // Beginn des componentDidMount Eventblocks.
  }

  addPossibleTheme(possibleTheme) {
    // Beginn der "addPossibleInterests" Funktion.
    let index = this.state.allThemesList.indexOf(possibleTheme); // Index des zu loeschenden Interesse aus den moeglichen Interessen.
    let newlist = [].concat(this.state.allThemesList); // Uebertragen des Arrays in einen lokalen Array.
    newlist.splice(index, 1); // Loeschen des moeglichen Interesses.
    newlist.sort(this.compare); // Sortierung des Arrays.
    this.setState({ allThemesList: newlist }); // Ueberschreiben des States mit dem lokalen Array.
    let list = [].concat(this.state.themesOfTheDepartment); // Uebertragen des Arrays in einen lokalen Array.
    list.push(possibleTheme); // Hinzufuegen der neuen Interesse in das lokale Array.
    list.sort(this.compare); // Sortierung des Arrays.
    this.setState({ themesOfTheDepartment: list }); // Ueberschreiben des States mit dem lokalen Array.
  } // Ende der "addPossibleInterests" Funktion.

  compare(a, b) {
    // Beginn der "compare" Funktion.
    if (a.themesTitle < b.themesTitle) {
      return -1;
    }
    if (a.themesTitle > b.themesTitle) {
      return 1;
    }
    return 0;
  } // Ende der "compare" Funktion.

  async cutThemes(themesOfTheDepartment) {
    // Beginn der "cutInterestIDs" Funktion.
    let updateThemes = [];
    for (let i = 0; i < themesOfTheDepartment.length; i++) {
      updateThemes.push(themesOfTheDepartment[i].themesID);
    }
    let themesData = updateThemes.toString().split(',');
    this.setState({ themesOfTheDepartment: themesData });
  }

  deleteCoach() {
    // Beginn der "deleteYourDepartment" Funktion.
    /*let index = this.state.coacheslist.indexOf(coach);                                                   // Index der zu loeschenden Abteilung.
        const newlist = [].concat(this.state.coacheslist);                                                        // Uebertragen des Arrays in einen lokalen Array.
        newlist.splice(index, 1);                                                                           // Loeschen der gewuenschten Abteilung aus dem Array.
        this.setState({coacheslist: newlist});                                                              // Ueberschreiben des States mit dem lokalen Array.
   */
    alert('Meld dich gerne bei uns, wenn du einen alten Coach löschen möchtest.');
  } // Ende der "deleteYourDepartment" Funktion.

  deleteTheme(theme) {
    // Beginn der "deleteYourInterest" Funktion.
    let index = this.state.themesOfTheDepartment.indexOf(theme); // Index des zu loeschenden Interesse.
    const newlist = [].concat(this.state.themesOfTheDepartment); // Uebertragen des Arrays in einen lokalen Array.
    newlist.splice(index, 1); // Loeschen des gewuenschten Interesse aus dem Array.
    newlist.sort(this.compare); // Sortierung des Arrays.
    this.setState({ themesOfTheDepartment: newlist }); // Ueberschreiben des States mit dem lokalen Array.
    if (theme.themesTitle !== 'Demo') {
      const list = [].concat(this.state.allThemesList); // Uebertragen des Arrays in einen lokalen Array.
      list.push(theme); // Das geloeschte Interesse dem Array der moeglichen Interessen hinzufuegen.
      list.sort(this.compare); // Sortierung des Arrays.
      this.setState({ allThemesList: list }); // Ueberschreiben des States mit dem lokalen Array.
    } else {
      console.log('Demo wurde gelöscht.');
    }
  } // Ende der "deleteYourInterest" Funktion.

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  async handleSubmit() {
    // Beginn des "handleSubmit" Events.
    InputValidate.UnsetErrorByID('description');
    InputValidate.UnsetTextErrorByID('error-description');
    InputValidate.UnsetErrorByID('location');
    InputValidate.UnsetTextErrorByID('error-location');
    InputValidate.UnsetErrorByID('headOfDepartment');
    InputValidate.UnsetTextErrorByID('error-headOfDepartment');
    InputValidate.UnsetErrorByID('status');
    InputValidate.UnsetTextErrorByID('error-status');
    let hasError = false;

    if (this.state.description === undefined || this.state.description === '') {
      InputValidate.SetErrorByID('description');
      InputValidate.SetTextErrorByID('error-description', 'Bitte eine Beschreibung angeben');

      hasError = true;
    }

    if (this.state.location === undefined || this.state.location === '') {
      InputValidate.SetErrorByID('location');
      InputValidate.SetTextErrorByID('error-location', 'Bitte den Standort angeben');

      hasError = true;
    }
    if (this.state.headOfDepartment === undefined || this.state.headOfDepartment === '') {
      InputValidate.SetErrorByID('headOfDepartment');
      InputValidate.SetTextErrorByID('error-headOfDepartment', 'Bitte den Unterabteilungsleiter angeben');

      hasError = true;
    }
    if (this.state.status === undefined || this.state.status === '' || this.state.status === '3') {
      InputValidate.SetErrorByID('status');
      InputValidate.SetTextErrorByID('error-status', 'Bitte einen Status angeben');

      hasError = true;
    }
    if (hasError === false) {
      if (this.state.themesOfTheDepartment.length === 0) {
        // Wenn kein Interesse mehr im State ist.
        this.setState({ themesOfTheDepartment: '' }); // soll der Wert des States auf leer geschrieben werden.
      } else {
        // ansonsten..
        await this.cutThemes(this.state.themesOfTheDepartment); // Aufruf der "cutInterestIDs" Funktion.
      }

      try {
        await DepartmentAPI.updateDepartment(
          this.state.departmentID,
          this.state.location,
          this.state.description,
          this.state.status,
          this.state.headOfDepartment,
          this.state.themesOfTheDepartment
        );
        this.props.history.push('/unterweiser/abteilung'); // Weiterleitung auf die Azubi Profil Seite.
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Ein Fehler ist aufgetreten');
    }
  } // Ende des "handleSubmit" Events.

  render() {
    let coacheslist = [];
    let themesOfTheDepartment = [];
    let allThemesList = [];

    if (this.state.coacheslist.length !== 0) {
      coacheslist = this.state.coacheslist.map(function iterator(coach, index) {
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        return (
          <a className="tag" key={index} onClick={() => this.deleteCoach(coach)}>
            <i className="c-icon  c-icon--[semantic-close]" id={'closeSkillTag'} />
            {coach}
          </a>
        );
      }, this);
    }

    if (this.state.themesOfTheDepartment.length !== 0) {
      themesOfTheDepartment = this.state.themesOfTheDepartment.map(function iterator(theme, index) {
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        return (
          <a key={index} className="tag" id={theme} onClick={() => this.deleteTheme(theme)}>
            <i className="c-icon  c-icon--[semantic-close]" id={'closeSkillTag'} />
            {theme.themesTitle}
          </a>
        );
      }, this);
    }

    if (this.state.allThemesList.length !== 0) {
      allThemesList = this.state.allThemesList.map(
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        function iterator(possibleTheme, index) {
          return (
            <a key={index} className="tag" id={possibleTheme} onClick={() => this.addPossibleTheme(possibleTheme)}>
              <i className="c-icon  c-icon--[semantic-close]" id={'openSkillTag'} />
              {possibleTheme.themesTitle}
            </a>
          );
        },
        this
      );
    }

    return (
      <div className={'FullSizeContainer'}>
        <NavigationBarCoaches history={this.props.history} />
        <div className="contentUI">
          <h3 id={'calendarH3'}>{this.state.departmentShortcuts} bearbeiten</h3>
          <p>Abteilungskürzel</p>
          <input
            className="textFieldProfile"
            type={'text'}
            id="departmentShortcuts"
            value={this.state.departmentShortcuts}
            disabled={true}
          />
          <p>Beschreibung</p>
          <input
            className="textFieldProfile"
            type={'text'}
            id="description"
            value={this.state.description}
            onChange={this.handleChange}
          />
          <p className="c-error-message" id="error-description" style={{ display: 'none' }} />
          <p>Standort</p>
          <input
            className="textFieldProfile"
            type={'text'}
            id="location"
            value={this.state.location}
            onChange={this.handleChange}
          />
          <p className="c-error-message" id="error-location" style={{ display: 'none' }} />
          <p>Unterabteilungsleiter</p>
          <input
            className="textFieldProfile"
            type={'text'}
            id="headOfDepartment"
            value={this.state.headOfDepartment}
            onChange={this.handleChange}
          />
          <p className="c-error-message" id="error-headOfDepartment" style={{ display: 'none' }} />
          <p>Status</p>
          <div className="c-input  c-input--select" style={{ width: 650 }}>
            <select
              className="c-input__input  "
              id="status"
              value={this.state.status}
              onChange={this.handleChange}
              placeholder={'Bitte einen  Status festlegen'}
            >
              <option value={'0'}> Grün</option>
              <option value={'1'}> Gelb</option>
              <option value={'2'}> Rot</option>
            </select>
          </div>
          <p className="c-error-message" id="error-status" style={{ display: 'none' }} />
          <p>Coaches</p>
          <p>{coacheslist}</p>
          <p>Themen der Abteilung</p>
          <p>{themesOfTheDepartment}</p>
          <p>Mögliche Themen</p>
          <p>{allThemesList}</p>
          <button
            className="c-btn  c-btn--icon c-btn--secondary"
            type="submit"
            id={'loginButton'}
            onClick={this.handleSubmit}
          >
            <span className="c-btn__text">Speichern</span>
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(EditDepartmentPageCoaches);
