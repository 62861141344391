import React, { useState } from 'react';
import Table from '../../../components/table/Table';
import NavigationBarTrainer from '../NavigationBarTrainer';
import Page from '../../../components/page/Page';
import HolidaysAPI from '../../../api/Ausbilder/Holidays';
import { Link, useHistory } from 'react-router-dom';
import { formatDateToLocalString } from '../../../shared/FormatFunctions';
import { useQueryClient } from 'react-query';
import SimpleLoader from '../../../components/loader/SimpleLoader';

const HolidayRequests = () => {
  const componentPath = '/ausbilder/urlaubsuebersicht';
  const history = useHistory();
  const holiday_data = HolidaysAPI.useGet('offen');
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const accept = async (e) => {
    setIsLoading(true);
    await HolidaysAPI.accept(e.target.dataset.id);
    HolidaysAPI.invalidate(queryClient);
    setIsLoading(false);
  };

  const reject = async (e) => {
    setIsLoading(true);
    await HolidaysAPI.reject(e.target.dataset.id);
    HolidaysAPI.invalidate(queryClient);
    setIsLoading(false);
  };

  const columns = [
    {
      Header: 'Vorname',
      accessor: 'creator.firstname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Nachname',
      accessor: 'creator.lastname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'EMail',
      accessor: 'creator.email',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Start',
      accessor: 'start',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Ende',
      accessor: 'end',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Netto Tage',
      accessor: 'nettoDays',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Typ',
      accessor: 'type',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Status',
      accessor: 'state',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        console.log(props.row.original);
        return (
          <div>
            {props.row.original.state === 'offen' ? (
              <button
                onClick={accept}
                data-id={dataId}
                className="c-icon c-icon--[semantic-checkmark] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
              />
            ) : null}
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
            />
            {props.row.original.state === 'offen' ? (
              <button
                onClick={reject}
                data-id={dataId}
                className="c-icon c-icon--[semantic-close] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <Page
      title={'Urlaubsanfragen'}
      displayLoader={holiday_data.isLoading}
      navigation={<NavigationBarTrainer history={history} />}
    >
      <SimpleLoader display={holiday_data.isLoading || holiday_data.isFetching || isLoading}></SimpleLoader>
      <Table
        defaultPageSize={10}
        ofText={'von'}
        previousText={'Vorherige Seite'}
        pageText={'Seite'}
        rowsText={'Zeilen'}
        nextText={'Nächste Seite'}
        data={holiday_data.data ?? []}
        noDataText={'Keine Datensätze'}
        columns={columns}
        filterable={true}
      />
    </Page>
  );
};

export default HolidayRequests;
