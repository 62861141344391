import React, { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AutoForm from '../../../components/AutoForm';
import TrainingTypesAPI from '../../../api/Global/TrainingTypes';
import NavigationBarAdmin from './../../Administrator/NavigationBarAdmin';
import Page from '../../../components/page/Page';
import { useQueryClient } from 'react-query';

function TrainingtypesCreate() {
  const [data, setData] = useState([]);
  const history = useHistory();
  const autoForm = useRef();
  const queryClient = useQueryClient();

  const onSubmitForm = async () => {
    const result = await TrainingTypesAPI.insert(data);

    if (!result.error) {
      TrainingTypesAPI.invalidate(queryClient);
      history.push('/admin/trainingtypes');
    }

    return result;
  };

  const showData = [
    {
      header: 'Titel',
      accessor: 'label',
      required: true,
    },
    {
      header: 'Anzeigen',
      accessor: 'display',
      required: true,
      type: 'combobox',
      comboboxOptions: [
        { display: 'Ja', value: 'true' },
        { display: 'Nein', value: 'false' },
      ],
    },
  ];

  return (
    <Page title="Abteilung erstellen" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/admin/trainingtypes'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default TrainingtypesCreate;
