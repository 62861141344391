import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavBarItem from '../../components/navbar/NavBarItem';
import NavigationBar from '../../components/navbar/NavigationBar';

class NavigationBarAdmin extends Component {
  render() {
    return (
      <NavigationBar showSupport={false} showProfile={false}>
        <NavBarItem location="/admin/users" title="Benutzer" />
        <NavBarItem location="/admin/password-reset" title="Password Reset" />
        <NavBarItem location="/admin/theme" title="Themen Lexikon" />
        <NavBarItem location="/admin/department" title="Abteilungen" />
        <NavBarItem location="/admin/trainingtypes" title="Ausbildungsgänge" />
      </NavigationBar>
    );
  }
}

export default withRouter(NavigationBarAdmin); // Export der "AdminNavigation" Klasse.
