import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, Auth } from 'aws-amplify';
import '@vwfs-bronson/bronson-bluelabel/dist/css/style.css';
import App from './App';
import config from './config';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';
import { bronsonRegister } from './shared/BronsonUpdate';
import { BrowserRouter } from 'react-router-dom';
import { configureDefaults } from './api/axiosConfig';
import configureStore from './redux/store/ConfigureStore.dev';
import { QueryClientProvider } from 'react-query';
import { QueryClientManager } from './api/QueryClientManager';

configureDefaults();
const store = configureStore();
const queryClient = QueryClientManager.instance().getClient();

const awsconfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AWS_AMP_AUTHFLOWTYPE,
    scope: ['openid'],
    responseType: 'code',
  },
};

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

bronsonRegister();

ReactDOM.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
registerServiceWorker();
