import React, { Component } from 'react';
import NavigationBarAdmin from './NavigationBarAdmin';
import Department from './Department/Department';
import Page from '../../components/page/Page';
class DepartmentManagementAdmin extends Component {
  render() {
    return (
      <Page navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
        <Department></Department>
      </Page>
    );
  }
}

export default DepartmentManagementAdmin;
