import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import NavBarItem from '../components/navbar/NavBarItem';

export default class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      newPassword: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  validateForm() {
    return this.state.code.length > 0 && this.state.newPassword.length > 0;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      Auth.forgotPasswordSubmit(this.props.location.state.forgottenemail, this.state.code, this.state.newPassword)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      this.props.history.push('/');
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    return (
      <div className="FullSizeContainer">
        <nav>
          <NavBarItem location="/" title="TOAD"></NavBarItem>
        </nav>
        <div className="confirmBody">
          <div className="vertical">
            <div className={'contentWrapper'} id={'centered'}>
              <h1 className={'confirmH1'}>Sie haben eine Email erhalten.</h1>
              <p>
                Bitte überprüfen Sie Ihren Email-Accountund geben <br />
                Sie den Verifizierungscode ein.
              </p>
              <form onSubmit={this.handleSubmit}>
                <input
                  className="inputVerifizierung"
                  placeholder="Verifizierungscode"
                  id={'code'}
                  value={this.state.code}
                  type={'text'}
                  onChange={this.handleChange}
                />
                <p />
                <input
                  className="inputConfirm"
                  id={'newPassword'}
                  value={this.state.newPassword}
                  type={'password'}
                  placeholder="Neues Passwort"
                  onChange={this.handleChange}
                />

                <div className={'LoginButton'} id={'forgotPasswordButton'}>
                  <button
                    className="c-btn  c-btn--icon c-btn--secondary"
                    type="submit"
                    disabled={!this.validateForm()}
                    id={'confirmButton'}
                  >
                    <span className="c-btn__text"> Bestätigen</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
