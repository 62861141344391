import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
export function useDropdownClose(element, expanded, setExpanded) {
  const location = useLocation();
  React.useEffect(() => {
    setExpanded(false);
  }, [location.pathname, setExpanded]);
  const listener = useCallback(
    (event) => {
      if (!element.current?.contains(event.target)) {
        setExpanded(false);
      }
    },
    [element, setExpanded]
  );
  React.useEffect(() => {
    if (expanded) {
      document.body.addEventListener('click', listener);
    }
    return () => document.body.removeEventListener('click', listener);
  }, [expanded, listener]);
}
