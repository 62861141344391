import axios from 'axios';
import { useQuery } from 'react-query';

const TrainingTypesAPI = {
  endpointName: 'toad-training-types',

  useGetTrainingtypes: function (params = {}) {
    return useQuery([this.endpointName, 'get'], async () => {
      const { data } = await axios.post('/' + this.endpointName, {
        ...params,
        action: 'get',
      });
      return data.data;
    });
  },
  useGetWithYear: function () {
    return useQuery(
      [this.endpointName, 'getWithYear'],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          action: 'getWithYear',
        });
        return data.data;
      },
      { staleTime: 1000 * 60 * 5 }
    );
  },
  delete: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'delete',
      });

      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  getOne: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'get_one',
      });

      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  insert: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'insert',
      });

      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  update: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'update',
      });

      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName, 'get']);
  },
};

export default TrainingTypesAPI;
