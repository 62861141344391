import axios from 'axios';

const HolidaysAPI = {
  endpointName: 'toad-holidays',

  create: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'create',
      });

      return { error: false, data: result.data };
    } catch (error) {
      return { error: true, code: error.response.status, msg: error.response.data.data };
    }
  },

  get: async function () {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'get',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
  getOne: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'getOne',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
  update: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'update',
      });

      return { error: false, data: result.data };
    } catch (error) {
      return { error: true, code: error.response.status, msg: error.response.data.data };
    }
  },
  delete: async function (requestTimestamp) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'delete',
        requestTimestamp: requestTimestamp,
      });

      return { error: false, data: result.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
};

export default HolidaysAPI;
