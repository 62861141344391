// import UserDataAPI from '../api/Global/UserData';
import DepartmentRequestAPI from '../api/Azubi/DepartmentRequest';
import statusFunctions from '../components/statusFunctions';
import UserAPI from '../api/Admin/User';

class CoachesDataService {
  async getCoachesDepartment() {
    try {
      let userData = await UserAPI.get();
      if (!userData.error) {
        return userData.data.department;
      }
    } catch (ex) {
      return console.log(ex);
    }
  }
  formatDate(input) {
    // Beginn der "formatDate" Funktion.
    let datePart = input.match(/\d+/g), // Uebertragen des inputs in den datePart.
      year = datePart[0].substring(0), // Abtrennen des Jahres.
      month = datePart[1], // Abtrennen des Monats.
      day = datePart[2]; // Abtrennen des Tages.

    return day + '.' + month + '.' + year; // Ausgabe des korrekten Datumformats.
  } // Ende der "formatDate" Funktion.

  async getOpenDepartmentRequests(userDepartment) {
    try {
      let response = await DepartmentRequestAPI.get(userDepartment);
      // let usernames = [];
      // let tmpDepReq = [];
      // if (!Requests.error && !Requests.response.error) {
      //   // Wenn kein Fehler auftritt..
      //   Requests = Requests.response; // Neudeklarierung der Antwort.
      //   if (Requests.data.length > 0) {
      //     // Wenn die Antworten mehr als 0 sind.
      //     for (let i = 0; i < Requests.data.length; i++) {
      //       if (Requests.data[i].status === 'open') {
      //         let obj = {};
      //         let tmpDate = new Date(Requests.data[i].timestamp); // Uebrtragen der Anfrage in den lokalen Array.
      //         obj['Anfragedatum'] =
      //           ('0' + tmpDate.getDate()).slice(-2) +
      //           '.' +
      //           ('0' + (tmpDate.getMonth() + 1)).slice(-2) +
      //           '.' +
      //           tmpDate.getFullYear();
      //         obj['startDate'] = Requests.data[i].start;
      //         obj['endDate'] = Requests.data[i].end;
      //         obj['trainee'] = Requests.data[i].userID;
      //         obj['traineeID'] = Requests.data[i].userID;
      //         obj['Startdatum'] = this.formatDate(Requests.data[i].start); // Aufruf der "formatDate" Funktion.
      //         obj['Enddatum'] = this.formatDate(Requests.data[i].end); // Aufruf der "formatDate" Funktion.
      //         obj['Status'] = Requests.data[i].status;
      //         obj['StatusName'] = statusFunctions.statusTranslator(Requests.data[i].status);
      //         obj['Nachricht'] = Requests.data[i].message;
      //         obj['Timestamp'] = Requests.data[i].timestamp;
      //         usernames.push(Requests.data[i].userID);
      //         tmpDepReq.push(obj);
      //       }
      //       // Einfuegen der aktuelle Anfrage in den Array der Anfragen.
      //     }
      //     let anfragen = await this.getTraineeNames(tmpDepReq, usernames);
      //     return anfragen;
      //   }
      // }
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getAcceptedDepartmentRequests(userDepartment) {
    try {
      var Requests = await DepartmentRequestAPI.get(userDepartment);
      let usernames = [];
      let tmpDepReq = [];
      if (!Requests.error && !Requests.response.error) {
        // Wenn kein Fehler auftritt..
        Requests = Requests.response; // Neudeklarierung der Antwort.
        if (Requests.data.length > 0) {
          // Wenn die Antworten mehr als 0 sind.
          for (let i = 0; i < Requests.data.length; i++) {
            if (Requests.data[i].status === 'confirmed') {
              let obj = {};
              let tmpDate = new Date(Requests.data[i].timestamp); // Uebrtragen der Anfrage in den lokalen Array.
              obj['Anfragedatum'] =
                ('0' + tmpDate.getDate()).slice(-2) +
                '.' +
                ('0' + (tmpDate.getMonth() + 1)).slice(-2) +
                '.' +
                tmpDate.getFullYear();
              obj['startDate'] = Requests.data[i].start;
              obj['endDate'] = Requests.data[i].end;
              obj['trainee'] = Requests.data[i].userID;
              obj['traineeID'] = Requests.data[i].userID;
              obj['Startdatum'] = this.formatDate(Requests.data[i].start); // Aufruf der "formatDate" Funktion.
              obj['Enddatum'] = this.formatDate(Requests.data[i].end); // Aufruf der "formatDate" Funktion.
              obj['Status'] = Requests.data[i].status;
              obj['StatusName'] = statusFunctions.statusTranslator(Requests.data[i].status);
              obj['Nachricht'] = Requests.data[i].message;
              obj['Timestamp'] = Requests.data[i].timestamp;
              obj['StartTimestamp'] = Requests.data[i].startTimestamp;
              obj['EndTimestamp'] = Requests.data[i].endTimestamp;
              usernames.push(Requests.data[i].userID);
              tmpDepReq.push(obj);
            }
            // Einfuegen der aktuelle Anfrage in den Array der Anfragen.
          }
          let anfragen = await this.getTraineeNames(tmpDepReq, usernames);
          let allRequests = {
            actual: [],
            future: [],
          };

          for (let i = 0; i < anfragen.length; i++) {
            var start = new Date(anfragen[i].startDate).getTime();
            var end = new Date(anfragen[i].endDate).getTime();

            if (start < Date.now() && end >= Date.now()) {
              allRequests.actual.push(anfragen[i]);
            } else if (start > Date.now()) {
              allRequests.future.push(anfragen[i]);
            }
          }

          return allRequests;
        } else {
          return {
            actual: [],
            future: [],
          };
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  async getTraineeNames(tmpDepReq, usernames) {
    try {
      let username = await UserAPI.getUsernames(usernames);
      for (let x = 0; x < tmpDepReq.length; x++) {
        let Vorname = username.response.data.data[x].Attributes[0].Value;
        let Nachname = username.response.data.data[x].Attributes[1].Value;
        tmpDepReq[x].trainee = Vorname + ' ' + Nachname;
      }
      return tmpDepReq;
    } catch (ex) {
      console.log(ex);
    }
  }
}

export default CoachesDataService;
