import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class DigitalMarketing2018 extends React.Component {
  render() {
    const DMM = 'Dualer Student im Bereich Digital Marketing & Sales';
    const DMW = 'Duale Studentin im Bereich Digital Marketing & Sales';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Emily Fast'} dkx={''} ausbildungsart={DMW} history={this.props.history} />
          <AzubiCard name={'Marleen Franke'} dkx={''} ausbildungsart={DMW} history={this.props.history} />
          <AzubiCard name={'Katharina Matthies'} dkx={''} ausbildungsart={DMW} history={this.props.history} />
          <AzubiCard name={'Loredana Rohrbach'} dkx={''} ausbildungsart={DMW} history={this.props.history} />
          <AzubiCard name={'John Walters'} dkx={''} ausbildungsart={DMM} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default DigitalMarketing2018;
