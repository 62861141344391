import React from 'react';
import NavigationBarTrainer from '../NavigationBarTrainer';
import Page from '../../../components/page/Page';
import HolidaysAPI from '../../../api/Ausbilder/Holidays';
import { Link, useHistory, useParams } from 'react-router-dom';
import AutoDatatable from '../../../components/AutoDatatable';

const HolidayRequestsShow = () => {
  const history = useHistory();
  let { id } = useParams();
  const holiday_data = HolidaysAPI.useGetOne({ id: id });

  console.log(holiday_data);

  const columns = [
    {
      header: 'Vorname',
      accessor: 'creator.firstname',
    },
    {
      header: 'Nachname',
      accessor: 'creator.lastname',
    },
    {
      header: 'EMail',
      accessor: 'creator.email',
    },
    {
      header: 'Start',
      accessor: 'start',
      type: 'date',
    },
    {
      header: 'Ende',
      accessor: 'end',
      type: 'date',
    },
    {
      header: 'Netto Tage',
      accessor: 'nettoDays',
    },
    {
      header: 'Typ',
      accessor: 'type',
    },
    {
      header: 'Status',
      accessor: 'state',
    },
  ];

  return (
    <Page
      title={'Urlaubsanfrage ' + id}
      displayLoader={holiday_data.isLoading}
      navigation={<NavigationBarTrainer history={history} />}
    >
      <AutoDatatable
        data={holiday_data.data ?? []}
        dataDescription={columns}
        isLoading={holiday_data.isLoading}
      ></AutoDatatable>
      <div className="o-layout__item">
        <div className="c-button-group u-mt">
          <Link to={'/ausbilder/urlaubsuebersicht'} className="c-btn c-btn--secondary">
            Zurück
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default HolidayRequestsShow;
