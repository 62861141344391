import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonDe from './locales/de/common.json';
import errorDe from './locales/de/error.json';

//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// the translations
const resources = {
  de: {
    common: commonDe,
    error: errorDe,
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng: 'en',
    fallbackLng: 'de',
    debug: false,
    //defaultNS: 'common',
    resources,
    load: 'languageOnly',
    detection: {
      // order and from where user language should be detected
      // order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
      defaultVariables: {
        'application-name': process.env.REACT_APP_APPLICATION_NAME || 'Digitales Angebot',
      },
    },
  });

export default i18n;
