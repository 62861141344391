import React, { Component } from 'react';

class DepartmentMatchingCard extends Component {
  render() {
    return (
      <div className={'card-department'} key={this.props.index}>
        <div id={'innerMatch'}>
          <p>{this.props.match.departmentShortcuts}</p>
        </div>
        <div className={'matchingCardTransition'}>
          <p style={{ color: 'white' }}>{this.props.match.score} %</p>
        </div>
        <div className={'matchingcardProgressBarOutside'}>
          <div className={'matchingcardProgressBarInside'} style={{ width: this.props.match.score + '%' }} />
        </div>
        <div className={'matchingCardHashtags'}>
          <p>Hier kommen die Hashtags, bzw. Kurzbeschreibung hin...</p>
        </div>
      </div>
    );
  }
}

export default DepartmentMatchingCard;
