import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import DepartmentRequestAPI from '../../api/Azubi/DepartmentRequest';
import NavigationBarCoaches from './NavigationBarCoaches';

import AutoDatatable from '../../components/AutoDatatable';
import Page from '../../components/page/Page';

export default function DepartmentRequestsManagementDetailPage() {
  let { id } = useParams();

  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const requestData = await DepartmentRequestAPI.getOne({ id: id });
      console.log(requestData);
      if (!requestData.error) {
        setData(requestData.data);
      }

      setIsLoading(false);
    }
    fetch();
  }, [setData, setIsLoading]);

  const changeState = async (id, newState) => {
    const response = await DepartmentRequestAPI.changeStateUnterweiser({ id: id, newState: newState });

    if (!response.error) {
      history.goBack();
    }
  };

  const columns = [
    {
      header: 'Vorname',
      accessor: 'requester.firstname',
    },
    {
      header: 'Nachname',
      accessor: 'requester.lastname',
    },
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      type: 'daterangepicker',
    },
    {
      header: 'Nachricht',
      accessor: 'message',
    },
    {
      header: 'Status',
      accessor: 'state',
    },
  ];

  return (
    <Page title="Einsatzanfragen" navigation={<NavigationBarCoaches></NavigationBarCoaches>}>
      <div className="o-layout">
        <AutoDatatable data={data} dataDescription={columns} isLoading={isLoading}></AutoDatatable>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/unterweiser/einsatzanfragen'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            {data?.state === 'offen' ? (
              <>
                <button className="c-btn c-btn--secondary" onClick={() => changeState(id, 'abgelehnt')}>
                  Einsatz ablehnen
                </button>
                <button className="c-btn c-btn--primary" onClick={() => changeState(id, 'akzeptiert')}>
                  Einsatz bestätigen
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Page>
  );
}
