import React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import DepartmentAPI from '../../../api/Global/Department';
import Spinner from '../../../components/loader/Spinner';
import Table from '../../../components/table/Table';

function Department() {
  const componentPath = 'department';
  const { data, isLoading, isFetching } = DepartmentAPI.useDepartmentGetAll();
  const queryClient = useQueryClient();

  const columns = [
    {
      Header: 'Abteilung',
      accessor: 'shortcut',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Beschreibung',
      accessor: 'description',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Status',
      accessor: 'state',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/edit/${dataId}`}
              className="c-icon c-icon--[semantic-edit] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            <button
              value={dataId}
              onClick={async (id) => {
                await DepartmentAPI.delete({ id: id.target.value });
                DepartmentAPI.invalidate(queryClient);
              }}
              className="c-icon c-icon--[semantic-close] c-icon--small"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="o-layout">
      <Spinner fullPage display={isLoading || isFetching} />
      <div className="o-layout__item">
        <h3>Abteilung</h3>
      </div>
      <div className="o-layout__item o-layout--right">
        <Link to={`${componentPath}/create`} className="c-btn  c-btn--primary">
          <span className="c-btn__text">Hinzufügen</span>
        </Link>
      </div>

      <div className="o-layout__item u-1/1">
        <Table
          defaultPageSize={10}
          ofText={'von'}
          previousText={'Vorherige Seite'}
          pageText={'Seite'}
          rowsText={'Zeilen'}
          nextText={'Nächste Seite'}
          data={data ?? []}
          noDataText={'Keine Datensätze'}
          columns={columns}
          filterable={true}
        />
      </div>
    </div>
  );
}

export default Department;
