import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import DepartmentRequestAPI from '../../../api/Azubi/DepartmentRequest';
import { formatDateToLocalString } from '../../../shared/FormatFunctions';
import NavigationBarTrainee from '../NavigationBarTrainee';
import Page from '../../../components/page/Page';
import Table from '../../../components/table/Table';
import { useQueryClient } from 'react-query';

export default function DepartmentRequests() {
  const componentPath = 'einsatzanfragenuebersicht';
  const queryClient = useQueryClient();
  const departmentData = DepartmentRequestAPI.useGet();

  const columns = [
    {
      Header: 'Abteilung',
      accessor: 'department.shortcut',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Start',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'start',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Ende',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'end',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Nachricht',
      accessor: 'message',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Status',
      accessor: 'state',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/edit/${dataId}`}
              className="c-icon c-icon--[semantic-edit] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            <button
              value={dataId}
              onClick={async (id) => {
                await DepartmentRequestAPI.delete({ id: id.target.value });
                DepartmentRequestAPI.invalidate(queryClient);
              }}
              className="c-icon c-icon--[semantic-close] c-icon--small"
            />
          </div>
        );
      },
    },
  ];

  return (
    <Page title="Einsatzanfragen Übersicht" navigation={<NavigationBarTrainee />}>
      <Spinner display={departmentData.isLoading || departmentData.isFetching} fullPage />

      <div className="o-layout">
        <div className="o-layout__item o-layout--right">
          <Link to="./einsatzanfragenuebersicht/create" className="c-btn  c-btn--primary">
            <span className="c-btn__text">Hinzufügen</span>
          </Link>
        </div>

        <div className="o-layout__item u-1/1 u-mt">
          <Table
            defaultPageSize={10}
            ofText={'von'}
            previousText={'Vorherige Seite'}
            pageText={'Seite'}
            rowsText={'Zeilen'}
            nextText={'Nächste Seite'}
            data={departmentData.data ?? []}
            noDataText={'Keine Datensätze'}
            columns={columns}
            filterable={true}
          />
        </div>
      </div>
    </Page>
  );
}
