import React, { Component } from 'react';

class DepartmentCard extends Component {
  render() {
    return (
      <div className={'card-department'} key={this.props.index}>
        <div className={'card-content-header u-pb'}>
          <p>{this.props.department.departmentShortcuts}</p>
        </div>
        <div className={'card-content-body'}>
          <p>Hier kommen die Hashtags, bzw. Kurzbeschreibung hin...</p>
        </div>
      </div>
    );
  }
}

export default DepartmentCard;
