import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Bank2018 extends React.Component {
  render() {
    const dualBM = 'Dualer Student im Bereich Bank';
    const dualBW = 'Duale Studentin im Bereich Bank';
    const BM = 'Auszubildender im Bereich Bank';
    const BW = 'Auszubildende im Bereich Bank';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Jannik Bonse'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
          <AzubiCard name={'Benedict Carli'} dkx={''} ausbildungsart={BM} history={this.props.history} />
          <AzubiCard name={'Gina Cords'} dkx={''} ausbildungsart={BW} history={this.props.history} />
          <AzubiCard name={'Sarah Luise Fricke'} dkx={''} ausbildungsart={BW} history={this.props.history} />
          <AzubiCard name={'Nico Groß'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
          <AzubiCard name={'Marie Meißner'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Luca Schalk'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Bank2018;
