import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import InputValidate from '../components/InputValidate';
import Spinner from '../components/loader/Spinner';
import NavigationBar from '../components/navbar/NavigationBar';

export default class AdminLogin extends Component {
  //Constructor of the NewLogin-component
  constructor(props) {
    super(props);

    //definition of the component states
    this.state = {
      forgottenemail: '',
      LoginEmail: '',
      password: '',
      usergroup: '',
      display: 'Login',
      displayLoader: false,
    };
  }

  //event definition
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  //login handling, if the login button is clicked
  handleLoginSubmit = async (event) => {
    this.setState({ displayLoader: true });
    event.preventDefault();

    try {
      //Asynchronous message with user-data is sended by the server
      await Auth.signIn(this.state.LoginEmail.toLowerCase(), this.state.password);

      //definition of other needed constants and variables
      let user = await Auth.currentAuthenticatedUser();
      let group = '';
      if (user.attributes['custom:group'].includes('Admin')) {
        group = 'Admin';
      }
      this.props.userHasAuthenticated(true);
      this.props.setUserGroup(group);
      localStorage.setItem('toad_cache_admin', '1');
      this.setState({ displayLoader: false });
      this.props.history.push('/');

      //handling of possible exceptions
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        InputValidate.SetErrorByID('LoginEmail');
        InputValidate.SetTextErrorByID('error-LoginEmail', 'Die E-Mail ist unbekannt');
      } else if (e.code === 'NotAuthorizedException') {
        InputValidate.SetErrorByID('password');
        InputValidate.SetTextErrorByID('error-password', 'E-Mail und Passwort stimmen nicht überein');
      } else {
        console.log('Error: ' + e.code);
      }
    }
    this.setState({ displayLoader: false });
  };

  //handling of forgotten password and push to passwordreset-site
  handlePasswordForgotten = async (event) => {
    event.preventDefault();

    try {
      await Auth.forgotPassword(this.state.forgottenemail);
      this.props.history.push({
        pathname: '/passwordreset',
        state: {
          forgottenemail: this.state.forgottenemail,
        },
      });
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        InputValidate.SetErrorByID('forgottenemail');
        InputValidate.SetTextErrorByID('error-forgottenemail', 'User konnte nicht gefunden werden');
      }
    }
  };

  showForgottenPassword() {
    this.setState({ display: 'PasswordForgotten' });
  }

  showLogin() {
    this.setState({ display: 'Login' });
    this.setState({ forgottenemail: '' });
    InputValidate.UnsetErrorByID('forgottenemail');
  }

  //check if user already has entered a mail in the password forgotten-screen
  validateForgottenPasswordForm() {
    return this.state.forgottenemail.length > 0;
  }

  //check if user has already entered his Email and his password in the login screen
  validateLoginForm() {
    return this.state.LoginEmail.length > 0 && this.state.password.length > 0;
  }

  render() {
    return (
      <>
        <NavigationBar></NavigationBar>
        <Spinner fullPage display={this.state.displayLoader} />
        <div className="o-page-wrap o-page-wrap--xxsmall">
          <div className="o-layout o-layout--equal-height">
            <div className="o-layout__item   u-1/1  u-1/1@s">
              {(() => {
                switch (this.state.display) {
                  case 'Login':
                    return (
                      <div>
                        <h3>Admin-Login</h3>
                        <p>E-Mail</p>
                        <input
                          className="c-input__input  "
                          type={'text'}
                          value={this.state.LoginEmail}
                          id="LoginEmail"
                          onChange={this.handleChange}
                        />
                        <p className="c-error-message" id="error-LoginEmail" style={{ display: 'none' }} />
                        <p>Passwort</p>
                        <input
                          className="c-input__input  "
                          type="password"
                          value={this.state.password}
                          id="password"
                          onChange={this.handleChange}
                        />
                        <p className="c-error-message" id="error-password" style={{ display: 'none' }} />
                        <div className="o-button-container u-mv  ">
                          <button
                            className="c-btn  c-btn--icon c-btn--secondary"
                            type="button"
                            disabled={!this.validateLoginForm()}
                            onClick={this.handleLoginSubmit}
                          >
                            <span className="c-btn__text">Login</span>
                          </button>
                          <button className={'c-btn c-btn--link'} onClick={this.showForgottenPassword.bind(this)}>
                            Passwort vergessen?
                          </button>
                        </div>
                      </div>
                    );
                  case 'PasswordForgotten':
                    return (
                      <div>
                        {/* JSX-definition of the forgot part of the login screen*/}
                        <form onSubmit={this.handlePasswordForgotten}>
                          <h3>Passwort vergessen</h3>
                          <p>E-Mail</p>

                          <input
                            className={'c-input__input  '}
                            type={'text'}
                            value={this.state.forgottenemail}
                            id={'forgottenemail'}
                            onChange={this.handleChange}
                          />
                          <p className="c-error-message" id="error-forgottenemail" style={{ display: 'none' }} />
                          <div className="o-button-container  ">
                            <button
                              className="c-btn  c-btn--icon"
                              type="submit"
                              id={'loginButton'}
                              disabled={!this.validateForgottenPasswordForm()}
                            >
                              <span className="c-btn__text">Zurücksetzen</span>
                            </button>
                            <a className={'forgotPassword'} onClick={this.showLogin.bind(this)}>
                              zurück zum Login
                            </a>
                          </div>
                        </form>
                      </div>
                    );
                  default:
                    return <div>Fehler</div>;
                }
              })()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
