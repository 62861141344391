import axios from 'axios';
import { useQuery } from 'react-query';

const DepartmentAPI = {
  endpointName: 'toad-department',

  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName]);
  },
  async create(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'insert',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async delete(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'delete',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async get() {
    try {
      let result = await axios.get('/department');
      return result;
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  useDepartmentGetAll(nested = false) {
    return useQuery(
      [this.endpointName, 'department/all?nested=' + nested],
      async () => {
        const { data } = await axios.get('department/all?nested=' + nested);
        return data;
      },
      { staleTime: 1000 * 60 * 5 }
    );
  },
  async getAll() {
    try {
      let result = await axios.get('department/all');
      return result;
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async getOne(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'getOne',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async getByShortcut(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'getByShortcut',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async update(data) {
    try {
      let result = await axios.put('/department', data);
      return result;
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
};

export default DepartmentAPI;
