import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Bank2017 extends React.Component {
  render() {
    const dualBM = 'Dualer Student im Bereich Bank';
    const dualBW = 'Duale Studentin im Bereich Bank';
    const BM = 'Auszubildender im Bereich Bank';
    const BW = 'Auszubildende im Bereich Bank';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Leslie Brandt'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Tom Fritze'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
          <AzubiCard name={'Stine Herdin'} dkx={''} ausbildungsart={BW} history={this.props.history} />
          <AzubiCard name={'Bjarne Hopp'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
          <AzubiCard name={'Moritz Hüsing'} dkx={''} ausbildungsart={BM} history={this.props.history} />
          <AzubiCard name={'Philip Majer'} dkx={''} ausbildungsart={dualBM} history={this.props.history} />
          <AzubiCard name={'Kim Michelle Maureschaat'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Luisa Meyer'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Katharina Marie Peesel'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Coleen Sackmann'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
          <AzubiCard name={'Nadine Werthmann'} dkx={''} ausbildungsart={dualBW} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Bank2017;
