import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export default class confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationCode: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    try {
      await Auth.confirmSignUp(this.props.location.state.dkx.toLowerCase(), this.state.confirmationCode);
      await Auth.signIn(this.props.location.state.dkx.toLowerCase(), this.props.location.state.password);

      this.props.userHasAuthenticated(true);
      let AktuellerUser = await Auth.currentAuthenticatedUser();
      const { attributes } = AktuellerUser;
      const UserGroup = attributes['custom:group'];
      console.log(UserGroup);
      const currentSession = await Auth.currentSession();
      const RoherIdToken = await currentSession.getIdToken();
      const idToken = await RoherIdToken.getJwtToken();
      this.setState(() => ({ UserIdToken: idToken }));
      let config = {
        headers: { Authorization: this.state.UserIdToken },
      };

      if (UserGroup === 'Azubis') {
        axios
          .post(
            'https://8nfzsh0rvi.execute-api.eu-central-1.amazonaws.com/dev/toad-user-daten',
            {
              Eventart: 'Insert',
              DKX: this.props.location.state.dkx.toLowerCase(),
              Email: this.props.location.state.email.toLowerCase(),
              Nutzergruppe: this.props.location.state.usergroup,
              Ausbildungsrichtung: this.props.location.state.ausbildungsrichtung,
              Vorname: this.props.location.state.vorname,
              Nachname: this.props.location.state.nachname,
              Profilbild:
                'https://toad-cognito-backend-dev-serverlessdeploymentbuck-1k9k8eibnjaz.s3.eu-central-1.amazonaws.com/profilePicture/648969-star-ratings-512.png',
            },
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      if (UserGroup === 'Unterweiser') {
        axios
          .post(
            'https://8nfzsh0rvi.execute-api.eu-central-1.amazonaws.com/dev/toad-user-daten',
            {
              Eventart: 'Insert',
              DKX: this.props.location.state.dkx.toLowerCase(),
              Email: this.props.location.state.email.toLowerCase(),
              Nutzergruppe: this.props.location.state.usergroup,
              Abteilung: this.props.location.state.abteilung,
              Vorname: this.props.location.state.vorname,
              Nachname: this.props.location.state.nachname,
              Profilbild:
                'https://toad-cognito-backend-dev-serverlessdeploymentbuck-1k9k8eibnjaz.s3.eu-central-1.amazonaws.com/profilePicture/648969-star-ratings-512.png',
            },
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      if (UserGroup === 'Ausbilder') {
        axios
          .post(
            'https://8nfzsh0rvi.execute-api.eu-central-1.amazonaws.com/dev/toad-user-daten',
            {
              Eventart: 'Insert',
              DKX: this.props.location.state.dkx.toLowerCase(),
              Email: this.props.location.state.email.toLowerCase(),
              Nutzergruppe: this.props.location.state.usergroup,
              Ausbilderart: this.props.location.state.ausbilderbereich,
              Vorname: this.props.location.state.vorname,
              Nachname: this.props.location.state.nachname,
              Profilbild:
                'https://toad-cognito-backend-dev-serverlessdeploymentbuck-1k9k8eibnjaz.s3.eu-central-1.amazonaws.com/profilePicture/648969-star-ratings-512.png',
            },
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      if (UserGroup === 'Azubis') {
        this.props.history.push('/auszubildende');
      } else if (UserGroup === 'Ausbilder') {
        this.props.history.push('/ausbilder');
      } else if (UserGroup === 'Unterweiser') {
        this.props.history.push('/unterweiser');
      } else {
        console.log('unbekannte Gruppe');
      }
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    return (
      <div className="FullSizeContainer">
        <nav>
          <a className="confirmTitle" href={'/'}>
            TOAD
          </a>
        </nav>
        <div className="confirmBody">
          <div className="vertical">
            <div className={'contentWrapper'} id={'centered'}>
              <h1 className={'confirmH1'}>Sie haben eine Email erhalten.</h1>
              <p>
                Bitte überprüfen Sie zum Abschluss des Registrierungsprozesses Ihren Email-Account
                <br />
                und geben Sie den Bestätigungscode ein.
                <br />
              </p>
              <form onSubmit={this.handleConfirmationSubmit}>
                <input
                  className="inputConfirm"
                  placeholder="Bestätigungscode"
                  id={'confirmationCode'}
                  value={this.state.confirmationCode}
                  type={'text'}
                  onChange={this.handleChange}
                />
                <div className={'LoginButton'} id={'forgotPasswordButton'}>
                  <button className="c-btn  c-btn--icon c-btn--secondary" type="submit" id={'confirmButton'}>
                    <span className="c-btn__text">Abschicken</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
