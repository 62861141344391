import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Versicherung2018 extends React.Component {
  render() {
    const dualVM = 'Dualer Student im Bereich Versicherung';
    const VM = 'Auszubildender im Bereich Versicherung';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Tim Joseph'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
          <AzubiCard name={'Ferda Kota'} dkx={''} ausbildungsart={VM} history={this.props.history} />
          <AzubiCard name={'Marvin Kröger'} dkx={''} ausbildungsart={VM} history={this.props.history} />
          <AzubiCard name={'Jan Messenkopf'} dkx={''} ausbildungsart={dualVM} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Versicherung2018;
