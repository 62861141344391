import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NavigationBarTrainee from '../NavigationBarTrainee';
import HolidayAPI from '../../../api/Azubi/Holidays';
import AutoDatatable from '../../../components/AutoDatatable';
import AutoForm from '../../../components/AutoForm';
import { useHistory, Link } from 'react-router-dom';
import Page from '../../../components/page/Page';
import { toast, ToastContainer } from 'react-toastify';

export default function HolidaysEditOrShow({ editMode }) {
  const showData = [
    {
      header: 'ID',
      accessor: 'id',
      editable: false,
      required: true,
    },
    {
      header: 'Netto Tage',
      accessor: 'nettoDays',
      editable: false,
      required: true,
    },
    {
      header: 'Status',
      accessor: 'state',
      editable: false,
      required: true,
    },
    {
      header: 'Type',
      accessor: 'type',
      editable: false,
      required: true,
    },
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      minDate: 'today',
      required: true,
      type: 'daterangepicker',
    },
    {
      header: 'Abgestimmt mit Abteilung',
      accessor: 'departmentConfirmation',
      type: 'checkbox',
      label: 'Ich habe meine Abwesenheit abgestimmt',
      required: true,
    },
  ];

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();

  const history = useHistory();
  const autoForm = useRef();

  const onSubmitForm = async () => {
    const result = await HolidayAPI.update(data);

    if (!result.error) {
      history.push('/auszubildende/urlaubsuebersicht');
    } else {
      toast.error('Termin konnte nicht angelegt werden: ' + result.msg, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return result;
  };

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const holidayData = await HolidayAPI.getOne({ id: id });
      if (!holidayData.error) {
        setData(holidayData.data[0]);
      }

      setIsLoading(false);
    }
    fetch();
  }, [setData, setIsLoading]);

  return (
    <Page title="Urlaub" navigation={<NavigationBarTrainee></NavigationBarTrainee>}>
      <div className="o-layout">
        {editMode ? (
          Object.keys(data).length > 0 ? (
            <AutoForm
              key="department-request-autoForm"
              data={data}
              dataDescription={showData}
              onValueChange={setData}
              submitForm={onSubmitForm}
              ref={autoForm}
              showLoader={isLoading}
            ></AutoForm>
          ) : null
        ) : (
          <AutoDatatable data={data} dataDescription={showData} isLoading={isLoading}></AutoDatatable>
        )}

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/auszubildende/urlaubsuebersicht'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            {editMode ? (
              <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
                Speichern
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Page>
  );
}
