/* istanbul ignore file */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers/RootReducer';
import reduxWebsocket from '@giantmachines/redux-websocket';

const configureStore = (preloadedState) => {
  // Create the middleware instance.
  const reduxWebsocketMiddleware = reduxWebsocket({ reconnectOnClose: true });

  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunk, reduxWebsocketMiddleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/RootReducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
