import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import ThemesAPI from '../../../api/Global/Themes';
import Table from '../../../components/table/Table';
import { useQueryClient } from 'react-query';

function Theme() {
  const componentPath = 'theme';
  const themesData = ThemesAPI.useGet();
  const queryClient = useQueryClient();

  const onDelete = async (event) => {
    await ThemesAPI.delete(event.target.id);
    ThemesAPI.invalidate(queryClient);
  };

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn--secondary  c-btn--small  c-table__btn c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/edit/${dataId}`}
              className="c-icon c-icon--[semantic-edit] c-icon--small c-btn--secondary  c-btn--small  c-table__btn c-btn--secondary  c-btn--small  c-table__btn"
            />
            <button
              id={dataId}
              onClick={onDelete}
              className="c-icon c-icon--[semantic-close] c-icon--small c-btn--secondary  c-btn--small  c-table__btn c-btn--secondary  c-btn--small  c-table__btn"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <h3>Themen</h3>

      <div className="o-layout">
        <Spinner fullPage display={themesData.isLoading || themesData.isFetching} />

        <div className="o-layout__item o-layout--right">
          <Link to={`${componentPath}/create`} className="c-btn  c-btn--primary">
            <span className="c-btn__text">Hinzufügen</span>
          </Link>
        </div>

        <div className="o-layout__item u-1/1">
          <Table
            defaultPageSize={10}
            ofText={'von'}
            previousText={'Vorherige Seite'}
            pageText={'Seite'}
            rowsText={'Zeilen'}
            nextText={'Nächste Seite'}
            data={themesData.data ?? []}
            noDataText={'Keine Datensätze'}
            columns={columns}
            filterable={true}
          />
        </div>
      </div>
    </>
  );
}

export default Theme;
