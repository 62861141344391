import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import TrainingTypesAPI from '../../../api/Global/TrainingTypes';
import Table from '../../../components/table/Table';
import { useQueryClient } from 'react-query';

function Trainingtypes() {
  const componentPath = 'trainingtypes';
  const [data, setData] = useState([]);
  const trainingTypeData = TrainingTypesAPI.useGetTrainingtypes({ all: true });

  const queryClient = useQueryClient();

  useEffect(() => {
    setData(
      trainingTypeData.data?.map((x) => {
        return { ...x, display: String(x.display), value: x.id };
      })
    );
  }, [trainingTypeData.data]);

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Titel',
      accessor: 'label',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Anzeigen',
      accessor: 'display',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link to={`${componentPath}/${dataId}`} className="c-icon c-icon--[semantic-search] c-icon--small" />
            <Link to={`${componentPath}/edit/${dataId}`} className="c-icon c-icon--[semantic-edit] c-icon--small" />
            <button
              value={dataId}
              onClick={async (id) => {
                await TrainingTypesAPI.delete({ id: id.target.value });
                TrainingTypesAPI.invalidate(queryClient);
              }}
              className="c-icon c-icon--[semantic-close] c-icon--small"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="o-layout">
      <Spinner fullPage display={trainingTypeData.isFetching || trainingTypeData.isLoading} />

      <div className="o-layout__item o-layout--right">
        <Link to={`${componentPath}/create`} className="c-btn  c-btn--primary">
          <span className="c-btn__text">Hinzufügen</span>
        </Link>
      </div>

      <div className="o-layout__item u-1/1">
        <Table
          defaultPageSize={10}
          ofText={'von'}
          previousText={'Vorherige Seite'}
          pageText={'Seite'}
          rowsText={'Zeilen'}
          nextText={'Nächste Seite'}
          data={data ?? []}
          noDataText={'Keine Datensätze'}
          columns={columns}
          filterable={true}
        />
      </div>
    </div>
  );
}

export default Trainingtypes;
