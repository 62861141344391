import axios from 'axios';
import { useQuery } from 'react-query';

const NewsfeedAPI = {
  endpointName: 'toad-newsfeed',
  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName, 'get']);
  },
  create: async function (title, message, withReg) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'create',
        title: title,
        message: message,
        registrationEnabled: withReg,
      });

      return { error: false, status: result.status, data: result.data }; // Ausgabe der Erfolgsmeldung.
    } catch (error) {
      // Einfangen der Fehlermeldung.
      return { error: true, status: error.response.status, msg: error.response.data.msg }; // Ausgabe der Fehlermeldung.
    }
  },
  changeRegistrationState: async function (feedID, count) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'changeRegistrationState',
        newsfeedpostId: feedID,
        count: count,
      });

      return { error: false, status: result.status, data: result.data.data }; // Ausgabe der Erfolgsmeldung.
    } catch (error) {
      // Einfangen der Fehlermeldung.
      return { error: true, status: error.response.status, msg: error.response.data.msg }; // Ausgabe der Fehlermeldung.
    }
  },
  useGet: function (count) {
    return useQuery(
      [this.endpointName, 'get'],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          action: 'get',
          count: count,
        });
        return data.data;
      },
      { staleTime: 1000 * 60 * 1 }
    );
  },
};

export default NewsfeedAPI;
