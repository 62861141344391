import React, { Component } from 'react';

class CardSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <article className="c-card  c-card--small">
        <header className="c-card__header">
          <b className="c-card__title">{this.props.title}</b>
        </header>

        <div className="c-card__body">{this.props.children}</div>
      </article>
    );
  }
}

export default CardSmall;
