import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function userIncludes(userArray, PrivArray) {
  for (var i = 0; i < userArray.length; i++) {
    if (PrivArray.includes(userArray[i])) return true;
  }

  return false;
}

const PrivateRoute = ({ component: Component, users, props, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        props.isAuthenticated === true && userIncludes(users, props.userGroup) ? (
          <Component {...props} />
        ) : props.isAuthenticated === true && !userIncludes(users, props.userGroup) ? (
          <Redirect to={{ pathname: '/noaccess' }} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
};

export default PrivateRoute;
