import React, { Component } from 'react';
import NavigationBarAdmin from './NavigationBarAdmin';

class HomePageAdmin extends Component {
  render() {
    return (
      <div className={'o-page-wrap'}>
        <NavigationBarAdmin></NavigationBarAdmin>
      </div>
    );
  }
} // Ende der "AdminStartPage" Klasse.
export default HomePageAdmin; // Export der "AdminStartPage" Klasse.
