import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NavigationBarAdmin from '../NavigationBarAdmin';
import ThemesAPI from '../../../api/Global/Themes';
import AutoDatatable from '../../../components/AutoDatatable';
import AutoForm from '../../../components/AutoForm';
import { useHistory, Link } from 'react-router-dom';
import Page from '../../../components/page/Page';
import { useQueryClient } from 'react-query';

function ThemeEditOrShow({ editMode }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  const history = useHistory();
  const autoForm = useRef();
  const queryClient = useQueryClient();

  const onSubmitForm = async () => {
    const result = await ThemesAPI.update(data);

    if (!result.error) {
      ThemesAPI.invalidate(queryClient);
      history.push('/admin/theme');
    }

    return result;
  };

  const showData = [
    {
      header: 'ID',
      accessor: 'id',
      editable: false,
      required: true,
    },
    {
      header: 'Titel',
      accessor: 'title',
      required: true,
      notEmpty: true,
    },
  ];

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const themeData = await ThemesAPI.getOne(id);
        if (!themeData.error) setData(themeData.data);
      } catch (e) {
        setData([]);
      }
      setIsLoading(false);
    }
    fetch();
  }, []);

  return (
    <Page title={'Thema bearbeiten'} navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        {editMode ? (
          <AutoForm
            data={data}
            dataDescription={showData}
            onValueChange={setData}
            submitForm={onSubmitForm}
            ref={autoForm}
          ></AutoForm>
        ) : (
          <AutoDatatable data={data} dataDescription={showData} isLoading={isLoading}></AutoDatatable>
        )}

        <div className="o-layout__item">
          <div className="c-button-group">
            <Link to={'/admin/theme'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            {editMode ? (
              <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
                Speichern
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ThemeEditOrShow;
