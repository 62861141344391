import axios from 'axios';
import { useQuery } from 'react-query';

const DepartmentRequestAPI = {
  endpointName: 'toad-department-request',
  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName]);
  },
  async get() {
    try {
      let result = await axios.get('/department-request');
      return result;
    } catch (error) {
      return { error: true, msg: error };
    }
  },
  useGet() {
    return useQuery(
      [this.endpointName],
      async () => {
        const { data } = await axios.get('/department-request');
        return data;
      },
      { staleTime: 1000 * 30 }
    );
  },
  async getOne(data) {
    try {
      let result = await axios.post('/' + DepartmentRequestAPI.endpointName, {
        ...data,
        action: 'getOne',
      });
      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
  async changeStateUnterweiser(data) {
    try {
      let result = await axios.post('/' + DepartmentRequestAPI.endpointName, {
        ...data,
        action: 'changeState',
      });
      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error?.data ?? error?.msg ?? error ?? '' };
    }
  },
  async insert(data) {
    try {
      let result = await axios.post('/' + DepartmentRequestAPI.endpointName, {
        ...data,
        action: 'insert',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, code: error.response.status, msg: error.response.data.data };
    }
  },
  async update(data) {
    try {
      let result = await axios.post('/' + DepartmentRequestAPI.endpointName, {
        ...data,
        action: 'update',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
  async delete(data) {
    try {
      let result = await axios.post('/' + DepartmentRequestAPI.endpointName, {
        ...data,
        action: 'delete',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, msg: error };
    }
  },
};

export default DepartmentRequestAPI;
