import axios from 'axios'; // Import des Axios Framework.
import { useQuery } from 'react-query';

const UserAPI = {
  // lokale DepartmentAPI Konstante.
  endpointName: 'toad-user',

  async getUsernames(usernames) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'getUsernames',
        usernames: usernames,
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  async get() {
    return await axios.get('/user');
  },
  useGet() {
    return useQuery(
      [this.endpointName, 'get'],
      async () => {
        const data = await axios.get('/user');
        return data.data;
      },
      { staleTime: 1000 * 20 * 1 }
    );
  },
  async getOne(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'getOne',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },

  async resetUser(email) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'reset',
        email: email,
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },

  async updateUserProfile(profile) {
    try {
      let result = await axios.put('/user', profile);
      return result;
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },

  async updateUser(data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'updateOne',
      });
      return { error: false, status: result.status, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
};

export default UserAPI;
