import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
      let group = this.props.userGroup;
      let toadAdmin = localStorage.getItem('toad_cache_admin');

      if (
        (toadAdmin !== undefined || toadAdmin !== null) &&
        toadAdmin === '1' &&
        group !== undefined &&
        group.includes('Admin')
      ) {
        this.props.history.push('/admin/users');
        return;
      }

      if (group !== undefined && group.includes('Azubis')) {
        this.props.history.push('/auszubildende');
      } else if (group !== undefined && group.includes('Unterweiser')) {
        this.props.history.push('/unterweiser');
      } else if (group !== undefined && group.includes('Ausbilder')) {
        this.props.history.push('/ausbilder');
      } else {
        this.props.history.push('/login');
      }
    } catch (ex) {
      if (ex === 'not authenticated') {
        this.props.history.push('/login');
      } else {
        console.log(ex);
      }
    }
  }

  render() {
    return <div />;
  }
}

export default withRouter(Home);
