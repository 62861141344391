import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import Spinner from '../components/loader/Spinner';
import { bronsonRegister } from '../shared/BronsonUpdate';

Array.prototype.remove = function () {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

const AutoForm = forwardRef((probs, ref) => {
  const [rerender, setRerender] = useState(0); // integer state
  const [missingFields, setMissingFields] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showLoader, setShowLoad] = useState(false);

  const { data, dataDescription, onValueChange, submitForm, style } = probs;

  useEffect(() => {
    bronsonRegister();

    dataDescription.forEach((descr) => {
      if ((descr?.type === 'daterangepicker' || descr?.type === 'datepicker') && descr?.minDate) {
        const pickr = document.getElementById(descr?.accessor)?.parentNode?.parentNode?._flatpickr;

        if (pickr) {
          pickr.set('minDate', descr?.minDate);
        }
      }

      if (
        descr?.type === 'daterangepicker' &&
        Object.prototype.hasOwnProperty.call(data, descr?.start_accessor) &&
        Object.prototype.hasOwnProperty.call(data, descr?.end_accessor)
      ) {
        const pickr = document.getElementById(descr?.accessor)?.parentNode?.parentNode?._flatpickr;

        if (pickr) {
          pickr.setDate([data[descr?.start_accessor], data[descr?.end_accessor]], true, 'Y-m-d');
          if (descr?.minDate) pickr.set('minDate', descr?.minDate);
        }
      }
    });
  }, []);

  const onChangeEvent = (event) => {
    if (event.target.className.includes('rangepicker')) {
      if (event.target.parentElement.parentElement._flatpickr.selectedDates.length === 2) {
        const dates = event.target.parentElement.parentElement._flatpickr.selectedDates;

        onValueChange({
          ...data,
          [event.target.attributes['data-start-id'].value]: new Date(dates[0]).toISOString(),
          [event.target.attributes['data-end-id'].value]: new Date(dates[1]).toISOString(),
          [event.target.id]: event.target.value,
        });
      }
    } else if (event.target.className.includes('checkbox')) {
      onValueChange({ ...data, [event.target.id]: event.target.checked });
    } else {
      onValueChange({ ...data, [event.target.id]: event.target.value });
    }

    if (missingFields.includes(event.target.id) && event.target.value.length > 0)
      setMissingFields(missingFields.filter((e) => e !== event.target.id) ?? []);
  };

  const onChangeEventTagPicker = (event, descr) => {
    if (!data[event.target.id]) {
      data[event.target.id] = [];
    }

    var selectedItem = descr?.tagpickerOptions?.filter(
      (option) => option[descr.optionsIdAccessor] == event.target.value
    )[0];

    data[event.target.id].push(selectedItem);
    onValueChange({ ...data });
  };

  const validateInput = () => {
    var fields =
      dataDescription
        .filter(function (descr) {
          if (descr.required) {
            const hasProb = !Object.prototype.hasOwnProperty.call(data, descr.accessor);
            if (!hasProb && descr.notEmpty) return !data[descr.accessor].length > 0;

            return hasProb;
          }
          return false;
        })
        .map((descr) => descr.accessor) ?? [];

    setMissingFields(fields);
    return fields.length === 0;
  };

  useImperativeHandle(ref, () => ({
    async onFormSubmit(event, refs = undefined) {
      setShowLoad(true);

      try {
        let refCheckOkay = true;

        if (refs) {
          refs.forEach((ext_ref) => {
            console.log(ext_ref.current.onValidateExtern());
            if (!ext_ref.current.onValidateExtern()) refCheckOkay = false;
          });
        }

        if (refCheckOkay && validateInput()) {
          await submitForm();
        }
      } catch (e) {
        event?.stopPropagation();
        setErrorMsg(e?.msg);
      } finally {
        setShowLoad(false);
      }
    },
    onValidateExtern() {
      return validateInput();
    },
    useForceUpdate() {
      setRerender(rerender + 1);
    },
  }));

  const generateInputbox = (descr) => {
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div className="c-form-field__box">
          <div className="c-input  ">
            <input
              className={`c-input__input ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
              id={descr.accessor}
              type="text"
              value={data[descr.accessor] ?? ''}
              onChange={onChangeEvent}
              placeholder={descr?.placeholder ?? ''}
              data-tr-component="Input Field"
              data-tr-function="Text Input"
              disabled={descr.editable ?? true ? false : true}
            />
          </div>
        </div>
      </div>
    );
  };

  const generateTextarea = (descr) => {
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div className="c-form-field__box">
          <div className="c-input  c-input--textarea">
            <textarea
              className={`c-input__input ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
              id={descr.accessor}
              type="text"
              rows="10"
              value={data[descr.accessor] ?? ''}
              onChange={onChangeEvent}
              placeholder={descr?.placeholder ?? ''}
              data-tr-component="Input Field"
              data-tr-function="Text Input"
              disabled={descr.editable ?? true ? false : true}
            />
          </div>
        </div>
      </div>
    );
  };

  const generateCheckbox = (descr) => {
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <label className="c-checkbox">
          <input
            className={`c-checkbox__input ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
            type="checkbox"
            id={descr.accessor}
            checked={data[descr.accessor] ?? false}
            onChange={onChangeEvent}
            placeholder={descr?.placeholder ?? ''}
            disabled={descr.editable ?? true ? false : true}
          ></input>
          <span className="c-checkbox__label" style={{ textAlign: 'left' }}>
            {descr?.label ?? ''}
          </span>
        </label>
      </div>
    );
  };

  const generateTagpicker = (descr) => {
    console.log(descr);
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div className="c-form-field__box">
          {data[descr.accessor]
            ? data[descr.accessor]?.map((tag) => {
                return (
                  <span
                    key={tag[descr.optionsIdAccessor]}
                    className="c-tag c-tag--info u-mr-xsmall u-mb-xsmall noneSelectable"
                    id={tag[descr.optionsIdAccessor]}
                    style={{ borderRadius: '100px' }}
                  >
                    <span className="c-tag__label">{tag[descr.optionsAccessor]}</span>
                    <button
                      className="c-tag__close"
                      id={tag[descr.optionsIdAccessor]}
                      onClick={(event) => {
                        data[descr.accessor] = data[descr.accessor].filter(
                          (x) => x[descr.optionsIdAccessor] != event.target.id
                        );
                        onValueChange({ ...data });
                      }}
                    />
                  </span>
                );
              })
            : null}
          <div className="c-input  c-input--select">
            <select
              className={`c-input__input tagpicker u-mt-small ${
                missingFields.includes(descr.accessor) ? 'is-error' : ''
              }`}
              value={data[descr.accessor] ?? ''}
              id={descr.accessor}
              onChange={(event) => onChangeEventTagPicker(event, descr)}
            >
              <option defaultValue={{ label: '', id: 0 }} hidden></option>
              {descr?.tagpickerOptions
                ?.filter(
                  (option) =>
                    !data[descr.accessor]?.some((x) => x[descr.optionsIdAccessor] == option[descr.optionsIdAccessor])
                )
                .map((option) => (
                  <option key={option[descr.optionsIdAccessor]} value={option[descr.optionsIdAccessor]}>
                    {option[descr.optionsAccessor]}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const generateDatepicker = (descr) => {
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div className="c-datepicker js-datepicker" data-datepicker-month-selector-type="static">
          <div className="c-input">
            <input
              className={`c-input__input ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
              id={descr.accessor}
              type="text"
              value={data[descr.accessor] ?? ''}
              onInput={onChangeEvent}
              disabled={descr.editable ?? true ? false : true}
              data-input
            />
            <button
              className="c-input__addon  c-input__addon--no-background"
              tabIndex="-1"
              aria-label="toggle datepicker"
              type="button"
              data-toggle
            >
              <i className="c-icon  c-icon--[semantic-calendar]" aria-hidden="true" role="img"></i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const generateDateRangePicker = (descr) => {
    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className={'c-form-field__label '}>
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div
          className="c-datepicker js-datepicker"
          data-datepicker-locale="de"
          data-datepicker-mode="range"
          data-datepicker-month-selector-type="static"
        >
          <div className="c-input">
            <input
              className={`c-input__input rangepicker ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
              id={descr.accessor}
              type="text"
              autoComplete="off"
              value={data[descr.accessor] ?? ''}
              onInput={onChangeEvent}
              onChange={onChangeEvent}
              disabled={descr.editable ?? true ? false : true}
              data-start-id={descr.start_accessor}
              data-end-id={descr.end_accessor}
              data-input
            />
            <button
              className="c-input__addon  c-input__addon--no-background"
              tabIndex="-1"
              aria-label="toggle datepicker"
              type="button"
              data-toggle
            >
              <i className="c-icon  c-icon--[semantic-calendar]" aria-hidden="true" role="img"></i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const generateCombobox = (descr) => {
    if (descr?.sort == true && descr?.comboboxOptions) {
      descr.comboboxOptions = descr?.comboboxOptions.sort(function compare(a, b) {
        if (a.display < b.display) {
          return -1;
        }
        if (a.display > b.display) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <div className="c-form-field  ">
        {descr.header ? (
          <label htmlFor={descr.accessor} className="c-form-field__label">
            {descr.header + (descr.required || descr.conditional?.required ? '*' : '')}
          </label>
        ) : null}
        <div className="c-form-field__box">
          <div className="c-input  c-input--select">
            <select
              className={`c-input__input ${missingFields.includes(descr.accessor) ? 'is-error' : ''}`}
              value={data[descr.accessor] ?? ''}
              id={descr.accessor}
              onChange={onChangeEvent}
            >
              <option defaultValue={{ label: '', value: 0 }} hidden></option>
              {descr?.comboboxOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.display}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const getElement = (descr) => {
    if (descr.conditional) {
      if (!Object.prototype.hasOwnProperty.call(data, descr.conditional.accessor)) return null;

      if (descr.conditional.condition) {
        if (descr.conditional.condition == 'isTrue') {
          if (data[descr.conditional.accessor] != true || data[descr.conditional.accessor] == undefined) return null;
        } else if (descr.conditional.condition == 'isFalse') {
          if (data[descr.conditional.accessor] != false || data[descr.conditional.accessor] == undefined) return null;
        }
      } else if (!descr.conditional.value.includes(data[descr.conditional.accessor])) {
        return null;
      }
    }

    switch (descr?.type) {
      case 'combobox':
        return generateCombobox(descr);
      case 'datepicker':
        return generateDatepicker(descr);
      case 'daterangepicker':
        return generateDateRangePicker(descr);
      case 'textarea':
        return generateTextarea(descr);
      case 'tagpicker':
        return generateTagpicker(descr);
      case 'checkbox':
        return generateCheckbox(descr);
      case 'input':
      default:
        return generateInputbox(descr);
    }
  };

  return (
    <>
      <Spinner display={showLoader} fullPage />
      <div className={'o-layout__item '} style={style ?? undefined}>
        {data && dataDescription ? (
          <>
            <fieldset className="o-fieldset">
              {dataDescription.map((descr) => {
                return !descr.autoDatatableOnly ? (
                  <React.Fragment key={dataDescription.accessor}>
                    <div className="o-fieldset__row">{getElement(descr)}</div>
                  </React.Fragment>
                ) : null;
              })}
              {errorMsg ? <label className={'c-error-message'}>Fehler: {errorMsg}</label> : null}
            </fieldset>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

AutoForm.displayName = 'AutoForm';

export default AutoForm;
