import React, { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AutoForm from '../../../components/AutoForm';
import NavigationBarTrainee from './../../Trainee/NavigationBarTrainee';
import HolidayAPI from '../../../api/Azubi/Holidays';
import Page from '../../../components/page/Page';
import { toast, ToastContainer } from 'react-toastify';

export default function HolidaysCreate() {
  const [data, setData] = useState([]);
  const history = useHistory();
  const autoForm = useRef();

  const onSubmitForm = async () => {
    const result = await HolidayAPI.create(data);

    if (!result.error) {
      history.push('/auszubildende/urlaubsuebersicht');
    } else {
      toast.error('Termin konnte nicht angelegt werden: ' + result.msg, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showData = [
    {
      header: 'Urlaubs Typ',
      accessor: 'type',
      required: true,
      type: 'combobox',
      comboboxOptions: [
        { display: 'TU', value: 'TU' },
        { display: 'EGZ', value: 'EGZ' },
      ],
    },
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      minDate: 'today',
      required: true,
      type: 'daterangepicker',
    },
    {
      header: 'Abgestimmt mit Abteilung',
      accessor: 'departmentConfirmation',
      type: 'checkbox',
      label: 'Ich habe meine Abwesenheit abgestimmt',
      required: true,
    },
  ];

  return (
    <Page title="Urlaub erstellen" navigation={<NavigationBarTrainee></NavigationBarTrainee>}>
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/auszubildende/urlaubsuebersicht'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Page>
  );
}
