import { Auth } from 'aws-amplify';
import axios from 'axios';

export function configureDefaults() {
  axios.defaults.headers['x-api-key'] = process.env.REACT_APP_x_api_key;
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  axios.interceptors.request.use(async (config) => {
    try {
      const session = await Auth.currentSession();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: 'Bearer ' + session.getIdToken().getJwtToken(),
        },
      };
    } catch (e) {
      console.log(e);
      return config;
    }
  });
}
