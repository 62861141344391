import React, { useEffect, useState } from 'react';

import CardSmall from '../../components/cards/CardSmall';
import Spinner from '../../components/loader/Spinner';
import BigPopup from '../../components/popup/BigPopup';
import NavigationBarCoaches from './NavigationBarCoaches';
import { Link, withRouter } from 'react-router-dom';
import CoachesDataService from '../../service/CoachesDataService';
import Page from '../../components/page/Page';
import { useDispatch } from 'react-redux';
import { getThemes } from '../../redux/actions/ThemeAction';

const HomePageCoaches = () => {
  const [userDepartment, setUserDepartment] = useState([]);
  const [openRequests, setOpenRequests] = useState([]);
  const [acceptedDepartmentRequests, setAcceptedDepartmentRequests] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(true);
  const [displayPopUpSoon, setDisplayPopUpSoon] = useState(false);
  const [displayPopUpNow, setDisplayPopUpNow] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        const service = new CoachesDataService();
        const department = await service.getCoachesDepartment();
        setUserDepartment(department);
        const departmentsRequest = await service.getOpenDepartmentRequests(department);
        setOpenRequests(departmentsRequest.filter((dr) => dr.state === 'offen'));
        setAcceptedDepartmentRequests(departmentsRequest.filter((dr) => dr.state !== 'offen'));

        console.log(userDepartment);
        console.log(openRequests);
        console.log(acceptedDepartmentRequests);
      } catch (e) {
        console.error(e);
      } finally {
        setDisplayLoader(false);
      }
    }

    fetchMyAPI();

    dispatch(getThemes());
  }, []);

  const onShowPopUpSoon = () => {
    setDisplayPopUpSoon(true);
  };

  const onShowPopUpNow = () => {
    setDisplayPopUpNow(true);
  };

  const onClosePopUpSoon = () => {
    setDisplayPopUpSoon(false);
  };

  const onClosePopUpNow = () => {
    setDisplayPopUpNow(false);
  };

  return (
    <Page navigation={<NavigationBarCoaches />} title="Willkommen">
      <Spinner display={displayLoader} fullPage />

      <div className="o-layout">
        <div className="o-layout__item u-1/2 u-1/1@s">
          <CardSmall title={'Offene Anfragen'}>
            {openRequests ? <p>Es gibt {openRequests?.length} offene Anfrage(n).</p> : null}
            <Link
              to="/unterweiser/einsatzanfragen"
              className="c-btn c-btn--secondary"
              type="submit"
              id="HomePageCoachButton"
            >
              <span className="c-btn__text">Anfragen verwalten</span>
            </Link>
          </CardSmall>
        </div>
        <div className="o-layout__item u-1/2 u-1/1@s">
          <CardSmall title={'Kommende Azubis'}>
            <p>Für die nächste Zeit sind bereits {acceptedDepartmentRequests?.future?.length} Anfragen bestätigt.</p>
            <button className="c-btn c-btn--secondary" type="submit" id="HomePageCoachButton">
              <span className="c-btn__text" onClick={onShowPopUpSoon}>
                Alle sehen
              </span>
            </button>
          </CardSmall>
        </div>
        <div className="o-layout__item u-1/1 u-1/1@s">
          <CardSmall title={'Aktuell in der Abteilung'}>
            <p>Momentan sind {acceptedDepartmentRequests?.actual?.length} Azubis in Ihrer Abteilung.</p>
            <button className="c-btn c-btn--secondary" type="submit" id="HomePageCoachButton">
              <span className="c-btn__text" onClick={onShowPopUpNow}>
                Alle sehen
              </span>
            </button>
          </CardSmall>
        </div>
      </div>
      <BigPopup open={displayPopUpSoon} onClose={onClosePopUpSoon}>
        <h3></h3>
        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th style={{ width: '33%' }}>#</th>
              <th style={{ width: '33%' }}>Name</th>
              <th style={{ width: '33%' }}>Zeitraum</th>
            </tr>
          </thead>
        </table>
      </BigPopup>
      <BigPopup open={displayPopUpNow} onClose={onClosePopUpNow}>
        <h3></h3>
        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th style={{ width: '33%' }}>#</th>
              <th style={{ width: '33%' }}>Name</th>
              <th style={{ width: '33%' }}>Zeitraum</th>
            </tr>
          </thead>
        </table>
      </BigPopup>
    </Page>
  );
};
export default withRouter(HomePageCoaches);
