const TimeConverter = {
  convertEUTimeToIso: function (strTime) {
    if (strTime.indexOf('.') >= 0) {
      var parts = strTime.split('.');
      return parts[2] + '-' + parts[1] + '-' + parts[0];
    }
    return strTime;
  },
  convertIsoTimeToEU: function (strTime) {
    if (strTime.indexOf('-') >= 0) {
      var parts = strTime.split('-');
      return parts[2] + '-' + parts[1] + '-' + parts[0];
    }
    return strTime;
  },
  compareDates: function (date1, date2) {
    var d1 = Date.parse(this.convertEUTimeToIso(date1));
    var d2 = Date.parse(this.convertEUTimeToIso(date2));

    if (d1 == d2) return 0;
    else if (d1 < d2) return -1;
    else return 1;
  },
  zeroFill: function (number, width) {
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
    }
    return number + ''; // always return a string
  },
  convertTimestampToDateTime: function (timestamp) {
    var datestamp = new Date(timestamp);

    return (
      this.zeroFill(datestamp.getDate(), 2) +
      '.' +
      this.zeroFill(datestamp.getMonth() + 1, 2) +
      '.' +
      datestamp.getFullYear() +
      ' - ' +
      this.zeroFill(datestamp.getHours(), 2) +
      ':' +
      this.zeroFill(datestamp.getMinutes(), 2)
    );
  },
  formatDate: function (input) {
    let datePart = input.match(/\d+/g),
      year = datePart[0].substring(0), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + '.' + month + '.' + year;
  },
  formatMysqlDate: function (input) {
    const event = new Date(Date.parse(input));

    let datePart = event.toLocaleString('de-DE').match(/\d+/g),
      year = datePart[2].substring(0), // get only two digits
      month = datePart[1].padStart(2, '0'),
      day = datePart[0].padStart(2, '0'),
      hours = datePart[3].padStart(2, '0'),
      minutes = datePart[4].padStart(2, '0'),
      seconds = datePart[5].padStart(2, '0');

    return day + '.' + month + '.' + year + ' - ' + hours + ':' + minutes + ':' + seconds;
  },
  parseDate: function (input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g),
      i = 0,
      fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) {
      fmt[part] = i++;
    });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
  },
};

export default TimeConverter;
