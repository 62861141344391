import UserAPI from '../../api/Admin/User';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';
export const ADD_PROFILE_IMAGE = 'ADD_PROFILE_IMAGE';

export function getProfile() {
  return async (dispatch) => {
    dispatch({ type: GET_PROFILE_START });

    try {
      let response = await UserAPI.get();
      dispatch({
        type: GET_PROFILE_SUCCESS,
        profile: response.data,
      });
    } catch (e) {
      dispatch({
        type: GET_PROFILE_ERROR,
        error: parseError(e),
      });
    }
  };
}

export function updateProfile(profile) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PROFILE_START });
    try {
      const response = await UserAPI.updateUserProfile(profile);
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        profile: response.data,
      });
      // if (data._id && file) {
      //   dispatch(uploadAttachment(EntityType.profile, data._id, [file]));
      // }
      // if (attachmentsToDelete && attachmentsToDelete.length > 0) {
      //   attachmentsToDelete.forEach(
      //     (attachmentId) => data._id && dispatch(handleDelete(EntityType.profile, data._id, attachmentId))
      //   );
      // }
    } catch (e) {
      dispatch({
        type: UPDATE_PROFILE_ERROR,
        error: parseError(e),
      });
    }
  };
}

// export function addProfileImage(file?: FileUploadData): ThunkAction<Promise<void>, ApplicationState, void, AnyAction> {
//     return async (dispatch: ThunkDispatch<ApplicationState, void, AnyAction>) => {
//         dispatch({
//             type: ADD_PROFILE_IMAGE,
//             image: file,
//         });
//     };
// }

function parseError(e) {
  let error;
  try {
    error = JSON.parse(e.response.data.substr('[xxx] '.length));
  } catch (e2) {
    error = e.message;
  }
  return error;
}
