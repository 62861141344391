import React, { Component } from 'react';
import './App.css';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import Routes from './Routes';
import Footer from './components/footer/Footer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: '0',
      AnsichtSelect: '0',
      isAuthenticated: false,
      isAuthenticating: true,
      userGroup: undefined,
    };
    this.inputChange = this.inputChange.bind(this);
  }

  async componentDidMount() {
    try {
      var user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const group = attributes['custom:group'];

      this.userHasAuthenticated(true);
      this.setUserGroup(group);
    } catch (e) {
      console.error(e);
      this.userHasAuthenticated(false);
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUserGroup = (userGrp) => {
    this.setState({ userGroup: userGrp });
  };

  inputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userGroup: this.state.userGroup,
      setUserGroup: this.setUserGroup,
    };

    return (
      !this.state.isAuthenticating && (
        <div>
          <main className="o-main">
            <Routes childProps={childProps} />
          </main>
          <Footer></Footer>
        </div>
      )
    );
  }
}

export default withRouter(App);
