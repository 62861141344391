import React, { useEffect, useRef, useState } from 'react';
import Page from '../../components/page/Page';
import NavigationBarTrainer from './NavigationBarTrainer';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import TrainingTypesAPI from '../../api/Global/TrainingTypes';
import AutoForm from '../../components/AutoForm';
import { DatesAPI } from '../../api/Ausbilder/Dates';
import Spinner from '../../components/loader/Spinner';

const AusbilderUebersicht = () => {
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);
  const [dateData, setDateData] = useState([]);
  const autoForm = useRef();
  const trainingTypesData = TrainingTypesAPI.useGetWithYear();

  const const_showData = [
    {
      header: 'Gruppen Anzeigen',
      accessor: 'participantGroups',
      type: 'tagpicker',
      optionsAccessor: 'display',
      optionsIdAccessor: 'value',
      tagpickerOptions: [],
    },
  ];

  const [showData, setShowData] = useState(const_showData);

  useEffect(() => {
    if (trainingTypesData.data) {
      showData[0].tagpickerOptions = trainingTypesData?.data?.map((y) => {
        return {
          display: y.trainingtype.label + ' ' + y.year,
          value: y.trainingtype.id + '' + y.year,
          year: y.year,
          trainingtype: y.trainingtype.id,
        };
      });

      setShowData(showData);
      autoForm.current.useForceUpdate();
    }
  });

  useEffect(() => {
    //setIsLoading(true);
    const fetch = async () => {
      try {
        let tmp_dateData = await DatesAPI.getCached(data.participantGroups);
        if (tmp_dateData) {
          let mappedData = [];
          let mappedDateData = [];
          for (let i = 0; i < tmp_dateData.length; i++) {
            mappedData = [
              ...mappedData,
              ...tmp_dateData[i].map((x) => {
                return {
                  id: x.id,
                  title: x.firstname + ' ' + x.lastname,
                };
              }),
            ];

            for (let y = 0; y < tmp_dateData[i].length; y++) {
              // absences
              for (let z = 0; z < tmp_dateData[i][y].absences.length; z++) {
                let curEle = tmp_dateData[i][y].absences[z];
                let user = tmp_dateData[i][y];

                if (curEle.state != 'abgelehnt') {
                  mappedDateData.push({
                    id: 'id_abst_' + curEle.id + '' + user.id,
                    key: 'abst_' + curEle.id + '' + user.id,
                    group: user.id,
                    title: curEle.type + ' ' + curEle.state,
                    start_time: moment(curEle.start),
                    end_time: moment(curEle.end),
                  });
                }
              }
              //dates
              for (let z = 0; z < tmp_dateData[i][y].dates.length; z++) {
                let curEle = tmp_dateData[i][y].dates[z];
                let user = tmp_dateData[i][y];
                mappedDateData.push({
                  id: 'id_date_' + curEle.id + '' + user.id,
                  key: 'date_' + curEle.id + '' + user.id,
                  group: user.id,
                  title: curEle.title,
                  start_time: moment(curEle.start),
                  end_time: moment(curEle.end),
                });
              }

              //departmentRequests
              for (let z = 0; z < tmp_dateData[i][y].departmentRequests.length; z++) {
                let curEle = tmp_dateData[i][y].departmentRequests[z];
                let user = tmp_dateData[i][y];

                if (curEle.state == 'akzeptiert') {
                  mappedDateData.push({
                    id: 'id_department_' + curEle.id + '' + user.id,
                    key: 'department_' + curEle.id + '' + user.id,
                    group: user.id,
                    title: curEle.department.shortcut,
                    start_time: moment(curEle.start),
                    end_time: moment(curEle.end),
                  });
                }
              }
            }
          }

          setDateData(mappedDateData);
          setGroups(mappedData);
          //setIsLoading(false);
        }
      } catch (ex) {
        //setIsLoading(false);
      }
    };
    fetch();
  }, [data]);

  return (
    <>
      <Page title={'Übersicht'} navigation={<NavigationBarTrainer history={history} />}>
        <Spinner fullPage display={trainingTypesData.isLoading || trainingTypesData.isFetching}></Spinner>
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={null}
          ref={autoForm}
          style={{
            marginBottom: '20px',
          }}
        ></AutoForm>
        <div className={'o-layout__item '}>
          {dateData.length > 0 && groups.length > 0 ? (
            <Timeline
              fullUpdate
              groups={groups}
              items={dateData}
              defaultTimeStart={moment().add(-12, 'days')}
              defaultTimeEnd={moment().add(12, 'days')}
              stackItems={true}
              timeSteps={{
                second: 0,
                minute: 0,
                hour: 0,
                day: 1,
                month: 1,
                year: 1,
              }}
            />
          ) : (
            <p>Bitte Jahrgang auswählen</p>
          )}
        </div>
      </Page>
    </>
  );
};
export default AusbilderUebersicht;
