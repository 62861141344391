import React, { useEffect, useRef, useState } from 'react';
import Page from '../../components/page/Page';
import 'react-toastify/dist/ReactToastify.css';
import AutoForm from '../../components/AutoForm';
import ThemesAPI from '../../api/Global/Themes';
import DepartmentAPI from '../../api/Global/Department';
import NavigationBarTrainee from './NavigationBarTrainee';
import Spinner from '../../components/loader/Spinner';

const DepartmentSearch = () => {
  const [data, setData] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const autoForm = useRef();

  let themes = ThemesAPI.useGet();
  const departmentData = DepartmentAPI.useDepartmentGetAll(true);

  const const_showData = [
    {
      header: 'Interessen',
      accessor: 'interests',
      type: 'tagpicker',
      optionsAccessor: 'title',
      optionsIdAccessor: 'id',
      tagpickerOptions: [],
    },
  ];

  const [showData, setShowData] = useState(const_showData);

  useEffect(() => {
    const_showData[0].tagpickerOptions = themes?.data;
    setShowData(const_showData);
  }, [themes.data]);

  useEffect(() => {
    var filtered = departmentData.data;
    console.log(data);
    if (data?.interests?.length > 0) {
      filtered =
        filtered?.filter((x) => {
          if (x.topics.length <= 0) {
            return false;
          }

          let found = true;
          for (let i = 0; i < data.interests?.length; i++) {
            console.log(data);
            if (!x.topics.find((x) => x.id === data.interests[i].id)) found = false;
          }

          return found;
        }) ?? [];
    }

    setFilteredDepartments(filtered);
  }, [data, departmentData.data]);

  return (
    <Page title={'Abteilungssuche'} navigation={<NavigationBarTrainee history={history} />}>
      <>
        <Spinner
          fullPage
          display={themes.isLoading || themes.isFetching || departmentData.isLoading || departmentData.isFetching}
        ></Spinner>
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={null}
          ref={autoForm}
          style={{
            marginBottom: '20px',
          }}
        ></AutoForm>
        <div className={'o-layout__item '}>
          {filteredDepartments?.map((x) => {
            return (
              <div className="newsEntry" key={x.shortcut}>
                <div className="o-layout">
                  <div className="o-layout__item  u-1/2">
                    <h4 className={'newsEntryTitle'}>{x.shortcut}</h4>
                    <p className={'newsEntryP'} align="left">
                      {x.description}
                    </p>
                    <p>Themen: {x.topics.map((y) => y.title).join(', ')}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </Page>
  );
};
export default DepartmentSearch;
