import React, { Component } from 'react';
import errorToad from '../images/error_toad.png';

export default class NoAccess extends Component {
  render() {
    return (
      <div className="AccessDeniedPage">
        <div className="lander">
          <div className="o-chop-apart c-card c-card--center ">
            <fieldset className="o-fieldset  u-mb">
              <h1>ACCESS DENIED</h1>
              <img src={errorToad} alt={'Errorbild'} />
              <h5>{`There's nothing to see here. We know it was just a mistake that you ended up here...`}</h5>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}
