import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../loader/Spinner';
import Page from '../page/Page';
import UserAPI from '../../api/Admin/User';

const Profile = ({ navigation }) => {
  // const history = useHistory(); import useHistory bei Link
  const userDataReq = UserAPI.useGet();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (userDataReq.data) setUserData(userDataReq.data);
  }, [userDataReq.data]);

  return (
    <Page navigation={navigation}>
      <div className="o-layout u-ml-xlarge u-mr-xlarge">
        <div className="o-layout__item">
          <Spinner display={userDataReq.isLoading || userDataReq.isFetching}></Spinner>
          {userData ? (
            <div>
              <div className="container-profileHeader">
                <div className="profileIcon">
                  <h1 className="icon-profile-initial">
                    {userData?.firstname.charAt(0)}
                    {userData?.lastname.charAt(0)}
                  </h1>
                </div>
                <div>
                  <h2 className="u-mt-large u-ml">
                    {userData?.firstname} {userData?.lastname}
                  </h2>
                  <p className="u-ml">{userData?.userGroup}</p>
                </div>
              </div>
              <div className="nav-profile u-mt-large">
                <hr></hr>
              </div>
              <div className="content-profile o-layout">
                <div className="u-2/3 o-layout__item u-mb-small">
                  <h3>Profil</h3>
                  <p>Ausbildungsstart: 2018</p>
                </div>
                <div className="u-1/3 o-layout__item u-mb-small">
                  <div className="u-5/5 container-relative">
                    <Link to="./account/editprofile" className="c-btn  c-btn--secondary c-btn-profile-edit">
                      <span className="c-btn__text">Profil bearbeiten</span>
                    </Link>
                  </div>
                </div>
                <div className="u-2/3 o-layout__item">
                  <h4>Interessen</h4>
                  {userData?.topics.map((x) => (
                    <span key={x.title} className="tag-wrap tag">
                      {x.title}
                    </span>
                  ))}
                </div>
                <div className="u-1/3 o-layout__item">
                  <h4>Kontakt</h4>
                  <p className="u-mb-none">Email</p>
                  <p>{userData?.email}</p>
                  <p className="u-mb-none">Telefon</p>
                  <p>{userData?.phone ? userData?.phone : 'Keine Angaben'}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  );
};

export default Profile;
