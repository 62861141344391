const statusFunctions = {
  statusTranslator: function (status) {
    switch (status) {
      case 'open':
        return 'Offen';
      case 'withdrawalRequested':
        return 'Widerrufung angefragt';
      case 'withdrawn':
        return 'Widerrufen';
      case 'possible':
        return 'Möglich';
      case 'notPossible':
        return 'Nicht Möglich';
      case 'postponementRequested':
        return 'Verschiebung angefragt';
      case 'confirmed':
        return 'Bestätigt';
      case 'declined':
        return 'Abgelehnt';
      default:
        return '';
    }
  },
};
export default statusFunctions;
