import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AutoForm from '../../../components/AutoForm';
import TrainingTypesAPI from '../../../api/Global/TrainingTypes';
import DepartmentAPI from '../../../api/Global/Department';
import NavigationBarAdmin from './../../Administrator/NavigationBarAdmin';
import Spinner from '../../../components/loader/Spinner';
import Page from '../../../components/page/Page';
import AdminApi from '../../../api/Admin/AdminApi';

function UserCreate() {
  const [data, setData] = useState([]);
  //const [showLoader, setShowloader] = useState(false);
  const [showData, setShowData] = useState([]);
  const autoForm = useRef();
  const history = useHistory();

  const trainingTypeData = TrainingTypesAPI.useGetTrainingtypes();
  const departmentData = DepartmentAPI.useDepartmentGetAll();

  console.log(departmentData);

  const onSubmitForm = useCallback(async () => {
    const api = new AdminApi();
    const result = await api.createUser(data);
    if (!result.error) history.goBack();
  }, [data, history]);

  let const_showData = [
    {
      header: 'Gruppe',
      accessor: 'group',
      required: true,
      type: 'combobox',
      comboboxOptions: [
        { display: 'Azubis', value: 'Azubis' },
        { display: 'Unterweiser', value: 'Unterweiser' },
        { display: 'Ausbilder', value: 'Ausbilder' },
        { display: 'Azubis, Admin', value: 'Azubis, Admin' },
      ],
    },
    {
      header: 'Ausbildungstyp',
      accessor: 'trainingTypeId',
      type: 'combobox',
      conditional: {
        accessor: 'group',
        value: ['Azubis', 'Azubis, Admin'],
      },
      comboboxOptions: [],
    },
    {
      header: 'Abteilung',
      accessor: 'department',
      type: 'combobox',
      conditional: {
        accessor: 'group',
        value: ['Unterweiser'],
      },
      comboboxOptions: [],
    },
    {
      header: 'Vorname',
      accessor: 'forename',
      required: true,
      notEmpty: true,
    },
    {
      header: 'Nachname',
      accessor: 'name',
      required: true,
      notEmpty: true,
    },
    {
      header: 'EMail',
      accessor: 'email',
      required: true,
      notEmpty: true,
    },
    {
      header: 'Telefon',
      accessor: 'phone',
      required: false,
    },
    {
      header: 'Jahrgang',
      accessor: 'year',
      conditional: {
        required: true,
        accessor: 'group',
        value: ['Azubis', 'Azubis, Admin'],
      },
    },
  ];

  useEffect(() => {
    console.log('useEffect');
    const_showData[1].comboboxOptions = trainingTypeData.data?.map((x) => {
      return { display: x.label, value: x.id };
    });

    const_showData[2].comboboxOptions = departmentData?.data?.map((x) => {
      return { display: x.shortcut, value: x.id };
    });

    setShowData(const_showData);
  }, [trainingTypeData.data, departmentData.data]);

  return (
    <Page title="Benutzer erstellen" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <Spinner fullPage display={trainingTypeData.isLoading || departmentData.isLoading}></Spinner>

      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/admin/users'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default UserCreate;
