import React from 'react';
import i18n from '../../i18n';
import PackageList from './PackageList';

/**
 * Component to render the sections for each indivual license in use.
 * @param {object} licenses - license information
 * @returns {*} jsx element
 *
 * @example
 * let licenses = {
 *   [
 *     license: "mit",
 *     packages: [{
 *       name: "foo",
 *       version: "1.0",
 *       copyright: "Volkswagen AG",
 *     }],
 *   ]
 * };
 *
 * <LicenseSections licenses={licenses}/>
 */
const LicenseSections = ({ licenses }) => {
  return (
    <>
      {licenses.map((section) => (
        <div key={section.license}>
          <h4>{i18n.t('license:LicenseView.Text.Name.' + section.license)}</h4>
          <div>
            <div className="c-table-wrapper c-table-wrapper--auto">
              <table>
                <thead>
                  <tr>
                    <th>{i18n.t('license:LicenseView.Text.Component')}</th>
                    <th>{i18n.t('license:LicenseView.Text.Copyright')}</th>
                  </tr>
                </thead>
                <tbody>
                  <PackageList packages={section.packages} />
                </tbody>
              </table>
            </div>
          </div>
          <div className="u-mv">
            <p>
              {i18n.t('license:LicenseView.Text.LinkTo')}
              <a target="_blank" rel="noopener noreferrer" href={i18n.t('license:LicenseView.Link.' + section.license)}>
                {i18n.t('license:LicenseView.Text.Name.' + section.license)}
              </a>
            </p>
            <p className="pre-wrap  u-mt-small  u-mb">
              {i18n.t('license:LicenseView.Text.LicenseText.' + section.license)}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default LicenseSections;
