// React Framework Imports.
import React, { Component } from 'react';
import Theme from './Theme/Theme';
import NavigationBarAdmin from './NavigationBarAdmin';
import Page from '../../components/page/Page';

class ThemesLexiconAdmin extends Component {
  // Beginn der "AdminLexicon" Komponente.
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Page navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
        <Theme></Theme>
      </Page>
    );
  }
}
export default ThemesLexiconAdmin;
