import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillMount() {}

  async componentDidMount() {}

  render() {
    return (
      <footer className="c-footer">
        <div className="c-footer__module  c-footer__module--nav">
          <div className="o-page-wrap">
            <nav className="c-footer-meta-bar">
              <ul className="c-footer-meta-nav">
                <li className="c-footer-meta-nav__item">
                  <a
                    className="c-footer-meta-nav__link"
                    href="https://group-wiki.wob.vw.vwg/wikis/pages/viewpage.action?pageId=1135871854"
                  >
                    Zentrale Datenschutzinformationen für Beschäftigte
                  </a>
                </li>

                <li className="c-footer-meta-nav__item">
                  <Link className="c-footer-meta-nav__link" to="/license">
                    License
                  </Link>
                </li>
              </ul>
              <span className="c-footer-copyright">
                <span className="c-footer-copyright__sign">©</span>
                <span className="c-footer-copyright__text">FS AG</span>
              </span>
            </nav>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
