import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NavigationBarAdmin from '../NavigationBarAdmin';
import UserAPI from '../../../api/Admin/User';
import AutoDatatable from '../../../components/AutoDatatable';
import AutoForm from '../../../components/AutoForm';
import { Link } from 'react-router-dom';
import Page from '../../../components/page/Page';
import TrainingTypesAPI from '../../../api/Global/TrainingTypes';
import { useHistory } from 'react-router-dom';

function UserEditOrShow({ editMode }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();
  const autoForm = useRef();
  const history = useHistory();

  const trainingTypeData = TrainingTypesAPI.useGetTrainingtypes();

  const onSubmitForm = () => {
    setIsLoading(true);
    const status = UserAPI.updateUser(data);
    setIsLoading(false);

    if (!status.error) history.push('/admin/users');
  };

  const const_showData = [
    {
      header: 'ID',
      accessor: 'id',
      editable: false,
      required: true,
    },
    {
      header: 'Vorname',
      accessor: 'firstname',
      required: true,
      notEmpty: true,
    },
    {
      header: 'Nachname',
      accessor: 'lastname',
      required: true,
      notEmpty: true,
    },
    {
      header: 'E-Mail',
      accessor: 'email',
      required: true,
      editable: false,
      notEmpty: true,
    },
    {
      header: 'Telefon',
      accessor: 'phone',
    },
    {
      header: 'Jahrgang',
      accessor: 'year',
      type: 'number',
    },
    {
      header: 'Benutzer Gruppe',
      accessor: 'userGroup',
      editable: false,
    },
    {
      header: 'Ausbildungstyp',
      accessor: 'trainingTypeId',
      type: 'combobox',
      conditional: {
        accessor: 'userGroup',
        value: ['Azubis', 'Azubis, Admin'],
      },
      comboboxOptions: [],
    },
  ];

  const [showData, setShowData] = useState(const_showData);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const userData = await UserAPI.getOne({ id: id });
        if (!userData.error) setData(userData.data);
      } catch (e) {
        setData([]);
      }
      setIsLoading(false);
    }
    fetch();
  }, []);

  useEffect(() => {
    console.log(const_showData);
    const_showData[7].comboboxOptions = trainingTypeData.data?.map((x) => {
      return { display: x.label, value: x.id };
    });

    setShowData(const_showData);
  }, [trainingTypeData.data]);

  return (
    <Page title="Benutzer bearbeiten" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        {editMode ? (
          <AutoForm
            data={data}
            dataDescription={showData}
            onValueChange={setData}
            submitForm={onSubmitForm}
            ref={autoForm}
          ></AutoForm>
        ) : (
          <AutoDatatable
            data={data}
            dataDescription={showData}
            isLoading={isLoading || trainingTypeData.isLoading}
          ></AutoDatatable>
        )}

        <div className="o-layout__item">
          <div className="c-button-group">
            <Link to={'/admin/users'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            {editMode ? (
              <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
                Speichern
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default UserEditOrShow;
