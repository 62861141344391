// React Framework Imports.
import React, { Component } from 'react';
import Flickity from 'react-flickity-component';
import Select from 'react-select';
// Komponenten Imports
import NavigationBarTrainee from './NavigationBarTrainee';
import Spinner from '../../components/loader/Spinner';
// API Imports
import DepartmentAPI from '../../api/Global/Department';
import ThemesAPI from '../../api/Global/Themes';
// CSS Imports
import '../../App.css';
// import UserDataAPI from '../../api/Global/UserData';
import DepartmentMatchingCard from '../../components/cards/DepartmentMatchingCard';
import DepartmentCard from '../../components/cards/DepartmentCard';

class DepartmentMatchingAndOverviewTrainee extends Component {
  // Beginn der "AzubiMatching" Klasse.

  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      display: 'matching',
      flickityOptions: { groupCells: true },
      matches: [],
      randomMatches: [],
    };
    this.UNSAFE_componentWillMount = this.UNSAFE_componentWillMount.bind(this);
    this.handleChangeSelectDepartmentThemes = this.handleChangeSelectDepartmentThemes.bind(this);
    this.loadDepartments = this.loadDepartments.bind(this);
    this.loadThemes = this.loadThemes.bind(this);
    this.randomSuggestions = this.randomSuggestions.bind(this);
  }

  async UNSAFE_componentWillMount() {
    // Beginn des "componentWillMount" Events.
    this.setState({ displayLoader: true }); // Ladebildschirm wird gestartet.
    await this.loadThemes();
    await this.loadUserInterests();
    await this.loadDepartments();
    this.randomSuggestions();
    this.traineeMatching();
    this.setState({ displayLoader: false }); // Ausblenden des Ladebildschirms.
  } // Ende des "componentWillMount" Events.

  // async loadUserInterests() {
  //   try {
  //     let userData = await UserDataAPI.get(); // Aufruf der ThemesAPI "getTheme" Funktion.
  //     if (!userData.error && !userData.response.error) {
  //       // Wenn keine Fehler aufgetreten sind.
  //       this.setState({ userInterests: userData.response.data.interests });
  //     }
  //   } catch (ex) {
  //     // Einfangen der Fehlermeldung.
  //     console.log(ex); // Loggen der Fehlermeldung in der Konsole.
  //   }
  // }

  traineeMatching() {
    let RawDepartments = this.state.allDepartments;
    let score = '';

    for (let i = 0; i < this.state.allDepartments.length; i++) {
      let Check2 = this.state.userInterests.filter(function (item) {
        return RawDepartments[i].departmentThemes.includes(item);
      });
      score = (Check2.length / this.state.userInterests.length) * 100;
      RawDepartments[i].score = score.toFixed(2);
    }
    RawDepartments.sort(this.compareScore);
    this.setState({ matches: RawDepartments });
  }

  compareScore(a, b) {
    // Beginn der "compare" Funktion.
    if (parseInt(a.score) > parseInt(b.score)) {
      // Wenn b > a dann soll b vor a sortiert werden.
      return -1;
    }
    if (parseInt(a.score) < parseInt(b.score)) {
      // Wenn a > b soll a vor b bleiben.
      return 1;
    }
    return 0; // Wenn man am Ende angekommen ist, soll 0 returned werden.
  } // Ende der "compare" Funktion.

  async loadThemes() {
    try {
      let themesList = await ThemesAPI.getTheme(); // Aufruf der ThemesAPI "getTheme" Funktion.
      if (!themesList.error && !themesList.response.error) {
        // Wenn keine Fehler aufgetreten sind.
        let rawThemesList = themesList.response.data.data.Items; // Uebertragen der Ergebnisse in lokales Objekt.
        let uebergabeList = [];
        for (let i = 0; i < rawThemesList.length; i++) {
          uebergabeList.push({
            // Jedes Ergebnisobjekt soll in den lokalen Array geschrieben werden.
            value: rawThemesList[i].themesID,
            label: rawThemesList[i].themesTitle,
          });
        }
        await uebergabeList.sort(this.compare); // Aufruf der "compare" Funktion zum Sortieren des lokalen Arrays.
        this.setState({ themesList: uebergabeList }); // Uebertragen des lokalen Array in den State.
      }
    } catch (ex) {
      // Einfangen der Fehlermeldung.
      console.log(ex); // Loggen der Fehlermeldung in der Konsole.
    }
  }

  async loadDepartments() {
    try {
      let departmentList = await DepartmentAPI.getAll(); // Aufruf der ThemesAPI "getTheme" Funktion.
      if (!departmentList.error && !departmentList.response.error) {
        // Wenn keine Fehler aufgetreten sind.
        let rawDepartmentData = departmentList.response.data;
        let departmentUebergabeArray = [];
        for (let i = 0; i < rawDepartmentData.length; i++) {
          departmentUebergabeArray.push({
            departmentID: rawDepartmentData[i].departmentID,
            departmentShortcuts: rawDepartmentData[i].departmentShortcuts.toUpperCase(),
            departmentThemes: rawDepartmentData[i].themes,
            description: rawDepartmentData[i].description,
          });
        }
        await departmentUebergabeArray.sort(this.compareDepartment);
        this.setState({ allDepartments: departmentUebergabeArray });
        this.setState({ departments: departmentUebergabeArray });
      }
    } catch (ex) {
      // Einfangen der Fehlermeldung.
      console.log(ex); // Loggen der Fehlermeldung in der Konsole.
    }
  }

  compare(a, b) {
    // Beginn der "compare" Funktion.
    if (a.label < b.label) {
      // Wenn b > a dann soll b vor a sortiert werden.
      return -1;
    }
    if (a.label > b.label) {
      // Wenn a > b soll a vor b bleiben.
      return 1;
    }
    return 0; // Wenn man am Ende angekommen ist, soll 0 returned werden.
  } // Ende der "compare" Funktion.

  compareDepartment(a, b) {
    // Beginn der "compare" Funktion.
    if (a.departmentShortcuts < b.departmentShortcuts) {
      // Wenn b > a dann soll b vor a sortiert werden.
      return -1;
    }
    if (a.departmentShortcuts > b.departmentShortcuts) {
      // Wenn a > b soll a vor b bleiben.
      return 1;
    }
    return 0; // Wenn man am Ende angekommen ist, soll 0 returned werden.
  } // Ende der "compare" Funktion.

  randomSuggestions() {
    let NumberArray = [];
    for (let i = 0; i < 4; i++) {
      let min = 0;
      let max = this.state.allDepartments.length - 1;
      let x = Math.round(Math.random() * (max - min)) + min;
      if (NumberArray.length > 0) {
        let istDrin = false;
        while (istDrin === false) {
          if (NumberArray.includes(x) === false) {
            NumberArray.push(x);
            istDrin = true;
          } else if (NumberArray.includes(x) === true) {
            x = Math.round(Math.random() * (max - min)) + min;
          }
        }
      } else if (NumberArray.length === 0) {
        NumberArray.push(x);
      }
    }
    let randomMatches = [];
    for (let y = 0; y < 4; y++) {
      randomMatches.push(this.state.allDepartments[NumberArray[y]]);
    }
    this.setState({ randomMatches: randomMatches });
  }

  handleChangeSelectDepartmentThemes = async (departmentThemes) => {
    // Beginn der "handleChangeSelectTraineeInterests" Funktion.
    if (departmentThemes === null) {
      this.setState({ departments: this.state.allDepartments });
    }
    await this.setState({ departmentThemes }); // TraineeInterests soll in den State geschrieben werden.
    let Uebergabe = [];
    let selectList = [];
    for (let x = 0; x < this.state.departmentThemes.length; x++) {
      selectList.push(this.state.departmentThemes[x].value);
    }

    for (let i = 0; i < this.state.allDepartments.length; i++) {
      let departmentThemes2 = this.state.allDepartments[i].departmentThemes;
      let Check = departmentThemes2.filter(function (item) {
        return selectList.includes(item);
      });

      if (selectList.length === Check.length) {
        Uebergabe.push(this.state.allDepartments[i]);
      }
    }
    this.setState({ departments: Uebergabe });
  }; // Ende der "handleChangeSelectTraineeInterests" Funktion.

  openOverview() {
    this.setState({
      display: 'overview',
    });
  }

  openMatching() {
    this.setState({
      display: 'matching',
    });
  }

  render() {
    const { departmentThemes } = this.state; // Die Konstante soll gleich der State sein.

    let matches = this.state.matches.map(
      // Beginn der Map Funktion.
      function iterator(match, index) {
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        return <DepartmentMatchingCard key={index} match={match} index={index} />;
      },
      this
    );

    let randomMatches = this.state.randomMatches.map(
      // Beginn der Map Funktion.
      function iterator(rmatch, index) {
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        return <DepartmentCard key={index} department={rmatch} index={index} />;
      },
      this
    );

    let departments = this.state.departments.map(
      // Beginn der Map Funktion.
      function iterator(department, index) {
        // Fuer jedes Element des Arrays wird ein HTML Element erzeugt.
        return <DepartmentCard key={index} department={department} index={index} />;
      },
      this
    );

    return (
      <div className={'FullSizeContainer'}>
        <Spinner display={this.state.displayLoader} />
        <NavigationBarTrainee history={this.props.history} />
        {(() => {
          switch (this.state.display) {
            case 'matching':
              return (
                <div className="contentUI">
                  <br />
                  <div id={'switchButton'} onClick={this.openOverview.bind(this)}>
                    <p id={'switchText'}>Abteilungsübersicht</p>
                    <i className="c-icon  c-icon--[control-arrow-up-and-down]" id={'switchIcon'} />
                  </div>
                  <h3 id={'calendarH3'}>
                    <br />
                    TOAD Abteilungsmatching
                  </h3>

                  <p>Matches</p>
                  <div className={'container-departments'}>
                    <Flickity options={this.state.flickityOptions}>{matches}</Flickity>
                  </div>
                  <div id={'refreshButton'}>
                    <i className="c-icon  c-icon--[semantic-reload]" onClick={this.randomSuggestions} />
                  </div>
                  <p>Unsere Vorschläge</p>
                  <div className={'container-departments'}>{randomMatches}</div>
                </div>
              );
            case 'overview':
              return (
                <div className="contentUI">
                  <br />
                  <div id={'switchButton'} onClick={this.openMatching.bind(this)}>
                    <p id={'switchText'}>Abteilungsmatching</p>
                    <i className="c-icon  c-icon--[control-arrow-up-and-down]" id={'switchIcon'} />
                  </div>
                  <h3 id={'calendarH3'}>
                    <br />
                    Abteilungsübersicht
                  </h3>

                  <p> Abteilungsthemen</p>
                  <Select
                    style={{ height: '31px' }}
                    id="currentDepartmentThemes"
                    value={departmentThemes}
                    valueKey="value"
                    options={this.state.themesList}
                    isMulti={true}
                    getOptionLabel={(opt) => opt.label}
                    getOptionValue={(opt) => opt.value}
                    placeholder="Bitte Thema wählen..."
                    noOptionsMessage={() => 'Kein Thema mit dem Titel gefunden'}
                    onChange={this.handleChangeSelectDepartmentThemes}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      boxShadow: 'none',
                      border: '2px solid red',
                      colors: {
                        ...theme.colors,
                        primary: '#a8adb3',
                      },
                    })}
                  />
                  <div className={'container-departments'}>{departments}</div>
                </div>
              );
            default:
              return <h1>Ein Fehler ist aufgetreten.</h1>;
          }
        })()}
      </div>
    );
  }
} // Ende der "AzubiMatching" Klasse.

export default DepartmentMatchingAndOverviewTrainee; // Export der "AzubiMatching" Klasse.
