import React, { Component } from 'react';
import './BigPopup.css';
import Modal from './Modal';

class BigPopup extends Component {
  render() {
    return (
      <Modal
        left={this.props.left}
        showCancel
        showClose
        title={this.props.title}
        display={this.props.open}
        onConfirm={this.props.onConfirm}
        onClose={this.props.onClose}
        onCancel={this.props.onCancel}
        cancelLabel={this.props.cancelLabel}
        className={'Inhalt'}
        confirmLabel={this.props.confirmLabel}
        stretch={this.props.stretch}
        cancelDisabled={this.props.cancelDisabled}
        confirmDisabled={this.props.confirmDisabled}
        large={this.props.large}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default BigPopup;
