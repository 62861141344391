// React Framework Imports.
import React, { Component } from 'react';
// Komponenten Imports
import InputValidate from '../../components/InputValidate';
// API Imports
import UserAPI from '../../api/Admin/User';
import NavigationBarAdmin from './NavigationBarAdmin';
import Page from '../../components/page/Page';

class UserPasswordResetAdmin extends Component {
  // Beginn der "AdminUserResetPw" Klasse.
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };

    this.handleResetUserPassword = this.handleResetUserPassword.bind(this); // Binding der Funktion, damit die Manipulationen an den States gespeichert werden.
    this.handleChange = this.handleChange.bind(this); // Binding der Funktion, damit die Manipulationen an den States gespeichert werden.
  }

  async handleResetUserPassword() {
    let hasErrors = false; // Lokale Fehlervariable.
    if (this.state.email === '') {
      // Wenn keine Email eingegeben wurde..
      hasErrors = true; // Fehlervariable auf true.
      InputValidate.SetErrorByID('email'); // Anzeigen der Fehlermeldung.
    }
    if (hasErrors === false) {
      // Wenn kein Fehler aufgetreten ist.
      try {
        let result = await UserAPI.resetUser(this.state.email); // Aufruf der UserAPI "resetUser" Funktion.
        if (!result.error && !result.data.error) {
          // Falls kein Error auftrutt
          document.getElementById('actionStatus').innerHTML =
            'Passwort für Benutzer ' + this.state.email + ' erfolgreich zurückgesetzt'; // Anzeigen der Erfolgsmeldung.
          document.getElementById('actionStatus').style.color = '#1dbf00';
        } else {
          document.getElementById('actionStatus').innerHTML = 'Fehler: ' + result.msg; // Anzeigen der Fehlermeldung.
          document.getElementById('actionStatus').style.color = '#ff0000';
        }
      } catch (ex) {
        document.getElementById('actionStatus').innerHTML = 'Fehler: ' + ex; // Anzeigen der Fehlermeldung.
        document.getElementById('actionStatus').style.color = '#ff0000';
      }
    }
  }

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.id]: event.target.value, // ..den Wert des aktuell angesprochenen Inputfelds, durch die ID, erhalten.
    });
    InputValidate.UnsetErrorByID(event._targetInst.memoizedProps.id); // Fehlermeldung zuruecksetzen.
  };

  render() {
    return (
      <Page title="Passwort zurücksetzen" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
        <div className="o-layout">
          <div className="o-layout__item u-1/2">
            <fieldset className="o-fieldset">
              <div className="c-form-field  ">
                <label className={'c-form-field__label'}>Email</label>
                <div className="c-form-field__box">
                  <div className="c-input  ">
                    <input
                      className="c-input__input  "
                      id="email"
                      type="email"
                      placeholder="Bitte E-Mail Adresse des Benutzer eingeben."
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="o-layout__item u-1/1">
            <button className="c-btn c-btn--primary" onClick={this.handleResetUserPassword}>
              <span className="c-btn__text">zurücksetzen</span>
            </button>
          </div>
        </div>
      </Page>
    );
  }
} // Ende der "AdminUserResetPw" Klasse.
export default UserPasswordResetAdmin; // Export der "AdminUserResetPw" Klasse.
