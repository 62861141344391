import { GET_THEME_START, GET_THEME_SUCCESS } from '../actions/ThemeAction';
import RequestState from '../models/RequestState';

function themeReducer(
  state = {
    getRequestState: RequestState.INITIAL,
    postRequestState: RequestState.INITIAL,
    image: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_THEME_START:
      return {
        ...state,
        getRequestState: RequestState.FETCHING,
      };
    case GET_THEME_SUCCESS:
      return {
        ...state,
        themes: action.themes,
        getRequestState: RequestState.SUCCESS,
      };
    default:
      return state;
  }
}

export default themeReducer;
