import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import NavigationBarTrainee from '../NavigationBarTrainee';
import DepartmentRequestAPI from '../../../api/Azubi/DepartmentRequest';
import AutoDatatable from '../../../components/AutoDatatable';
import AutoForm from '../../../components/AutoForm';
import { useHistory, Link } from 'react-router-dom';

export default function DepartmentRequestsEditOrShow({ editMode }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const componentPath = '/auszubildende/einsatzanfragenuebersicht';

  let { id } = useParams();

  const history = useHistory();
  const autoForm = useRef();

  const onSubmitForm = async () => {
    const result = await DepartmentRequestAPI.update(data);

    if (!result.error) {
      history.push(componentPath);
    }

    return result;
  };

  const showData = [
    {
      header: 'ID',
      accessor: 'id',
      editable: false,
      required: true,
    },
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      required: true,
      type: 'daterangepicker',
      minDate: 'today',
    },
    {
      header: 'Abteilung',
      accessor: 'department.shortcut',
      required: true,
      editable: false,
      comboboxOptions: [],
      type: 'combobox',
      autoDatatableOnly: true,
    },
    {
      header: 'Nachricht',
      accessor: 'message',
      required: true,
      type: 'textarea',
    },
  ];

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const requestResult = await DepartmentRequestAPI.getOne({ id: id });
        if (!requestResult.error) setData(requestResult.data);
      } catch (e) {
        setData([]);
      }
      setIsLoading(false);
    })();
  }, [setData]);

  return (
    <>
      <NavigationBarTrainee></NavigationBarTrainee>
      <div className="o-page-wrap  u-mb">
        <div className="o-layout">
          <div className="o-layout__item">
            <h3>Einsatzanfrage {id}</h3>
          </div>
          {editMode ? (
            Object.keys(data).length > 0 ? (
              <AutoForm
                data={data}
                dataDescription={showData}
                onValueChange={setData}
                submitForm={onSubmitForm}
                ref={autoForm}
              ></AutoForm>
            ) : null
          ) : (
            <AutoDatatable data={data} dataDescription={showData} isLoading={isLoading}></AutoDatatable>
          )}
        </div>
        <div className="c-button-group u-mt">
          <Link to={componentPath} className="c-btn c-btn--secondary">
            Zurück
          </Link>
          {editMode ? (
            <button className="c-btn c-btn--secondary" onClick={() => autoForm.current.onFormSubmit()}>
              Speichern
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
