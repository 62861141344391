import React, { useEffect, useState } from 'react';
import Page from '../../components/page/Page';
import 'react-toastify/dist/ReactToastify.css';
import NavigationBarTrainee from './NavigationBarTrainee';
import Spinner from '../../components/loader/Spinner';
import Table from '../../components/table/Table';
import DatesAPI from '../../api/Global/Dates';
import { formatDateToLocalString } from '../../shared/FormatFunctions';

const DepartmentSearch = () => {
  const [timelineData, setTimelineData] = useState();
  let timeline = DatesAPI.useGetTimeline();

  useEffect(() => {
    let unsortedData = [];

    timeline.data?.dates.map((x) => {
      if (x) unsortedData.push({ ...x, entryType: 'dat' });
    });

    let sorted =
      unsortedData.sort(function (a, b) {
        return new Date(a.start) - new Date(b.start);
      }) ?? [];

    console.log(sorted);

    setTimelineData(sorted);
  }, [timeline.data]);

  const columns = [
    {
      Header: 'Titel',
      accessor: 'title',
      headerStyle: { textAlign: 'center' },
      className: 'center',
      Cell: (props) => <div style={{ wordBreak: 'break-all' }}>{props.value}</div>,
    },
    {
      Header: 'Start',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'start',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Ende',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'end',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
  ];

  return (
    <Page title="Termine" navigation={<NavigationBarTrainee />}>
      <Spinner display={timeline.isLoading || timeline.isFetching} fullPage />

      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-mt">
          <Table
            defaultPageSize={10}
            ofText={'von'}
            previousText={'Vorherige Seite'}
            pageText={'Seite'}
            rowsText={'Zeilen'}
            nextText={'Nächste Seite'}
            data={timelineData ?? []}
            noDataText={'Keine Datensätze'}
            columns={columns}
            filterable={true}
          />
        </div>
      </div>
    </Page>
  );
};
export default DepartmentSearch;
