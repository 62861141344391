import React, { useCallback, useRef, useState } from 'react';
import { useDropdownClose } from './UseDropdownClose';

const HeaderNavigation = ({ children, preToggleIcon }) => {
  const element = useRef();
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded((expanded) => !expanded), []);
  useDropdownClose(element, expanded, setExpanded);

  return (
    <div className="c-header__nav" ref={element}>
      <div className="c-site-nav">
        <button
          className="c-site-nav__toggle"
          type="button"
          title="Show navigation"
          aria-expanded={expanded}
          onClick={toggle}
        >
          {preToggleIcon}
          <i className="c-site-nav__toggle-icon" aria-hidden="true"></i>
        </button>
        <div className="c-site-nav__panel" aria-hidden={!expanded}>
          <nav className="c-site-nav__navs u-text-uppercase">
            <ul className="c-site-nav__nav">{children}</ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavigation;
