import React from 'react';
import Spinner from '../components/loader/Spinner';
import { formatDateToLocalString } from '../shared/FormatFunctions';

function AutoDatatable({ data, dataDescription, isLoading }) {
  const fromPathString = function (pathString, obj) {
    var arr = pathString.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  };

  const mapArray = (obj, descr) => {
    if (!obj) return null;
    return obj.map((x) => x[descr.optionsAccessor]).join(', ');
  };

  return (
    <>
      <Spinner display={isLoading} fullPage />
      <div className="o-layout__item">
        {data && dataDescription ? (
          <>
            <div className="c-table-wrapper  ">
              <table>
                <tbody>
                  {dataDescription.map((descr) => {
                    return (
                      <tr key={descr.accessor}>
                        <th scope="row">{descr.header}</th>
                        {typeof data[descr.accessor] === 'boolean' ? (
                          <td>{data[descr.accessor] === true ? 'Ja' : 'Nein'}</td>
                        ) : descr.type === 'tagpicker' ? (
                          <td>{mapArray(data[descr.accessor], descr)}</td>
                        ) : descr.type === 'daterangepicker' ? (
                          <td>
                            {formatDateToLocalString(data[descr.start_accessor], 'DD.MM.YYYY')} bis{' '}
                            {formatDateToLocalString(data[descr.end_accessor], 'DD.MM.YYYY')}
                          </td>
                        ) : descr.type === 'date' ? (
                          <td>{formatDateToLocalString(data[descr.accessor], 'DD.MM.YYYY')}</td>
                        ) : (
                          <td>{fromPathString(descr.accessor, data)}</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default AutoDatatable;
