import React from 'react';
import './SimpleLoader.css';

const SimpleLoader = ({ display, className }) => {
  console.log(display);
  return (
    <div className={display === true ? '' : 'hidden'}>
      <div className={'c-spinner__loader ' + className}>
        <span className="c-spinner__element"></span>
      </div>
    </div>
  );
};

export default SimpleLoader;
