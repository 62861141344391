import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import HolidayAPI from '../../../api/Azubi/Holidays';
import { formatDateToLocalString } from '../../../shared/FormatFunctions';
import Page from '../../../components/page/Page';
import NavigationBarTrainee from '../NavigationBarTrainee';
import Table from '../../../components/table/Table';
import TableFilter from '../../../components/table/TableFilter';

const Holidays = () => {
  const componentPath = 'urlaubsuebersicht';
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        const data = await HolidayAPI.get();
        setData(data.data);
      } catch (e) {
        setData([]);
      }
      setIsLoading(false);
    }
    fetch();
  }, [setData, setIsLoading]);

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Start',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'start',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Ende',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'end',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Netto Tage',
      accessor: 'nettoDays',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Typ',
      accessor: 'type',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Status',
      accessor: 'state',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Abgestimmt mit Abteilung',
      Cell: (props) => (props.value ? 'Ja' : 'Nein'),
      accessor: 'departmentConfirmation',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/edit/${dataId}`}
              className="c-icon c-icon--[semantic-edit] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/delete/${dataId}`}
              className="c-icon c-icon--[semantic-close] c-icon--small c-btn  c-btn--icon-only  c-btn--secondary  c-btn--small  c-table__btn"
            />
          </div>
        );
      },
    },
  ]);

  return (
    <Page title="Urlaubsübersicht" navigation={<NavigationBarTrainee />}>
      <div className="o-layout">
        <Spinner fullPage display={isLoading} />
        <div className="o-layout__item u-1/1">
          <div className="o-layout__item u-1/3 o-layout--left" style={{ marginLeft: '-20px', marginRight: '20px' }}>
            <TableFilter
              id={'filter1'}
              label={'Filter Status'}
              onChange={(val) => {
                if (val == 'alle') {
                  setDataFiltered(data);
                } else {
                  setDataFiltered(data.filter((x) => x.state == val));
                }
              }}
              options={[
                { key: 'alle', value: 'alle', label: 'alle' },
                { key: 'offen', value: 'offen', label: 'offen' },
                { key: 'genehmigt', value: 'genehmigt', label: 'genehmigt' },
                { key: 'abgelehnt', value: 'abgelehnt', label: 'abgelehnt' },
              ]}
            ></TableFilter>
          </div>
          <div className="o-layout__item u-1/3 u-pl-none"></div>
          <div className="o-layout__item u-1/3 o-layout--right">
            <Link to={`${componentPath}/create`} className="c-btn  c-btn--secondary">
              <span className="c-btn__text">Hinzufügen</span>
            </Link>
          </div>
        </div>

        <div className="o-layout__item">
          <Table
            defaultPageSize={10}
            pageSize={10}
            ofText={'von'}
            previousText={'Vorherige Seite'}
            pageText={'Seite'}
            rowsText={'Zeilen'}
            nextText={'Nächste Seite'}
            data={dataFiltered ?? data}
            noDataText={'Keine Datensätze'}
            columns={columns}
            filterable={true}
          />
        </div>
      </div>
    </Page>
  );
};

export default Holidays;
