import React, { useState } from 'react';

const TableFilter = ({ options, id, label, onChange }) => {
  const [value, setValue] = useState('');

  return (
    <div className="c-input  c-input--select">
      <p style={{ margin: 'auto', marginRight: '8px' }}>{label}</p>
      <select
        className={`c-input__input tagpicker`}
        value={value}
        id={id}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event.target.value);
        }}
      >
        <option defaultValue={{ label: '', id: 0 }} hidden></option>
        {options.map((option) => (
          <option key={option.key} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TableFilter;
