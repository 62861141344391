import React from 'react';
import { useHistory } from 'react-router-dom';
import './i18n-license';
import i18n from '../../i18n';
import licenses from './licenses.json';
import LicenseSections from './LicenseSections';
import './style.css';

/**
 * Renders a page with all packages shipped with the frontend and their corresponding license.
 *
 * @param {object} history - history object
 * @returns {*} jsx element
 *
 * @example:
 * <LicenseView/>
 */
const LicenseView = () => {
  const history = useHistory();
  return (
    <>
      <section className="o-content-section">
        <div className="o-component-wrapper">
          <div className={'o-page-wrap '}>
            <h3 level="3" center>
              {i18n.t('license:LicenseView.HeroTeaserTitle')}
            </h3>
            <button
              icon
              iconName={'control-arrow-left'}
              label={i18n.t('common:buttons.back')}
              onClick={history.goBack}
              className="u-mb c-btn c-btn--secondary"
            >
              Zurück
            </button>
            <LicenseSections licenses={licenses} />
          </div>
        </div>
      </section>
    </>
  );
};

export default LicenseView;
