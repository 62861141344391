import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

class Mediengestalter2018 extends React.Component {
  render() {
    const MGW = 'Mediengestalterin in der Digital Unit Berlin';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity>
          <AzubiCard name={'Tabea Jessen'} dkx={''} ausbildungsart={MGW} history={this.props.history} />
          <AzubiCard name={'Esther Kähne'} dkx={''} ausbildungsart={MGW} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default Mediengestalter2018;
