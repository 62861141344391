import RequestState from '../models/RequestState';
import {
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_ERROR,
} from '../actions/ProfileActions';

function profileReducer(
  state = {
    getRequestState: RequestState.INITIAL,
    postRequestState: RequestState.INITIAL,
    image: undefined,
  },
  action
) {
  switch (action.type) {
    case GET_PROFILE_START:
      return {
        ...state,
        getRequestState: RequestState.FETCHING,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getRequestState: RequestState.SUCCESS,
        profile: action.profile,
        error: undefined,
      };
    case GET_PROFILE_ERROR: {
      return {
        ...state,
        getRequestState: RequestState.ERROR,
        error: action.error,
      };
    }
    case UPDATE_PROFILE_START:
      return { ...state, postRequestState: RequestState.FETCHING };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        getRequestState: RequestState.SUCCESS,
        postRequestState: RequestState.SUCCESS,
        profile: action.profile,
        error: undefined,
      };
    case UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        postRequestState: RequestState.ERROR,
        error: action.error,
      };
    }
    default:
      return state;
  }
}

export default profileReducer;
