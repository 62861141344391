import React, { Component } from 'react';
import './AzubiCard.css';

class AzubiCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dkx: '',
    };
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.dkx === '') {
      this.setState(() => ({ dkx: 'DKXTester' }));
    } else if (this.props.dkx !== '') {
      this.setState(() => ({ dkx: this.props.dkx }));
    } else {
      console.log('Es ist ein Fehler bei der Verarbeitung der DKX aufgetreten');
    }
  }

  handleClick() {
    this.props.history.push({
      pathname: '/azubi',
      state: { dkx: this.state.dkx, name: this.props.name },
    });
  }

  render() {
    return (
      <div className="js-slider__item  c-cards-slider__item">
        <div className="c-cards-slider__card-wrapper">
          <a className="c-card  c-card--center" onClick={this.handleClick}>
            <img
              className={'Foto'}
              src={
                'https://s3.eu-central-1.amazonaws.com/toad-cognito-backend-dev-serverlessdeploymentbuck-1k9k8eibnjaz/profilePicture/' +
                this.state.dkx +
                '.png'
              }
              alt=""
            />

            <div className="c-card__title">{this.props.name}</div>
            <div className="c-card__body">{this.props.ausbildungsart}</div>
          </a>
        </div>
      </div>
    );
  }
}

export default AzubiCard;
