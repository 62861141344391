import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DepartmentAPI from '../../api/Global/Department';
import AutoForm from '../../components/AutoForm';
import Spinner from '../../components/loader/Spinner';
import Page from '../../components/page/Page';
import NavigationBarCoaches from './NavigationBarCoaches';
import { selectThemes } from '../../redux/selectors/ThemeSelect';
import { useSelector } from 'react-redux';

const DepartmentPageCoaches = () => {
  const history = useHistory();
  const [data, setData] = useState({ state: 'GREEN' });
  const [isLoading, setIsLoading] = useState(false);
  const autoForm = useRef();
  const themes = useSelector(selectThemes);

  const [dataDefinition] = useState([
    {
      header: 'Abteilungskürzel',
      accessor: 'shortcut',
      required: true,
      editable: false,
      type: 'input',
    },
    {
      header: 'Beschreibung',
      accessor: 'description',
      required: true,
      type: 'textarea',
    },
    {
      header: 'Auslastung',
      accessor: 'state',
      required: true,
      type: 'combobox',
      comboboxOptions: [
        { display: 'Grün', value: 'GREEN' },
        { display: 'Gelb', value: 'YELLOW' },
        { display: 'Rot', value: 'RED' },
      ],
    },
    {
      header: 'Themen',
      accessor: 'topics',
      required: true,
      type: 'tagpicker',
      optionsAccessor: 'title',
      optionsIdAccessor: 'id',
      tagpickerOptions: themes,
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      const department = await DepartmentAPI.get();

      setData(department.data);

      setIsLoading(false);
    };
    fetch();
  }, []);

  const onSubmitForm = async () => {
    const result = await DepartmentAPI.update(data);

    if (!result.error) history.goBack();

    return result;
  };

  return (
    <Page title={'Meine Abteilung'} navigation={<NavigationBarCoaches />}>
      <Spinner fullPage display={isLoading} />
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={dataDefinition}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>
        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <button className="c-btn c-btn--secondary" onClick={() => history.goBack()}>
              Abbrechen
            </button>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Speichern
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default DepartmentPageCoaches;
