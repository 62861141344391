import moment from 'moment';

/**
 * Formats a number as a localized string
 * @param value number value or undefined
 * @param digits the number of digits, default 0
 * @param unit the unit of the number like km
 */
export function formatNumberToString(value, digits = 0, unit = '') {
  return value !== undefined
    ? value.toLocaleString('de', {
        minimumFractionDigits: digits,
      }) + (unit ? `\u202F${unit}`.trimRight() : '')
    : '';
}

/**
 * Parse a string value and returns its number representation. The number format is assumed as 'de'.
 * @param value the string value
 * @returns the string's number value or 0 if value is undefined
 */
export function parseStringAsNumber(value) {
  return value ? Number(value.replace(/\./g, '').replace(',', '.')) : 0;
}

/**
 * Formats a date as a string with YYYY-MM-DD
 * @param value date value or undefined
 */
export function formatDateToString(value, format = 'YYYY-MM-DD') {
  return value && moment(value).isValid() ? moment(value).utc().format(format) : '';
}

/**
 * Formats a date as a string with YYYY-MM-DD
 * @param value date value or undefined
 */
export function formatDateToLocalString(value, format = 'YYYY-MM-DD') {
  return value && moment(value).isValid() ? moment(value).local().format(format) : '';
}

export function getTimestampNow() {
  return moment().unix();
}

export function getTimeStartOfDay(value) {
  return moment(value).isValid() ? moment(value).local().startOf('day').unix() : 0;
}

export function getTimeEndOfDay(value) {
  return moment(value).isValid() ? moment(value).local().endOf('day').unix() : 0;
}
