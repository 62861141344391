import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './containers/NotFound';
import AppliedRoute from './components/AppliedRoute';
import Azubi from './containers/Azubi';
import NoAccess from './containers/NoAccess';
import HomePageTrainer from './containers/Trainer/HomePageTrainer';
import AusbilderUebersicht from './containers/Trainer/AusbilderUebersicht';
import AusbilderNeuerTermin from './containers/Trainer/AusbilderNeuerTermin';
import HomePageCoaches from './containers/Coaches/HomePageCoaches';
import AuszubildendeStartseite from './containers/Trainee/HomePageTrainee';
import Login from './containers/Login';
import AdminLogin from './containers/AdminLogin';
import confirm from './containers/confirm';
import PasswordReset from './containers/PasswordReset';
import DepartmentPageCoaches from './containers/Coaches/DepartmentPageCoaches';
import TraineeOverviewCoaches from './containers/Coaches/TraineeOverviewCoaches';
import HolidayRequests from './containers/Trainer/Holidays/HolidayRequests';
import DepartmentRequestsManagementCoaches from './containers/Coaches/DepartmentRequestsManagementCoaches';
import PrivateRoute from './middleware/PrivateRoute';
import Home from './containers/Home';
import HomePageAdmin from './containers/Administrator/HomePageAdmin';
import EditDepartmentPageCoaches from './containers/Coaches/EditDepartmentPageCoaches';
import TraineeDepartmentMatching from './containers/Trainee/DepartmentMatchingAndOverviewTrainee';
import User from './containers/Administrator/User/User';
import ThemesLexiconAdmin from './containers/Administrator/ThemesLexiconAdmin';
import DepartmentManagementAdmin from './containers/Administrator/DepartmentManagementAdmin';
import UserPasswordResetAdmin from './containers/Administrator/UserPasswordResetAdmin';

import DepartmentEditOrShow from './containers/Administrator/Department/DepartmentEditOrShow';
import DepartmentCreate from './containers/Administrator/Department/DepartmentCreate';

import UserEditOrShow from './containers/Administrator/User/UserEditOrShow';
import UserCreate from './containers/Administrator/User/UserCreate';

import ThemeEditOrShow from './containers/Administrator/Theme/ThemeEditOrShow';
import ThemeCreate from './containers/Administrator/Theme/ThemeCreate';

import HolidaysCreate from './containers/Trainee/Holidays/HolidaysCreate';
import Holidays from './containers/Trainee/Holidays/Holidays';
import HolidaysEditOrShow from './containers/Trainee/Holidays/HolidaysEditOrShow';

import DepartmentRequestCreate from './containers/Trainee/DepartmentRequest/DepartmentRequestCreate';
import DepartmentRequestEditOrShow from './containers/Trainee/DepartmentRequest/DepartmentEditOrShow';

import TrainingtypesAdmin from './containers/Administrator/TrainingtypesAdmin';
import TrainingtypesEditOrShow from './containers/Administrator/Trainingtypes/TrainingtypesEditOrShow';
import TrainingtypesCreate from './containers/Administrator/Trainingtypes/TrainingtypesCreate';
import LicenseView from './components/license/LicenseView';
import DepartmentRequests from './containers/Trainee/DepartmentRequest/DepartmentRequest';
import ProfileEdit from './components/profilepage/ProfileEdit';
import NavigationBarTrainee from './containers/Trainee/NavigationBarTrainee';
import Profile from './components/profilepage/Profile';
import NavigationBarCoaches from './containers/Coaches/NavigationBarCoaches';

import DepartmentRequestsManagementDetailPage from './containers/Coaches/DepartmentRequestsManagementDetailPage';
import NavigationBarTrainer from './containers/Trainer/NavigationBarTrainer';
import HolidayRequestsShow from './containers/Trainer/Holidays/HolidayRequestsShow';
import DepartmentSearch from './containers/Trainee/DepartmentSearch';
import DatesOverview from './containers/Trainee/DatesOverview';

const Routes = ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/license" exact component={LicenseView} props={childProps} />

    <AppliedRoute path="/adminlogin" exact component={AdminLogin} props={childProps} />
    <AppliedRoute path="/noaccess" exact component={NoAccess} props={childProps} />

    <AppliedRoute path="/admin/users" exact component={User} props={childProps} />
    <AppliedRoute path="/admin/password-reset" exact component={UserPasswordResetAdmin} props={childProps} />
    <AppliedRoute path="/admin/theme" exact component={ThemesLexiconAdmin} props={childProps} />
    <AppliedRoute path="/admin/department" exact component={DepartmentManagementAdmin} props={childProps} />

    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende"
      exact
      component={AuszubildendeStartseite}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/abteilungen"
      exact
      component={TraineeDepartmentMatching}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/urlaubsuebersicht"
      exact
      component={Holidays}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/einsatzanfragenuebersicht"
      exact
      component={DepartmentRequests}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/account"
      exact
      component={Profile}
      props={{ ...childProps, navigation: <NavigationBarTrainee /> }}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/account/editprofile"
      exact
      component={ProfileEdit}
      props={{ ...childProps, navigation: <NavigationBarTrainee /> }}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/einsatzanfragenuebersicht/create"
      exact
      component={DepartmentRequestCreate}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/einsatzanfragenuebersicht/:id"
      exact
      component={DepartmentRequestEditOrShow}
      props={{ ...childProps, editMode: false }}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/einsatzanfragenuebersicht/edit/:id"
      exact
      component={DepartmentRequestEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/einsatzanfragenuebersicht/:id"
      exact
      component={DepartmentRequestEditOrShow}
      props={{ ...childProps }}
    />
    <PrivateRoute users={['Ausbilder']} path="/ausbilder" exact component={HomePageTrainer} props={childProps} />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/uebersicht"
      exact
      component={AusbilderUebersicht}
      props={childProps}
    />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/neuertermin"
      exact
      component={AusbilderNeuerTermin}
      props={childProps}
    />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/urlaubsuebersicht"
      exact
      component={HolidayRequests}
      props={childProps}
    />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/urlaubsuebersicht/:id"
      exact
      component={HolidayRequestsShow}
      props={childProps}
    />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/account"
      exact
      component={Profile}
      props={{ ...childProps, navigation: <NavigationBarTrainer /> }}
    />
    <PrivateRoute
      users={['Ausbilder']}
      path="/ausbilder/account/editprofile"
      exact
      component={ProfileEdit}
      props={{ ...childProps, navigation: <NavigationBarTrainer /> }}
    />
    <PrivateRoute users={['Unterweiser']} path="/unterweiser" exact component={HomePageCoaches} props={childProps} />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/auszubildende"
      exact
      component={TraineeOverviewCoaches}
      props={childProps}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/einsatzanfragen"
      exact
      component={DepartmentRequestsManagementCoaches}
      props={childProps}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/einsatzanfragen/:id"
      exact
      component={DepartmentRequestsManagementDetailPage}
      props={childProps}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/account"
      exact
      component={Profile}
      props={{ ...childProps, navigation: <NavigationBarCoaches /> }}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/account/editprofile"
      exact
      component={ProfileEdit}
      props={{ ...childProps, navigation: <NavigationBarCoaches /> }}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/abteilung"
      exact
      component={DepartmentPageCoaches}
      props={childProps}
    />
    <PrivateRoute
      users={['Unterweiser']}
      path="/unterweiser/abteilung/edit"
      exact
      component={EditDepartmentPageCoaches}
      props={childProps}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/department/create"
      exact
      component={DepartmentCreate}
      props={childProps}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/department/edit/:id"
      exact
      component={DepartmentEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/department/:id"
      exact
      component={DepartmentEditOrShow}
      props={childProps}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/trainingtypes"
      exact
      component={TrainingtypesAdmin}
      props={childProps}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/trainingtypes/create"
      exact
      component={TrainingtypesCreate}
      props={childProps}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/trainingtypes/edit/:id"
      exact
      component={TrainingtypesEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute
      users={['Admin']}
      path="/admin/trainingtypes/:id"
      exact
      component={TrainingtypesEditOrShow}
      props={childProps}
    />
    <PrivateRoute users={['Admin']} path="/admin/user/create" exact component={UserCreate} props={childProps} />
    <PrivateRoute
      users={['Admin']}
      path="/admin/user/edit/:id"
      exact
      component={UserEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute users={['Admin']} path="/admin/theme/create" exact component={ThemeCreate} props={childProps} />
    <PrivateRoute
      users={['Admin']}
      path="/admin/theme/edit/:id"
      exact
      component={ThemeEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute users={['Admin']} path="/admin/theme/:id" exact component={ThemeEditOrShow} props={childProps} />
    <PrivateRoute users={['Admin']} path="/admin/user/:id" exact component={UserEditOrShow} props={childProps} />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/urlaubsuebersicht/create"
      exact
      component={HolidaysCreate}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/departmentsearch"
      exact
      component={DepartmentSearch}
      props={childProps}
    />
    <PrivateRoute users={['Azubis']} path="/auszubildende/dates" exact component={DatesOverview} props={childProps} />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/urlaubsuebersicht/:id"
      exact
      component={HolidaysEditOrShow}
      props={childProps}
    />
    <PrivateRoute
      users={['Azubis']}
      path="/auszubildende/urlaubsuebersicht/edit/:id"
      exact
      component={HolidaysEditOrShow}
      props={{ ...childProps, editMode: true }}
    />
    <PrivateRoute users={['Admin']} path="/admin" exact component={HomePageAdmin} props={childProps} />
    <AppliedRoute path="/passwordreset" exact component={PasswordReset} props={childProps} />
    <PrivateRoute path="/azubi" exact component={Azubi} props={childProps} />
    <AppliedRoute path="/confirm" exact component={confirm} props={childProps} />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
