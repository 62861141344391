import axios from 'axios';
import { useQuery } from 'react-query';

const DatesAPI = {
  endpointName: 'toad-dates',

  get: async function (start, end, traingID, year) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'get',
        start: start,
        end: end,
        traingID: traingID,
        year: year,
      });
      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },

  useGetTimeline: function () {
    return useQuery(
      [this.endpointName, 'getTimeline'],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          action: 'getTimeline',
        });
        return data.data;
      },
      { staleTime: 1000 * 60 * 5 }
    );
  },

  update: async function () {},

  create: async function (type, title, shortcut, start, end, participants) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'create',
        type: type,
        title: title,
        shortcut: shortcut,
        start: start,
        end: end,
        participants: participants,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
};

export default DatesAPI;
