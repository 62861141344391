import React, { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AutoForm from '../../../components/AutoForm';
import ThemesAPI from '../../../api/Global/Themes';
import NavigationBarAdmin from './../../Administrator/NavigationBarAdmin';
import Page from '../../../components/page/Page';
import { useQueryClient } from 'react-query';

function ThemeCreate() {
  const [data, setData] = useState([]);
  const history = useHistory();
  const autoForm = useRef();
  const queryClient = useQueryClient();

  const onSubmitForm = async () => {
    const result = await ThemesAPI.insertTheme(data.title);

    if (!result.error) {
      ThemesAPI.invalidate(queryClient);
      history.push('/admin/theme');
    }

    return result;
  };

  const showData = [
    {
      header: 'Titel',
      accessor: 'title',
      required: true,
      notEmpty: true,
    },
  ];

  return (
    <Page title="Thema anlegen" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/admin/theme'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ThemeCreate;
