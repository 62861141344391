import axios from 'axios';
import { useQuery } from 'react-query';

const ThemesAPI = {
  endpointName: 'toad-themes',
  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName]);
  },
  get: async function () {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'get',
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  useGet: function () {
    return useQuery(
      [this.endpointName, 'get'],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          action: 'get',
        });
        return data.data;
      },
      { staleTime: 1000 * 60 * 5 }
    );
  },
  getOne: async function (id) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'getOne',
        id: id,
      });

      return { error: result.data.error, data: result.data.data };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  insertTheme: async function (title) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'insert',
        title: title,
      });

      return { error: false, data: result };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  delete: async function (themesID) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        action: 'delete',
        id: themesID,
      });

      return { error: false, data: result };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
  update: async function (data) {
    try {
      let result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'update',
      });

      return { error: false, data: result };
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
};

export default ThemesAPI;
