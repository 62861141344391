import React from 'react';
import Flickity from 'react-flickity-component';
import AzubiCard from '../../cards/AzubiCard';

const flickityOptions = {
  groupCells: true,
};

class It2018 extends React.Component {
  render() {
    const dualWIM = 'Dualer Student der Wirtschaftsinformatik';
    const dualWIW = 'Duale Studentin der Wirtschaftsinformatik';
    const ITM = 'Auszubildender im Bereich Fachinformatik';
    const ITW = 'Auszubildende im Bereich Fachinformatik';
    const TU = 'Student der Informatik';

    return (
      <div className={'Inhalt der Abteilungen'}>
        <Flickity options={flickityOptions}>
          <AzubiCard name={'Gino Bellomo'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Viktoria Blanke'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Florian Braunschweig'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Philipp Brzosa'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Nico de Groot'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Patrick Dembowski'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Daniel Dudek'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Marco Funken'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Jasper Glodeck'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Tom Heering'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Marcel Hennigs'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Maximilian Hüttl'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Sara Karolak'} dkx={''} ausbildungsart={ITW} history={this.props.history} />
          <AzubiCard name={'Linda Keitel'} dkx={''} ausbildungsart={dualWIW} history={this.props.history} />
          <AzubiCard name={'Philipp Kleemann'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Cedric Latzel'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Miles Müller'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Laurenz Pieper'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Jonas Poppe'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Jerome Radke'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Robin Reinecke'} dkx={''} ausbildungsart={TU} history={this.props.history} />
          <AzubiCard name={'Kevin Sonnemann'} dkx={''} ausbildungsart={dualWIM} history={this.props.history} />
          <AzubiCard name={'Nils Steinmetz'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Fenja Stieler'} dkx={''} ausbildungsart={ITW} history={this.props.history} />
          <AzubiCard name={'Florian Sturm'} dkx={''} ausbildungsart={ITM} history={this.props.history} />
          <AzubiCard name={'Marcel Vogt'} dkx={''} ausbildungsart={TU} history={this.props.history} />
        </Flickity>
      </div>
    );
  }
}

export default It2018;
