import React from 'react';
import '../../App.css';
import NavBarItem from '../../components/navbar/NavBarItem';
import NavigationBar from '../../components/navbar/NavigationBar';

const NavigationBarTrainer = () => {
  return (
    <NavigationBar defaultPage={'/ausbilder'}>
      <NavBarItem location="/ausbilder/urlaubsuebersicht" title="Urlaubsanfragen"></NavBarItem>
      <NavBarItem location="/ausbilder/uebersicht" title="Übersicht"></NavBarItem>
      <NavBarItem location="/ausbilder/neuertermin" title="Neuer Termin"></NavBarItem>
    </NavigationBar>
  );
};
export default NavigationBarTrainer;
