import axios from 'axios';
import { useQuery } from 'react-query';

const HolidaysAPI = {
  endpointName: 'toad-holidays',
  invalidate: function (queryClient) {
    queryClient.invalidateQueries([this.endpointName]);
  },
  useGet: function (state) {
    return useQuery(
      [this.endpointName, 'get', state],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          action: 'get',
          state: state,
        });
        return data.data;
      },
      { staleTime: 1000 * 20 * 1 }
    );
  },
  useGetOne: function (inputData) {
    return useQuery(
      [this.endpointName, 'getOne', inputData],
      async () => {
        const { data } = await axios.post('/' + this.endpointName, {
          ...inputData,
          action: 'getOne',
        });
        return data.data;
      },
      { staleTime: 1000 * 20 * 1 }
    );
  },
  getMultiple: async function (state, users) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'getMultiple',
        state: state,
        users: users,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  check: async function (startdate, enddate, users) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'check',
        startdate: startdate,
        enddate: enddate,
        users: users,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  accept: async function (requestId) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'accept',
        id: requestId,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  reject: async function (requestId) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'reject',
        id: requestId,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
};

export default HolidaysAPI;
