import axios from 'axios';
import { useQuery } from 'react-query';

const TraineesAPI = {
  endpointName: 'toad-user',

  create: async function (trainingType, year, trainees) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'create',
        trainingType: trainingType,
        year: year,
        trainees: trainees,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },

  get: async function (trainingID, year) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'get',
        trainingID: trainingID,
        year: year,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  useGetAll: function () {
    return useQuery([this.endpointName, 'getAll'], async () => {
      const { data } = await axios.post('/' + this.endpointName, {
        action: 'getAll',
      });
      return data.data;
    });
  },
};

export default TraineesAPI;
