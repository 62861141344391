import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import InputValidate from '../components/InputValidate';
import '../App.css';
import Spinner from '../components/loader/Spinner';
import NavigationBar from '../components/navbar/NavigationBar';
import NavBarItem from '../components/navbar/NavBarItem';

export default class Login extends Component {
  //Constructor of the NewLogin-component
  constructor(props) {
    super(props);

    //definition of the component states
    this.state = {
      forgottenemail: '',
      LoginEmail: '',
      password: '',
      usergroup: '',
      display: 'Login',
      newPassword: '',
      newPasswordConfirm: '',
      displayLoader: false,
    };

    //binding of needed events
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
  }

  //event definition
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  //login handling, if the login button is clicked
  handleLoginSubmit = async (event) => {
    this.setState({ displayLoader: true });

    InputValidate.UnsetErrorByID('LoginEmail');
    InputValidate.UnsetErrorByID('password');
    InputValidate.UnsetTextErrorByID('error-LoginEmail');
    InputValidate.UnsetTextErrorByID('error-password');
    event.preventDefault();

    try {
      //Asynchronous message with user-data is sended by the server
      let userObj = await Auth.signIn(this.state.LoginEmail.toLowerCase(), this.state.password);
      if (userObj.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await this.setState({ display: 'NewPassword' });
      }
      //definition of other needed constants and variables
      let user = await Auth.currentAuthenticatedUser();
      console.log(user);
      const group = user.attributes['custom:group'];
      this.props.userHasAuthenticated(true);
      this.props.setUserGroup(group);

      //await this.setState({displayLoader: true})
      this.props.history.push('/');

      //handling of possible exceptions
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        InputValidate.SetErrorByID('LoginEmail');
        InputValidate.SetTextErrorByID('error-LoginEmail', 'Die E-Mail ist unbekannt');
      } else if (e.code === 'NotAuthorizedException') {
        InputValidate.SetErrorByID('password');
        InputValidate.SetTextErrorByID('error-password', 'E-Mail und Passwort stimmen nicht überein');
      } else {
        console.log('Error: ' + e.code);
      }
    }

    this.setState({ displayLoader: false });
  };

  handleNewPassword = async (event) => {
    let hasErrors = false;
    InputValidate.UnsetErrorByID('newPasswordConfirm');
    InputValidate.UnsetTextErrorByID('error-newPasswordConfirm');
    InputValidate.UnsetErrorByID('newPassword');
    InputValidate.UnsetTextErrorByID('error-newPassword');
    event.preventDefault();

    let passwordRegEx = new RegExp(/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/);
    let NewPasswordCheck = passwordRegEx.test(this.state.newPassword);
    console.log('Check ist' + NewPasswordCheck);

    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      // Passwörter stimmen nicht überein.
      InputValidate.SetErrorByID('newPasswordConfirm');
      InputValidate.SetTextErrorByID('error-newPasswordConfirm', 'Die Passwörter stimmen nicht überein');
      hasErrors = true;
    } else if (this.state.newPassword.length < 7) {
      InputValidate.SetErrorByID('newPassword');
      InputValidate.SetTextErrorByID('error-newPassword', 'Das neue Passwort ist zu kurz');
      hasErrors = true;
    } else if (NewPasswordCheck === false) {
      InputValidate.SetErrorByID('newPassword');
      InputValidate.SetTextErrorByID('error-newPassword', 'Das neue Passwort entspricht nicht den Passwort vorgaben');
      hasErrors = true;
    }

    if (hasErrors === false) {
      try {
        let userObj = await Auth.signIn(this.state.LoginEmail.toLowerCase(), this.state.password);
        if (userObj.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(userObj, this.state.newPassword);
        }
        //Set new user password
        //definition of other needed constants and variables
        let user = await Auth.currentAuthenticatedUser();
        const group = user.attributes['custom:group'];

        this.props.userHasAuthenticated(true);
        this.props.setUserGroup(group);

        this.props.history.push('/');

        //handling of possible exceptions
      } catch (e) {
        console.log('Error: ' + e.code);
      }
    } else {
      // Fehler ist aufgetreten
    }
  };

  //handling of forgotten password and push to passwordreset-site
  handlePasswordForgotten = async (event) => {
    InputValidate.UnsetErrorByID('forgottenemail');
    InputValidate.UnsetTextErrorByID('error-forgottenemail');
    event.preventDefault();
    try {
      await Auth.forgotPassword(this.state.forgottenemail);
      this.props.history.push({
        pathname: '/passwordreset',
        state: {
          forgottenemail: this.state.forgottenemail,
        },
      });
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        InputValidate.SetErrorByID('forgottenemail');
        InputValidate.SetTextErrorByID('error-forgottenemail', 'User konnte nicht gefunden werden');
      } else {
        alert(e.code);
      }
    }
  };

  //method to show forgotten password-block for inputting registered mail address
  showForgottenPassword() {
    InputValidate.UnsetErrorByID('LoginEmail');
    InputValidate.UnsetErrorByID('password');
    InputValidate.UnsetTextErrorByID('error-LoginEmail');
    InputValidate.UnsetTextErrorByID('error-password');
    this.setState({ display: 'PasswordForgotten' });
  }
  showLogin() {
    this.setState({ display: 'Login' });
    this.setState({ forgottenemail: '' });
    InputValidate.UnsetErrorByID('forgottenemail');
    InputValidate.UnsetTextErrorByID('error-forgottenemail');
  }

  //check if user has already entered his Email and his password in the login screen
  validateLoginForm() {
    return this.state.LoginEmail.length > 0 && this.state.password.length > 0;
  }

  validateNewPasswordForm() {
    return this.state.newPassword.length > 7 && this.state.newPasswordConfirm.length > 7;
  }

  validateForgottenEmail() {
    return this.state.forgottenemail.length > 5;
  }

  render() {
    return (
      <>
        <NavigationBar>
          <NavBarItem location="/AdminLogin" title="Admin"></NavBarItem>
        </NavigationBar>
        <div className=".o-content-section">
          <div className="o-page-wrap o-page-wrap--xxsmall">
            <div className="o-layout o-layout--equal-height">
              {/* JSX-definition of the right part of the login screen*/}
              <div className="o-layout__item   u-1/1  u-1/1@s">
                <Spinner fullPage display={this.state.displayLoader} />
                <div className="vertical">
                  {(() => {
                    switch (this.state.display) {
                      case 'Login':
                        return (
                          <div>
                            <h3>TOAD - Welcome to the new Key to Mobility.</h3>

                            <p>E-Mail</p>
                            <input
                              className="c-input__input  "
                              type={'text'}
                              value={this.state.LoginEmail}
                              id="LoginEmail"
                              onChange={this.handleChange}
                            />
                            <p className="c-error-message" id="error-LoginEmail" style={{ display: 'none' }} />
                            <p>Passwort</p>
                            <input
                              className="c-input__input  "
                              type="password"
                              value={this.state.password}
                              id="password"
                              onChange={this.handleChange}
                            />
                            <p className="c-error-message" id="error-password" style={{ display: 'none' }} />
                            <div className="o-button-container u-mv  ">
                              <button
                                className="c-btn  c-btn--icon c-btn--secondary"
                                type="button"
                                onClick={this.handleLoginSubmit}
                                id={'loginButton'}
                                disabled={!this.validateLoginForm()}
                              >
                                <span className="c-btn__text">Login</span>
                              </button>

                              <button className={'c-btn c-btn--link'} onClick={this.showForgottenPassword.bind(this)}>
                                Passwort vergessen?
                              </button>
                            </div>
                          </div>
                        );
                      case 'NewPassword':
                        return (
                          <div>
                            <form onSubmit={this.handleNewPassword}>
                              <h3>Passwort setzen</h3>
                              <p>Neues Passwort eingeben</p>
                              <input
                                className="c-input__input  "
                                type={'password'}
                                value={this.state.newPassword}
                                id="newPassword"
                                onChange={this.handleChange}
                              />
                              <p className="c-error-message" id="error-newPassword" style={{ display: 'none' }} />
                              <p>Neues Passwort bestätigen</p>
                              <input
                                className="c-input__input  "
                                type="password"
                                value={this.state.newPasswordConfirm}
                                id="newPasswordConfirm"
                                onChange={this.handleChange}
                              />
                              <p
                                className="c-error-message"
                                id="error-newPasswordConfirm"
                                style={{ display: 'none' }}
                              />
                              <div className="o-button-container u-mv  ">
                                <button
                                  className="c-btn  c-btn--icon c-btn--secondary"
                                  type="submit"
                                  id={'loginButton'}
                                  disabled={!this.validateNewPasswordForm()}
                                >
                                  <span className="c-btn__text">Passwort ändern</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        );
                      case 'PasswordForgotten':
                        return (
                          <div>
                            {/* JSX-definition of the forgot part of the login screen*/}
                            <form onSubmit={this.handlePasswordForgotten}>
                              <h3>Passwort vergessen</h3>
                              <p>E-Mail</p>
                              <input
                                className="c-input__input  "
                                type={'text'}
                                value={this.state.forgottenemail}
                                id="forgottenemail"
                                onChange={this.handleChange}
                              />
                              <p className="c-error-message" id="error-forgottenemail" style={{ display: 'none' }} />
                              <div className={'LoginButton'}>
                                <button
                                  className="c-btn  c-btn--icon c-btn--secondary"
                                  type="submit"
                                  id={'loginButton'}
                                  disabled={!this.validateForgottenEmail()}
                                >
                                  <span className="c-btn__text">Zurücksetzen</span>
                                </button>
                                <a className={'forgotPassword'} onClick={this.showLogin.bind(this)}>
                                  zurück zum Login
                                </a>
                              </div>
                            </form>
                          </div>
                        );

                      default:
                        return <div>Fehler</div>;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
