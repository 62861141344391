import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import {
  formatDateToLocalString,
  getTimeEndOfDay,
  getTimeStartOfDay,
  getTimestampNow,
} from '../../shared/FormatFunctions';
import './HorizontalTimeline.css';

const HorizontalTimeline = ({ dates }) => {
  const currentTime = getTimestampNow();

  console.log(dates);
  return (
    <>
      <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
        <ul className="horizontalTimeline" id="horizontalTimeline">
          {dates?.map((date) => {
            let currentStartDif = currentTime - getTimeStartOfDay(date.start);
            let currentEndDif = currentTime - getTimeEndOfDay(date.start);
            return (
              <li
                key={date.title + date.start + date.end}
                className={
                  'li ' +
                  (currentStartDif > 0 && currentEndDif < 0
                    ? 'current'
                    : currentStartDif > 0 && currentEndDif > 0
                    ? 'complete'
                    : '')
                }
              >
                <div className="timestamp">
                  <span className="author">{date.title}</span>
                  <span className="date">{formatDateToLocalString(date.start, 'DD.MM.YYYY')}</span>
                </div>
                <div className="status">
                  <span className="date">{formatDateToLocalString(date.end, 'DD.MM.YYYY')}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </ScrollContainer>
    </>
  );
};

export default HorizontalTimeline;
