import React from 'react';
import '../../App.css';
import NavBarItem from '../../components/navbar/NavBarItem';
import NavigationBar from '../../components/navbar/NavigationBar';

const NavigationBarTrainee = () => {
  return (
    <NavigationBar defaultPage={'/auszubildende'}>
      <NavBarItem location="/auszubildende/einsatzanfragenuebersicht" title="Einsatzanfragenübersicht"></NavBarItem>
      <NavBarItem location="/auszubildende/departmentsearch" title="Abteilungssuche"></NavBarItem>
      <NavBarItem location="/auszubildende/dates" title="Termine"></NavBarItem>
      <NavBarItem location="/auszubildende/urlaubsuebersicht" title="Urlaub"></NavBarItem>
    </NavigationBar>
  );
};

export default NavigationBarTrainee;
