const InputValidate = {
  SetErrorByID: function (element) {
    let link_element = document.getElementById(element);

    if (link_element != null) {
      let current = link_element.getAttribute('class');
      link_element.setAttribute('class', current + ' is-error');
    }
  },
  UnsetErrorByID: function (element) {
    let link_element = document.getElementById(element);

    if (link_element != null) {
      let current = link_element.getAttribute('class');
      link_element.setAttribute('class', current.replace('is-error', ''));
    }
  },
  SetTextErrorByID: function (element, text) {
    document.getElementById(element).innerText = text;
    document.getElementById(element).style.display = 'block';
  },
  UnsetTextErrorByID: function () {},
};

export default InputValidate;
