import React, { useState, useEffect, useRef } from 'react';
import './NewsFeed.css';
import NewsfeedAPI from '../../api/Global/Newsfeed';
import TimeConverter from '../TimeConverter';
import BigPopup from '../../components/popup/BigPopup';
import Spinner from '../loader/Spinner';
import { useQueryClient } from 'react-query';
import AutoForm from '../AutoForm';
import { toast, ToastContainer } from 'react-toastify';

const NewsFeed = () => {
  const autoForm = useRef();
  const [newsData, setNewsData] = useState([]);
  const [filteredNewsfeedData, setFilteredNewsfeedData] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [selectedFilterButton, setSelectedFilterButton] = useState('all');
  const [newPostData, setNewPostData] = useState({ withRegistration: false });

  const newsfeedData = NewsfeedAPI.useGet(10);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [displayInfoDialog, setDisplayInfoDialog] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (newsfeedData.data) {
      setNewsData(newsfeedData.data?.newsfeedData);
      setFilteredNewsfeedData(newsfeedData?.data);
      setCurrentUserId(newsfeedData?.data.currentUserId);
    }
  }, [newsfeedData.data]);

  useEffect(() => {
    filterPosts(selectedFilterButton);
  }, [newsData]);

  const filterPosts = (filter) => {
    setSelectedFilterButton(filter);

    if (filter === 'all') {
      setFilteredNewsfeedData(newsData);
    } else if (filter === 'own') {
      setFilteredNewsfeedData(
        newsData.filter((newsEntry) => {
          return newsEntry.userId == currentUserId;
        })
      );
    } else if (filter === 'registered') {
      setFilteredNewsfeedData(
        newsData.filter((newsEntry) => {
          return newsEntry.participants.some((e) => Number(e.id) == currentUserId);
        })
      );
    }
  };

  const handleRegistrateChange = async (event) => {
    const feedid = event.currentTarget.value;
    const reqState = await NewsfeedAPI.changeRegistrationState(feedid, 10);

    if (!reqState.error) {
      setNewsData(reqState.data);
    }
  };

  const showData = [
    {
      header: 'Title',
      accessor: 'titel',
      required: true,
      notEmpty: true,
    },
    {
      header: 'Nachricht',
      accessor: 'NewsTextInput',
      required: true,
      type: 'textarea',
      notEmpty: true,
    },
    {
      header: 'Können sich Nutzer für diesen Beitrag anmelden?',
      accessor: 'withRegistration',
      type: 'checkbox',
      label: 'Anmeldung möglich',
    },
  ];

  const openNewMessageDialog = () => {
    setDisplayPopup(true);
  };

  const closeNewDialaog = () => {
    setDisplayPopup(false);
  };

  const onSubmitForm = async () => {
    const result = await NewsfeedAPI.create(newPostData.titel, newPostData.NewsTextInput, newPostData.withRegistration);

    if (!result.error) {
      NewsfeedAPI.invalidate(queryClient);
      setNewPostData({ withRegistration: false });
      setDisplayPopup(false);
    } else {
      toast.error('Post konnte nicht erstellt werden: ' + result.msg, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return result;
  };

  const onDisplayInfoDialogShow = (event) => {
    const postId = event.currentTarget.getAttribute('data-value');
    const participants = newsData.find((e) => Number(e.id) === Number(postId))?.participants ?? [];

    setDisplayInfoDialog(true);
    setDisplayUsers(participants);
  };

  const onDisplayInfoDialogClose = () => {
    setDisplayInfoDialog(false);
  };

  const generateNewsfeed = () => {
    if (filteredNewsfeedData?.length ?? 0 > 0) {
      return filteredNewsfeedData.map((News, index) => {
        if (News === undefined) return null;
        else {
          const registratedUser = News.participants?.some((e) => Number(e.id) === Number(currentUserId));
          return (
            <div className="newsEntry" key={index}>
              <div className="o-layout">
                <div className="o-layout__item  u-1/2">
                  <a className="newsEntryNoneUnderline">{TimeConverter.formatMysqlDate(News.createdAt)}</a>
                </div>
                <div className="o-layout__item  u-1/2 u-text-right">
                  <a className="newsEntryNoneUnderline">
                    {(News.creator?.firstname ?? '') + ' ' + (News.creator?.lastname ?? '')}
                  </a>
                </div>
              </div>
              <div className="o-layout">
                <div className="o-layout__item  u-1/1">
                  <h4 className={'newsEntryTitle'}>{News.title}</h4>
                  <p className={'newsEntryP'} align="left">
                    {News.description}
                  </p>
                </div>
              </div>
              {News.registrationEnabled === true ? (
                <div className="o-layout">
                  <div className="o-layout__item  u-1/2">
                    <button
                      className="c-btn  c-btn--secondary"
                      type="button"
                      onClick={handleRegistrateChange}
                      value={News.id}
                      id={registratedUser === false ? 'registerButton' : 'unregisterButton'}
                    >
                      <div className="c-btn__text">{!registratedUser ? 'Anmelden' : 'Abmelden'}</div>
                    </button>
                  </div>
                  <div className="o-layout__item  u-1/2">
                    <div id={'registrationInfo'} data-value={News.id} onClick={onDisplayInfoDialogShow}>
                      <span
                        className={'p1 fa-stack fa-2x has-badge'}
                        data-count={News.participants === null ? '0' : News.participants.length}
                      />
                      <i id={'infoIcon'} className="c-icon c-icon--[user]" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          );
        }
      });
    }

    return null;
  };

  return (
    <div id={'newsFeed'}>
      <div className="u-pb-large">
        <div className="o-page-wrap  o-page-wrap--xsmall">
          <header className="c-section-heading  c-section-heading--small-spacing u-pt">
            <h2 className="c-section-heading__title">
              <span className="c-section-heading__title-text">Newsfeed</span>
            </h2>
          </header>
          <fieldset className="o-layout o-layout--middle">
            <div className="o-layout__item u-1/2">
              <button
                className={'tag tag-added ' + (selectedFilterButton === 'all' ? 'tag-active' : '')}
                onClick={() => filterPosts('all')}
              >
                <span>Alle</span>
              </button>

              <button
                className={'tag tag-added ' + (selectedFilterButton === 'own' ? 'tag-active' : '')}
                onClick={() => filterPosts('own')}
              >
                <span>Eigene</span>
              </button>

              <button
                className={'tag tag-added ' + (selectedFilterButton === 'registered' ? 'tag-active' : '')}
                onClick={() => filterPosts('registered')}
              >
                <span>Angemeldet</span>
              </button>
            </div>
            <div className="o-layout__item u-1/2 u-text-right">
              <button className="c-btn c-btn--secondary" onClick={openNewMessageDialog}>
                <span className="c-btn__text">Anlegen</span>
                <i className="c-icon  c-icon--[semantic-edit]" id={'newsEdit'} />
              </button>
            </div>
          </fieldset>
          {newsfeedData.isLoading ? <Spinner fullPage={false}></Spinner> : generateNewsfeed()}
        </div>
      </div>

      <BigPopup stretch open={displayPopup} title="Neuer Beitrag" onClose={closeNewDialaog}>
        <AutoForm
          key="newsfeed-newPost-autoForm"
          data={newPostData}
          dataDescription={showData}
          onValueChange={setNewPostData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>
        <div className="o-layout__item u-text-right">
          <button
            className="c-btn c-btn--primary"
            style={{ marginTop: '30px', width: '100%' }}
            onClick={() => autoForm.current.onFormSubmit()}
          >
            Speichern
          </button>
        </div>
      </BigPopup>

      <BigPopup open={displayInfoDialog} onClose={onDisplayInfoDialogClose}>
        <h3>Teilnehmer</h3>
        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {displayUsers.map((participant, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{participant.firstname + ' ' + participant.lastname}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </BigPopup>
      <ToastContainer />
    </div>
  );
};

export default NewsFeed;
