const SelectUtils = {
  addEmptyDefault(selectid) {
    let x = document.getElementById(selectid);
    if (x) {
      let option = document.createElement('option');
      option.disabled = true;
      option.selected = true;
      option.style = 'display:none';

      x.add(option);
    }
  },
  selectOption(selectid, selection) {
    let x = document.getElementById(selectid);
    x.value = selection;
  },
  addItemToSelect(selectid, value, txt) {
    let x = document.getElementById(selectid);
    let option = document.createElement('option');
    option.text = txt;
    option.value = value;
    x.add(option);
  },
  sortSelect(elem) {
    let selElem = document.getElementById(elem);

    let tmpAry = [];
    for (let i = 0; i < selElem.options.length; i++) {
      tmpAry[i] = [];
      tmpAry[i][0] = selElem.options[i].text;
      tmpAry[i][1] = selElem.options[i].value;
    }
    tmpAry.sort();

    while (selElem.options.length > 0) {
      selElem.options[0] = null;
    }

    for (let i = 0; i < tmpAry.length; i++) {
      selElem.options[i] = new Option(tmpAry[i][0], tmpAry[i][1]);
    }
  },
  clearSelect(elem) {
    let selectbox = document.getElementById(elem);
    for (let i = selectbox.options.length - 1; i >= 0; i--) {
      selectbox.remove(i);
    }
  },
};

export default SelectUtils;
