import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DepartmentRequestAPI from '../../api/Azubi/DepartmentRequest';
import Spinner from '../../components/loader/Spinner';
import { formatDateToLocalString } from '../../shared/FormatFunctions';
import NavigationBarCoaches from './NavigationBarCoaches';
import Table from '../../components/table/Table';

const DepartmentRequestsManagementCoaches = () => {
  const componentPath = 'einsatzanfragen';
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        const response = await DepartmentRequestAPI.get();
        setData(response.data);
      } catch (e) {
        console.error(e);
        setData([]);
      }
      setIsLoading(false);
    })();
  }, [setData, setIsLoading]);

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Vorname',
      accessor: 'requester.firstname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Nachname',
      accessor: 'requester.lastname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Start',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'start',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Ende',
      Cell: (props) => formatDateToLocalString(props.value, 'DD.MM.YYYY'),
      accessor: 'end',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Status',
      accessor: 'state',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        console.log(props.row);
        const dataId = props.row.original.id;
        return (
          <div>
            <Link to={`${componentPath}/${dataId}`} className="c-icon c-icon--[semantic-search] c-icon--small" />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <NavigationBarCoaches history={history} />
      <div className="o-page-wrap">
        <Spinner fullPage display={isLoading} />
        <div className="o-layout">
          <div className="o-layout__item">
            <h3>Einsatzanfragen</h3>
          </div>

          <div className="o-layout__item u-1/1">
            <Table
              defaultPageSize={10}
              ofText={'von'}
              previousText={'Vorherige Seite'}
              pageText={'Seite'}
              rowsText={'Zeilen'}
              nextText={'Nächste Seite'}
              data={data}
              noDataText={'Keine Datensätze'}
              columns={columns}
              filterable={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentRequestsManagementCoaches;
