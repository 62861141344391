import React, { useEffect, useState } from 'react';
import HolidaysAPI from '../../api/Ausbilder/Holidays';
import '../../App.css';
import CardSmall from '../../components/cards/CardSmall';
import Spinner from '../../components/loader/Spinner';
import NewsFeed from '../../components/newsfeed/NewsFeed';
import BigPopup from '../../components/popup/BigPopup';
import NavigationBarTrainer from './NavigationBarTrainer';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';

const HomePageTrainer = () => {
  const history = useHistory();
  const holiday_data = HolidaysAPI.useGet('any');
  const [requestCountText, setRequestCountText] = useState('');
  const [azubis_off, setAzubis_off] = useState([]);
  const [offTodayText, setOffTodayText] = useState('');
  const [displayPopUp, setDisplayPopUp] = useState(false);

  const navigateHolidaysRequest = () => {
    history.push({
      pathname: '/ausbilder/urlaubsuebersicht',
    });
  };

  useEffect(() => {
    if (holiday_data.data) {
      let open = holiday_data.data.filter((x) => x.state == 'offen');
      let away = holiday_data.data.filter(
        (x) => x.state == 'genehmigt' && moment().isBetween(moment(x.start), moment(x.end))
      );

      if (away.length == 1) {
        setOffTodayText('Heute ist ' + away.length + ' Azubi im Urlaub.');
      } else if (away.length > 1) {
        setOffTodayText('Heute sind ' + away.length + ' Azubis im Urlaub.');
      } else {
        setOffTodayText('Heute ist kein Azubi im Urlaub.');
      }

      if (open.length == 1) {
        setRequestCountText(open.length + ' offene Urlaubsanfrage.');
      } else if (open.length > 1) {
        setRequestCountText(open.length + ' offene Urlaubsanfragen.');
      } else {
        setRequestCountText('keine offenen Urlaubsanfragen.');
      }

      setAzubis_off(away);
    }
  }, [holiday_data.data]);

  return (
    <>
      <Spinner fullPage display={holiday_data.isLoading || holiday_data.isFetching} />

      <NavigationBarTrainer history={history} />

      <div className={'o-page-wrap u-mb'}>
        <h3>Willkommen</h3>

        <div className="o-layout u-text-center">
          <div className="o-layout__item u-1/3 u-1/1@s">
            <CardSmall title={'Offene Urlaubsanfragen'}>
              <p>Es gibt {requestCountText}</p>
              <button
                className="c-btn c-btn--secondary"
                type="submit"
                id={'gotoHoliday'}
                onClick={navigateHolidaysRequest}
              >
                <span className="c-btn__text">Alle sehen</span>
              </button>
            </CardSmall>
          </div>

          <div className="o-layout__item  u-1/3 u-1/1@s">
            <CardSmall title={'Sind im Urlaub...'}>
              <p>{offTodayText}</p>
              <button
                className="c-btn c-btn--secondary"
                type="submit"
                id={'gotoHoliday'}
                onClick={() => setDisplayPopUp(true)}
              >
                <span className="c-btn__text">Alle sehen</span>
              </button>
            </CardSmall>
          </div>
        </div>
      </div>
      <BigPopup open={displayPopUp} onClose={() => setDisplayPopUp(false)}>
        <h3>Im Urlaub:</h3>
        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {azubis_off.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td>{index + 1}</td>
                  <td>{x.creator.firstname + ' ' + x.creator.lastname}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </BigPopup>
      <NewsFeed />
    </>
  );
};

export default withRouter(HomePageTrainer);
