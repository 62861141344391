import React from 'react';
import { usePagination, useTable } from 'react-table';
const Table = ({ columns, data, previousText }) => {
  const tableInstance = useTable({ columns, data }, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <div className="c-table-wrapper c-table--colored">
      <table {...getTableProps()}>
        <thead>
          {
            // Apply the header row props
            headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th scope="row" key={column.id} {...column.getHeaderProps()}>
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr key={row.id} {...row.getRowProps()}>
                  {
                    // Loop over the rows cells

                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      <nav
        className="c-pagination u-mt-small "
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        aria-label="Pagination"
      >
        <ul className="c-pagination__list ">
          <li className="c-pagination__item u-hide@s">
            <div className="c-input  c-input--select u-mr">
              <select
                className="c-input__input  "
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </li>

          <li className="c-pagination__item u-ml u-text-center" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </li>
          <li className="c-pagination__item" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span className="c-pagination__link  c-pagination__link--stepper-prev " title="previous page (disabled)">
              <span className="u-visually-hidden">{previousText}</span>
            </span>
          </li>

          {pageOptions.map((op) => (
            <li key={op} className="c-pagination__item">
              <a href="#" className="c-pagination__link">
                {op + 1}
              </a>
            </li>
          ))}

          <li className="c-pagination__item " onClick={nextPage} disabled={!canNextPage}>
            <a href="#" className="c-pagination__link  c-pagination__link--stepper-next" title="next page">
              <span className="u-visually-hidden">next page</span>
            </a>
          </li>
          <li className="c-pagination__item u-mr " onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </li>
          <li className="c-pagination__item u-hide@s">
            <span>
              {'Seite '}
              <strong>
                {pageIndex + 1} von {pageOptions.length}
              </strong>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Table;
