import axios from 'axios';
import { QueryClientManager } from '../QueryClientManager';

const DateAPIConst = {
  getDateInfo: function (eventName) {
    switch (eventName) {
      case 'travel':
        return { color: 'red' };
    }
  },
};

const DatesAPI = {
  endpointName: 'toad-dates',

  get: async function (start, end, traingID, year) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'get',
        start: start,
        end: end,
        traingID: traingID,
        year: year,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  getCached: async function (reqData) {
    if (!reqData || reqData.length <= 0) return null;

    let querys = [];

    for (let i = 0; i < reqData.length; i++) {
      querys.push(
        QueryClientManager.instance()
          .getClient()
          .fetchQuery([this.endpointName, reqData[i]], async () => {
            const { data } = await axios.post('/' + this.endpointName, {
              ...reqData[i],
              action: 'getForYear',
            });
            return data.data;
          })
      );
    }

    return await Promise.all(querys);
  },
  removeSingleUser: async function (uid, timestamp, creator) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'remove_single',
        uid: uid,
        timestamp: timestamp,
        creator: creator,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },

  delete: async function (timestamp, creator) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        action: 'delete',
        timestamp: timestamp,
        creator: creator,
      });

      return { error: false, response: result.data };
    } catch (error) {
      return { error: true, response: error };
    }
  },
  createDate: async function (data) {
    try {
      var result = await axios.post('/' + this.endpointName, {
        ...data,
        action: 'create',
      });
      return result;
    } catch (error) {
      return { error: true, status: error.response.status, msg: error.response.data.msg };
    }
  },
};

export { DateAPIConst, DatesAPI };
