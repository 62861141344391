import React, { Component } from 'react';
import APIAuth from '../../api/authAPI';
import HeaderNav from '../header/HeaderNav';
import NavBarItem from './NavBarItem';
import { Link, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import toadi from '../../images/toad_blue.png';

class NavigationBar extends Component {
  constructor(props = { showProfile: true, showSupport: true }) {
    super(props);

    this.state = {
      profilbild: '',
      userAccount: '',
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  UNSAFE_componentWillMount() {
    let userImg = localStorage.getItem('userProfileImage');

    if (userImg != null) {
      this.setState({ profilbild: userImg });
    }
  }

  async componentDidMount() {
    var user = await Auth.currentAuthenticatedUser();

    this.setState({ user: user });
    let userImg = localStorage.getItem('userProfileImage');

    // if (userImg == null) {
    //   let userData = await UserDataAPI.get();

    //   if (!userData.error && !userData.response.error) {
    //     localStorage.setItem('userProfileImage', userData.response.data.profileImg);
    //     userImg = userData.response.data.profileImg;
    //   }
    // }

    this.setState(() => ({ profilbild: userImg }));
    this.setState(() => ({ userAccount: this.props.defaultPage + '/account' }));
  }

  sendTicket = () => {
    window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_MAIL}?subject=${encodeURIComponent(
      'TOAD Support Anfrage'
    )}&body=${encodeURIComponent('')}`;
  };

  handleLogout = async () => {
    await APIAuth.Logout();

    try {
      localStorage.removeItem('userProfileImage');
      localStorage.removeItem('toad_cache_holidays');
      localStorage.removeItem('toad_cache_department_request');
      localStorage.removeItem('toad_cache_admin');

      this.props.userHasAuthenticated(false);
    } catch (ex) {
      console.error(ex);
    }
    this.props.history.push('/login');
  };

  render() {
    return (
      <header className="c-header__wrapper">
        <div className="c-header  c-header--static">
          <div className="c-header__content-wrapper">
            <div className="c-header__logo">
              <Link
                className="c-logo  c-logo--has-image"
                to="/"
                title="INSERT YOUR OWN TEXT THAT DESCRIBES THE LINK"
                aria-label="Logo"
              >
                <picture>
                  <img
                    src="https://cdn.bronson.vwfs.tools/bluelabel-banking/v/9.2.0/img/logo.svg"
                    alt="INSERT YOUR OWN ALTERNATIVE TEXT THAT DESCRIBES THE LOGO"
                    className="c-logo__image"
                  ></img>
                </picture>
              </Link>
            </div>

            <HeaderNav>
              {this.props.children}
              {this.state.user && (this.props.showProfile ?? true) ? (
                <NavBarItem location={this.state.userAccount} title="Mein Profil"></NavBarItem>
              ) : null}
              {this.props.showSupport ?? true ? (
                <li className="c-site-nav__item      js-dropdown">
                  <a className="c-site-nav-dropdown__link foo" href="#" onClick={this.sendTicket}>
                    <span className="c-site-nav__link-label">Support</span>
                  </a>
                </li>
              ) : null}

              {this.state.user ? (
                <li className="c-site-nav__item      js-dropdown">
                  <a className="c-site-nav-dropdown__link" onClick={this.handleLogout}>
                    <span className="c-site-nav__link-label">Logout</span>
                  </a>
                </li>
              ) : null}

              <li className="c-site-nav__item u-hide@s">
                <span id="vender-logo" className="c-site-nav__link">
                  <span className="c-site-nav__link-label">
                    <img className="img-logo" src={toadi} alt="logo" height="37px" width="115px"></img>
                    <br />
                    <span>TOAD</span>
                  </span>
                </span>
              </li>
            </HeaderNav>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(NavigationBar);
