import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TraineesAPI from '../../api/Ausbilder/Trainees';
import TrainingTypesAPI from '../../api/Global/TrainingTypes';
import AutoForm from '../../components/AutoForm';
import Page from '../../components/page/Page';
import NavigationBarTrainer from './NavigationBarTrainer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatesAPI } from '../../api/Ausbilder/Dates';

const AusbilderNeuerTermin = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const autoForm = useRef();

  const trainingTypesData = TrainingTypesAPI.useGetWithYear();
  const traineesData = TraineesAPI.useGetAll();

  const onSubmitForm = async () => {
    console.log(data);
    const status = await DatesAPI.createDate(data);

    if (!status.error) {
      history.push('/ausbilder');
    } else {
      toast.error('Termin konnte nicht angelegt werden: ' + status.msg, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const const_showData = [
    {
      header: 'Termin Typ',
      accessor: 'type',
      required: true,
      sort: true,
      comboboxOptions: [
        { display: 'Leibniz FH', value: 'LZFH' },
        { display: 'Berufsschule', value: 'BBS' },
        { display: 'TU Braunschweig', value: 'TUBS' },
        { display: 'Jahrgangs Termin', value: 'JGTM' },
        { display: 'Schulung', value: 'SCHUL' },
        { display: 'Individuell', value: 'IDVL' },
      ],
      type: 'combobox',
    },
    {
      header: 'Titel',
      accessor: 'title',
      required: false,
      conditional: {
        accessor: 'type',
        value: ['IDVL', 'JGTM'],
      },
    },
    {
      header: 'Zeitraum',
      accessor: 'timerange',
      start_accessor: 'start',
      end_accessor: 'end',
      required: true,
      type: 'daterangepicker',
    },
    {
      header: 'Filter',
      label: 'Nur bestimmte Tage',
      accessor: 'onlySelectedDays',
      type: 'checkbox',
    },
    {
      header: 'Tage',
      accessor: 'selectedDays',
      required: false,
      type: 'tagpicker',
      optionsAccessor: 'display',
      optionsIdAccessor: 'value',
      tagpickerOptions: [
        { display: 'Montag', value: 1 },
        { display: 'Dienstag', value: 2 },
        { display: 'Mittwoch', value: 3 },
        { display: 'Donnerstag', value: 4 },
        { display: 'Freitag', value: 5 },
        { display: 'Samstag', value: 6 },
      ],
      conditional: {
        accessor: 'onlySelectedDays',
        condition: 'isTrue',
      },
    },
    {
      header: 'Teilnehmer',
      accessor: 'participants',
      type: 'tagpicker',
      optionsAccessor: 'display',
      optionsIdAccessor: 'value',
      tagpickerOptions: [],
    },
    {
      header: 'Teilnehmer Gruppen',
      accessor: 'participantGroups',
      type: 'tagpicker',
      optionsAccessor: 'display',
      optionsIdAccessor: 'value',
      tagpickerOptions: [],
    },
  ];

  const [showData, setShowData] = useState(const_showData);

  useEffect(() => {
    const_showData[5].tagpickerOptions = traineesData?.data?.map((x) => {
      return {
        display: x.firstname + ' ' + x.lastname,
        value: x.id,
        id: x.id,
        year: x.year,
        trainingtype: x.trainingTypeId,
      };
    });

    const_showData[6].tagpickerOptions = trainingTypesData?.data?.map((y) => {
      return {
        display: y.trainingtype.label + ' ' + y.year,
        value: y.trainingtype.id + '' + y.year,
        year: y.year,
        trainingtype: y.trainingtype.id,
      };
    });

    setShowData(const_showData);
  }, [traineesData.data, trainingTypesData.data]);

  return (
    <Page title={'Neuer Termin'} navigation={<NavigationBarTrainer history={history} />}>
      <div className="o-layout">
        <AutoForm
          data={data}
          dataDescription={showData}
          onValueChange={setData}
          submitForm={onSubmitForm}
          ref={autoForm}
        ></AutoForm>

        <div className="o-layout__item">
          <div className="c-button-group u-mt">
            <Link to={'/auszubildende/urlaubsuebersicht'} className="c-btn c-btn--secondary">
              Zurück
            </Link>
            <button className="c-btn c-btn--primary" onClick={() => autoForm.current.onFormSubmit()}>
              Anlegen
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Page>
  );
};
export default AusbilderNeuerTermin;
