import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import Table from '../../../components/table/Table';
import NavigationBarAdmin from '../NavigationBarAdmin';
import Page from '../../../components/page/Page';
import AdminApi from '../../../api/Admin/AdminApi';
import BigPopup from '../../../components/popup/BigPopup';

function User() {
  const componentPath = 'user';
  const [data, setData] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const api = new AdminApi();
      const userData = await api.get();
      setData(userData.data);
      setIsLoading(false);
    }
    fetch();
  }, [setData]);

  const deleteUser = async () => {
    setIsLoading(true);
    try {
      const api = new AdminApi();
      await api.deleteUser(user.id);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const openModal = (user) => {
    setShowModal(true);
    setUser(user);
  };

  const onClose = () => {
    setShowModal(false);
    setUser(null);
  };

  const columns = [
    {
      Header: 'E-Mail',
      accessor: 'email',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Vorname',
      accessor: 'firstname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Nachname',
      accessor: 'lastname',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Benutzer Gruppe',
      accessor: 'userGroup',
      headerStyle: { textAlign: 'center' },
      className: 'center',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props) => {
        const dataId = props.row.original.id;
        return (
          <div>
            <Link
              to={`${componentPath}/${dataId}`}
              className="c-icon c-icon--[semantic-search] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            <Link
              to={`${componentPath}/edit/${dataId}`}
              className="c-icon c-icon--[semantic-edit] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
            />
            {props.row.original.email !== process.env.REACT_APP_SUPPORT_MAIL ? (
              <button
                onClick={() => openModal(props.row.original)}
                className="c-icon c-icon--[semantic-close] c-icon--small c-btn--secondary  c-btn--small  c-table__btn"
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <Page title="Benutzer" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
      <div className="o-layout">
        <Spinner fullPage display={isLoading} />

        <div className="o-layout__item o-layout--right">
          <Link to={`${componentPath}/create`} className="c-btn  c-btn--primary">
            <span className="c-btn__text">Hinzufügen</span>
          </Link>
        </div>

        <div className="o-layout__item u-1/1">
          <Table
            defaultPageSize={10}
            ofText={'von'}
            previousText={'Vorherige Seite'}
            pageText={'Seite'}
            rowsText={'Zeilen'}
            nextText={'Nächste Seite'}
            data={data}
            noDataText={'Keine Datensätze'}
            columns={columns}
            filterable={true}
          />
        </div>
      </div>

      <BigPopup
        title={'Benutzer löschen'}
        confirmLabel="Löschen"
        onClose={onClose}
        open={showModal}
        cancelLabel="Abbrechen"
        onCancel={onClose}
        onConfirm={deleteUser}
      >
        {user ? (
          <p>
            Soll der Benutzer {user.firstname} {user.lastname} gelöscht werden?
          </p>
        ) : null}
      </BigPopup>
    </Page>
  );
}

export default User;
