import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';

const Modal = ({
  id,
  display,
  className,
  left,
  title,
  children,
  onClose,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  confirmDisabled = false,
  cancelDisabled = false,
  closeDisabled = false,
  buttonOrder = 'DEFAULT',
  large,
  stretch,
  video,
  showCancel = true,
  showClose = true,
  swapPrimary,
  confirmSpinner,
}) => {
  const modalWrapper = useRef(null);
  const onClickAction = (e) => {
    if (onClose && modalWrapper.current !== e.currentTarget) {
      onClose(false);
    }
    e.stopPropagation();
  };
  const onCloseAction = useCallback(() => {
    if (onClose) {
      onClose(true);
    }
  }, [onClose]);
  const cancelButton = (
    <div className="c-modal__actions__item">
      <button disabled={cancelDisabled} onClick={onCancel}>
        {cancelLabel}
      </button>
    </div>
  );
  return (
    <div
      className={classNames('c-modal', className, {
        'c-modal--left': left,
        'c-modal--large': large,
        'c-modal--stretch': stretch,
        'c-modal--video': video,
      })}
      aria-hidden={!display}
      id={id}
      onClick={onClickAction}
    >
      <div className="c-modal__overlay" tabIndex={-1}></div>
      <div
        className="c-modal__wrapper"
        role="dialog"
        aria-labelledby={`${id}-header`}
        ref={modalWrapper}
        onClick={onClickAction}
      >
        <header className="c-modal__header">
          {title && (
            <div className="c-modal__title__wrapper">
              <h2 className="c-modal__title" id={`${id}-header`}>
                {title}
              </h2>
            </div>
          )}
          {showClose && (
            <button
              className="c-modal__close-btn"
              type="button"
              aria-label="Close this dialog window."
              disabled={closeDisabled}
              onClick={onCloseAction}
            ></button>
          )}
        </header>

        <div className="c-modal__content" role="document">
          {children}
        </div>

        <div className="c-modal__footer">
          {(onCancel || onConfirm) && (
            <div className="c-modal__actions">
              {showCancel && onCancel && buttonOrder === 'DEFAULT' && cancelButton}
              {onConfirm && (
                <div className="c-modal__actions__item">
                  <button
                    className="c-btn  js-modal-autofocus"
                    aria-label="Close this dialog window."
                    onClick={onConfirm}
                    disabled={confirmDisabled}
                    secondary={swapPrimary}
                    spinner={confirmSpinner}
                  >
                    {confirmLabel}
                  </button>
                </div>
              )}
              {showCancel && buttonOrder === 'INVERT' && cancelButton}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
