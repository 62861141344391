import { Auth } from 'aws-amplify';

const APIAuth = {
  Logout: async function () {
    await Auth.signOut();
  },

  currentUser: async () => {
    const result = await Auth.currentAuthenticatedUser();
    if (result) {
      const account = {};
      account.email = result.attributes['email'];
      account.phone = result.attributes['custom:phone'];
      account.group = result.attributes['custom:group'];
      account._id = result.attributes['sub'];
      account.firstName = result.attributes['name'];
      account.lastName = result.attributes['family_name'];
      account.year = result.attributes['custom:year'];
      return account;
    }
  },
};

export default APIAuth;
