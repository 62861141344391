// React Framework Imports.
import React, { Component } from 'react';
import Trainingtypes from './Trainingtypes/Trainingtypes';
import NavigationBarAdmin from './NavigationBarAdmin';
import Page from '../../components/page/Page';

class TrainingtypesAdmin extends Component {
  // Beginn der "AdminLexicon" Komponente.
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Page title="Ausbildungsgänge" navigation={<NavigationBarAdmin></NavigationBarAdmin>}>
        <Trainingtypes history={this.props.history}></Trainingtypes>
      </Page>
    );
  }
}
export default TrainingtypesAdmin;
